<div class="main" fxLayout="column">
    <div class="page-container full" fxLayout="column" fxLayoutGap="20px" fxLayoutAlign="center center">
        <h1>{{ 'landing.welcome_to_kemppi_ewarranty' | translate }}</h1>

        <div fxLayout="column">
            <div *ngIf="!publicUse" fxLayout="column">
                <h3>Service is currently closed.</h3>
                <h3>Registrations and warranty claims will be available 3.6.2019</h3>
            </div>
            <div *ngIf="publicUse && allowedModules" fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
                <button mat-button *ngIf="allowedModules.registrations" routerLinkActive="active" routerLink="/registrations" class="module-select">
                    <div fxLayout="column" fxLayoutAlign="center center">
                        <div>
                            <mat-icon class="kemppi-symbols">è</mat-icon>
                        </div>
                        <div>{{ 'menu.product_registrations' | translate | uppercase }}</div>
                    </div>
                </button>
                <button mat-button *ngIf="allowedModules.claims" routerLinkActive="active" routerLink="/claims" class="module-select">
                    <div fxLayout="column" fxLayoutAlign="center center">
                        <div>
                            <mat-icon class="kemppi-symbols">s</mat-icon>
                        </div>
                        <div>{{ 'menu.warranty_claims' | translate | uppercase }}</div>
                    </div>
                </button>
            </div>
            <div *ngIf="allowedModules" fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
                <button mat-button *ngIf="allowedModules.prices" routerLinkActive="active" routerLink="/prices/labour-costs" class="module-select">
                    <div fxLayout="column" fxLayoutAlign="center center">
                        <div>
                            <mat-icon class="not-kemppi-symbols">euro_symbol</mat-icon>
                        </div>
                        <div>{{ 'menu.labour_costs' | translate | uppercase }}</div>
                    </div>
                </button>
                <button mat-button *ngIf="allowedModules.reports" routerLinkActive="active" routerLink="/reporting/claims" class="module-select">
                    <div fxLayout="column" fxLayoutAlign="center center">
                        <div>
                            <mat-icon class="kemppi-symbols">Å</mat-icon>
                        </div>
                        <div>{{ 'menu.reporting' | translate | uppercase }}</div>
                    </div>
                </button>
            </div>

            <div *ngIf="!allowedModules || (!allowedModules.registrations && !allowedModules.claims)">
                {{ 'landing.no_permission_to_any_module' | translate }}
            </div>
        </div>


        <div *ngIf="allowedModules && allowedModules.claims" class="quick-search">
            <h2 class="mat-subhead">Open warranty claim</h2>
            <div class="" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
                <mat-form-field>
                    <mat-label>{{ 'claims.claim_id' | translate }}</mat-label>
                    <input matInput [(ngModel)]="claimId" (keydown.enter)="openWarrantyClaim(claimId)">
                </mat-form-field>
                <div>
                    <button mat-raised-button color="accent" (click)="openWarrantyClaim(claimId)">{{ 'common.open' | translate }}</button>
                </div>
            </div>
        </div>

    </div>
    <div class="footer full" fxLayout="column" fxFlex>
        <div fxLayout="row">
            <div fxFlex></div>
            <div fxFlex="500px" class="footer-text">
                <h2>Kemppi eWarranty</h2>
                <p>{{ 'landing.kemppi_ewarranty_description' | translate }}</p>
            </div>
            <div fxFlex></div>
            <div fxFlex="300px" class="footer-text">
                <h3>{{ 'landing.quick_links' | translate }}</h3>
                <h3><a href="https://kemppi.force.com/iam">My Kemppi portal</a></h3>
                <h3><a href="https://userdoc.kemppi.com">Userdoc</a></h3>
                <h3><a href="https://servicedoc.kemppi.com">Servicedoc</a></h3>
                <h3><a href="https://kemppi.force.com/support">Kemppi Support</a></h3>
            </div>
            <div fxFlex></div>
        </div>
        <div fxLayout="row" class="footer-text" fxLayoutAlign="center center">Copyright &copy; 2019 Kemppi Oy.</div>
    </div>

</div>
