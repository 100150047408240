import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatListModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatSnackBarModule
} from '@angular/material';
import {FlexLayoutModule} from '@angular/flex-layout';

import {KsToolbarComponent} from './components/ks-toolbar/ks-toolbar.component';
import {KsActionbarComponent} from './components/ks-actionbar/ks-actionbar.component';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
//        MaterialModule,
        MatButtonModule, MatInputModule, MatFormFieldModule, MatIconModule, MatListModule, MatToolbarModule, MatProgressBarModule, MatSnackBarModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        HttpClientModule,
        TranslateModule, // See app.module for loader
        RouterModule
    ],
    declarations: [KsToolbarComponent, KsActionbarComponent],
    exports: [KsToolbarComponent, KsActionbarComponent]
})
export class KsNavigationModule {
}
