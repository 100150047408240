import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

import {
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatListModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatTreeModule,
    MatDialogModule
} from '@angular/material';
import {FlexLayoutModule} from '@angular/flex-layout';

import { KsNavigationModule } from '../../shared/ks-navigation/ks-navigation.module';
import { DialogsModule } from '../../shared/dialogs/dialogs.module';

import { LabourCostsPageComponent } from './components/labour-costs-page/labour-costs-page.component';
import { SettingsPageComponent } from './components/settings-page/settings-page.component';
import { CostDialogComponent } from './components/cost-dialog/cost-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule, MatInputModule, MatSelectModule, MatCheckboxModule, MatFormFieldModule, MatIconModule, MatListModule, MatToolbarModule, MatProgressBarModule, MatTreeModule,
        MatDialogModule,
        FormsModule,
        FlexLayoutModule,
        HttpClientModule,
        TranslateModule, // See app.module for loader
        RouterModule,

        KsNavigationModule,
        DialogsModule
    ],
    declarations: [
        SettingsPageComponent,
        LabourCostsPageComponent,
        CostDialogComponent
    ],
    entryComponents: [
        CostDialogComponent
    ],
    exports: [
        SettingsPageComponent,
        LabourCostsPageComponent
    ]
})
export class SettingsModule {
}
