import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatFormFieldModule,
    MatIconModule,
    MatListModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatChipsModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatAutocompleteModule
} from '@angular/material';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import {FlexLayoutModule} from '@angular/flex-layout';

import { KsNavigationModule } from '../../shared/ks-navigation/ks-navigation.module';
import { DialogsModule } from '../../shared/dialogs/dialogs.module';
import { WarCustomModule } from '../../shared/war-custom/war-custom.module';

import { ListClaimsPageComponent } from './components/list-claims-page/list-claims-page.component';
import { ClaimPageComponent } from './components/claim-page/claim-page.component';


@NgModule({
    imports: [
        CommonModule,
        MatButtonModule, MatInputModule, MatSelectModule, MatCheckboxModule, MatRadioModule, MatFormFieldModule, MatIconModule, MatListModule, MatToolbarModule, MatProgressBarModule,
        MatChipsModule, MatDatepickerModule, MatTooltipModule, MatDialogModule, MatProgressSpinnerModule, MatSlideToggleModule, MatAutocompleteModule,
        MatMomentDateModule,

        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        HttpClientModule,
        TranslateModule, // See app.module for loader
        RouterModule,

        KsNavigationModule,
        WarCustomModule,
        DialogsModule
    ],
    declarations: [
        ListClaimsPageComponent,
        ClaimPageComponent
    ],
    entryComponents: [
    ],
    exports: [
        ListClaimsPageComponent,
        ClaimPageComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ClaimsModule {
}
