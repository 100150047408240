<div *ngIf="value && value !== false" class="approval" fxLayout="row" fxLayoutAlign="start center">
    <div class="approval-user" fxFlex>
        <b>{{ topic }}</b><br>
        {{value.timestamp | date:'short':undefined:localeService.locale}}<br>
        {{value.user?.name}}<br>
        {{value.user?.email}}
    </div>
    <button *ngIf="editEnabled" mat-icon-button class="button-hover" color="accent" (click)="remove()"><mat-icon>delete</mat-icon></button>
</div>
<div *ngIf="!value && editEnabled" class="approval inactive" fxLayout="row" fxLayoutAlign="start center">
    <div class="approval-user" fxFlex>
        <b>{{ topic }}</b><br>
    </div>
    <button mat-icon-button class="button-hover" color="accent" (click)="add()"><mat-icon>add</mat-icon></button>
</div>
