import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { WarCustomModule } from '../../shared/war-custom/war-custom.module';

import {
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatListModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatAutocompleteModule
} from '@angular/material';
import {FlexLayoutModule} from '@angular/flex-layout';

import { KsNavigationModule } from '../../shared/ks-navigation/ks-navigation.module';
import { DialogsModule } from '../../shared/dialogs/dialogs.module';

import { ListRegistrationsPageComponent } from './components/list-registrations-page/list-registrations-page.component';
import { RegistrationPageComponent } from './components/registration-page/registration-page.component';
import { RegistrationCertificateComponent } from './components/registration-certificate/registration-certificate.component';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule, MatInputModule, MatSelectModule, MatCheckboxModule, MatFormFieldModule, MatIconModule, MatListModule,
        MatToolbarModule, MatProgressBarModule, MatProgressSpinnerModule, MatChipsModule, MatAutocompleteModule,
        FormsModule, ReactiveFormsModule,
        FlexLayoutModule,
        HttpClientModule,
        TranslateModule, // See app.module for loader
        RouterModule,

        KsNavigationModule,
        WarCustomModule,
        DialogsModule
    ],
    declarations: [
        ListRegistrationsPageComponent,
        RegistrationPageComponent,
        RegistrationCertificateComponent
    ],
    entryComponents: [
    ],
    exports: [
        ListRegistrationsPageComponent,
        RegistrationPageComponent,
        RegistrationCertificateComponent
    ]
})
export class RegistrationsModule {
}
