import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LocaleService } from '../../../../services/locale.service';

import * as moment from 'moment';
import * as _ from 'lodash';
import { MachinesService } from 'src/app/services/machines.service';

@Component({
    selector: 'app-registration-certificate',
    templateUrl: './registration-certificate.component.html',
    styleUrls: ['./registration-certificate.component.scss']
})
export class RegistrationCertificateComponent implements OnInit {
    loading: boolean;
    id: string;
    sec: string;

    machineInfo: any;
    registration: any;

    customer: any;
    address: any;
    dealer: any;
    purchaseDate: any;
    warrantyExpireDate: any;
    serialNumber: any;
    product: any;
    productCode: any;

    constructor(
        private route: ActivatedRoute,
        private localeService: LocaleService,
        private machinesServices: MachinesService
    ) {
        this.machineInfo = null;
    }

    ngOnInit() {
        this.loading = true;

        this.route.queryParams.subscribe(params => {
            const language = params['language'];
            if (language) {
                console.log('change lang', language);
                this.localeService.language = language;
            }
            this.sec = params['sec'];
        });

        this.route.params.subscribe(params => {
            // reload state on id change
            this.id = params['id'];

            this.getSerialInfo(this.id, this.sec);
        });
    }

    /**
     * Get serial info
     * SecureId or (company + partnerId) must match
     */
    getSerialInfo(serialNumber, secureId, company = null, partnerId = null) {
        this.machinesServices.getMachineInfo(serialNumber).subscribe((machine: any) => {
            console.log('machine', machine);
            // Machine must have delivery date
            if (machine && machine.deliveryDate
                && (machine.secureId === secureId
                    || (machine.registeredCompany === company && machine.registeredPartnerId === partnerId)
                )
            ) {
                this.machineInfo = machine;
                this.mapDetails();
            }
        });
    }


    mapDetails() {
        if (this.machineInfo) {
            this.serialNumber = this.id;

            this.customer = _.get(this.machineInfo, 'registeredEndCustomerName');
            this.dealer = _.get(this.machineInfo, 'registeredPartnerName');

            const purchaseDate = _.get(this.machineInfo, 'salesDate', null); // Purchase = Dealer registration
            const warrantyExpireDate = _.get(this.machineInfo, 'warrantyExpireDate', null);

            this.purchaseDate = purchaseDate ? moment(purchaseDate).format('DD.MM.YYYY') : null;
            this.warrantyExpireDate = warrantyExpireDate ? moment(warrantyExpireDate).format('DD.MM.YYYY') : null;

            this.product = _.get(this.machineInfo, 'productInfo.partDescription');
            this.productCode = _.get(this.machineInfo, 'productId');
        }
        this.loading = false;
    }

    print() {
        window.print();
    }

}
