import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { MatButtonModule, MatInputModule, MatFormFieldModule, MatIconModule, MatProgressSpinnerModule, MatProgressBarModule,
  MatMenuModule, MatCardModule, MatTooltipModule, MatSelectModule, MatCheckboxModule, MatButtonToggleModule, MatDialogModule,
  MatSnackBarModule, MatDatepickerModule, MatAutocompleteModule,
  MAT_LABEL_GLOBAL_OPTIONS,
  MAT_DATE_LOCALE,
  DateAdapter } from '@angular/material';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { FlexLayoutModule } from '@angular/flex-layout';
import { FlexLayoutModule, BREAKPOINT, DEFAULT_BREAKPOINTS, ShowHideDirective } from '@angular/flex-layout';

import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { KsNavigationModule } from './shared/ks-navigation/ks-navigation.module';
import { BaseModule } from './modules/base/base.module';
import { ClaimsModule } from './modules/claims/claims.module';
import { RegistrationsModule } from './modules/registrations/registrations.module';
import { ReportingModule } from './modules/reporting/reporting.module';
import { SettingsModule } from './modules/settings/settings.module';
import { WarCustomModule } from './shared/war-custom/war-custom.module';
import { DialogsModule } from './shared/dialogs/dialogs.module';

import { AppConfigService } from './services/app-config.service';
import { AuthService } from './services/auth.service';
import { CanDeactivateGuard } from './services/can-deactivate-guard.service';
import { ClaimsService } from './services/claims.service';
import { CurrenciesService } from './services/currencies.service';
import { CustomersService } from './services/customers.service';
import { EndCustomersService } from './services/endcustomers.service';
import { ExcelService } from './services/excel.service';
import { LocaleService } from 'src/app/services/locale.service';
import { LoginService } from './services/login.service';
import { MachineryService } from './services/machinery.service';
import { MachinesService } from './services/machines.service';
import { PartsService } from './services/parts.service';
import { PricesService } from './services/prices.service';
import { RegistrationsService } from './services/registrations.service';
import { RepairTimesService } from './services/repair-times.service';
import { ReportsService } from './services/reports.service';
import { StateService } from './services/state.service';

import { AppComponent } from './app.component';

// HighCharts
import { ChartModule } from 'angular-highcharts';

// For more information about locales, see:
// https://angular.io/guide/i18n#i18n-pipes
// https://stackoverflow.com/questions/46564972/angular-5-breaking-change-manually-import-locale/46733006
// https://github.com/angular/angular/tree/master/packages/common/locales
// http://www.rfc-editor.org/rfc/bcp/bcp47.txt

import { registerLocaleData } from '@angular/common';
// import localeEn from '@angular/common/locales/en';
import localeDa from '@angular/common/locales/da';
import localeDe from '@angular/common/locales/de';
import localeEnGB from '@angular/common/locales/en-GB';
import localeEs from '@angular/common/locales/es';
import localeFi from '@angular/common/locales/fi';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
// import localeJa from '@angular/common/locales/ja';
import localeNl from '@angular/common/locales/nl';
import localeNb from '@angular/common/locales/nb'; // Norwegian Bokmal
import localePl from '@angular/common/locales/pl';
import localePt from '@angular/common/locales/pt';
import localeRu from '@angular/common/locales/ru';
import localeSv from '@angular/common/locales/sv';
import localeZh from '@angular/common/locales/zh';

registerLocaleData(localeDa, 'da');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeEnGB, 'en-GB');
registerLocaleData(localeEs, 'es');
registerLocaleData(localeFi, 'fi');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeIt, 'it');
registerLocaleData(localeNl, 'nl');
registerLocaleData(localeNb, 'nb');
registerLocaleData(localePl, 'pl');
registerLocaleData(localePt, 'pt');
registerLocaleData(localeRu, 'ru');
registerLocaleData(localeSv, 'sv');
registerLocaleData(localeZh, 'zh');

export function createTranslateLoader(http: HttpClient) {
  const date = new Date().getTime(); // Use time as suffix to force reload translation file
  return new TranslateHttpLoader(http, '../../assets/i18n/', '.json?' + date);
}

// export function augmentBreakpointForPrint(bp: BreakPoint): BreakPoint {
//     bp.mediaQuery = 'only screen and ' + bp.mediaQuery;
//     return bp;
// }

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatButtonModule, MatInputModule, MatFormFieldModule, MatIconModule, MatProgressSpinnerModule, MatProgressBarModule,
        MatMenuModule, MatCardModule, MatTooltipModule, MatSelectModule, MatCheckboxModule, MatButtonToggleModule, MatDialogModule,
        MatSnackBarModule, MatDatepickerModule, MatAutocompleteModule,
        MatMomentDateModule,
        FlexLayoutModule,
        FormsModule, ReactiveFormsModule,

        KsNavigationModule,
        BaseModule,
        ClaimsModule,
        RegistrationsModule,
        // MachinesModule,
        ReportingModule,
        SettingsModule,
        WarCustomModule,
        DialogsModule,

        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
                // deps: [Http]
            }
        }),

        ChartModule
    ],
    providers: [
        AppConfigService,
        AuthService,
        ClaimsService,
        CurrenciesService,
        CustomersService,
        EndCustomersService,
        ExcelService,
        LocaleService,
        LoginService,
        MachineryService,
        MachinesService,
        PartsService,
        PricesService,
        RegistrationsService,
        RepairTimesService,
        ReportsService,
        StateService,

        // Global input mat-form-filed mat-label option to float always without fancy bouncing
        { provide: MAT_LABEL_GLOBAL_OPTIONS, useValue: {float: 'always'} },
        // Set globally default language to en-GB. mm/dd/yyyy -> dd/mm/yyyy
        // { provide: LOCALE_ID, useValue: 'en-GB' },
        // { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        { provide: LOCALE_ID, deps: [LocaleService], useFactory: (localeService: LocaleService) => localeService.locale },
        // { provide: DateAdapter, useClass: MomentDateAdapter },
        // Handle date picker dates as UTC (since we don't ask time we cannot be sure when the date changes)
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },

        // {
        //     provide: BREAKPOINT,
        //     useValue: DEFAULT_BREAKPOINTS,
        //     multi: true
        //     // useFactory: function () {
        //     //     return DEFAULT_BREAKPOINTS;
        //     //     // return DEFAULT_BREAKPOINTS.map(augmentBreakpointForPrint);
        //     // }
        // },

        CanDeactivateGuard,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
