<div (click)="state.isMenuOpen = false; state.isUserMenuOpen=false" *ngIf="!printing && state.isMenuOpen || state.isUserMenuOpen" id="backdrop"></div>
<mat-toolbar *ngIf="!printing" [class.child-view]="hasMenu" color="primary" class="ks-toolbar">
    <button [class.hidden]="!hasMenu" [disabled]="!hasMenu" *ngIf="!hasBack"
            (click)="state.isMenuOpen = !state.isMenuOpen" mat-icon-button>
        <mat-icon id="app-menu" class="kemppi-symbols">×</mat-icon>
    </button>
    <button *ngIf="hasBack" (click)="emitBack()" mat-icon-button>
        <mat-icon color="accent" id="app-menu" class="kemppi-symbols">³</mat-icon>
    </button>

    <mat-list class="custom-menu" *ngIf="state.isMenuOpen">
        <!-- Modules -->
        <a *ngIf="publicUse && allowedModules.registrations" mat-list-item (click)="state.isMenuOpen = false" routerLinkActive="active" routerLink="/registrations">
            <mat-icon class="kemppi-symbols">è</mat-icon>
            <span>{{ 'menu.product_registrations' | translate }}</span>
        </a>
        <a *ngIf="publicUse && allowedModules.claims" mat-list-item (click)="state.isMenuOpen = false" routerLinkActive="active" routerLink="/claims">
            <mat-icon class="kemppi-symbols">s</mat-icon>
            <span>{{ 'menu.warranty_claims' | translate }}</span>
        </a>

        <!-- Prices -->
        <ng-container *ngIf="allowedModules.prices">
            <mat-list-item class="topic-item">
                <mat-icon class="kemppi-symbols">©</mat-icon>
                <span>{{ 'menu.settings' | translate }}</span>
            </mat-list-item>

            <a *ngIf="allowedModules.prices" mat-list-item class="child-item" (click)="state.isMenuOpen = false" routerLinkActive="active" routerLink="/prices/labour-costs">
                <mat-icon class="kemppi-symbols">©</mat-icon>
                <span>{{ 'menu.labour_costs' | translate }}</span>
            </a>
        </ng-container>

        <!-- Internal -->
        <ng-container *ngIf="allowedModules.machines || allowedModules.reports || allowedModules.settings">
            <mat-list-item class="topic-item">
                <mat-icon class="kemppi-symbols">Ģ</mat-icon>
                <span>{{ 'menu.kemppi_internal' | translate }}</span>
            </mat-list-item>

            <!--
            <a *ngIf="allowedModules.machines" mat-list-item class="child-item" (click)="state.isMenuOpen = false" routerLinkActive="active" routerLink="/machines">
                <mat-icon class="kemppi-symbols">-</mat-icon>
                <span translate>{{ 'menu.machinery' | translate }}</span>
            </a>
            -->
            <!--
            <a *ngIf="allowedModules.reports" mat-list-item class="child-item" (click)="state.isMenuOpen = false" routerLinkActive="active" routerLink="/reporting/reporting">
                <mat-icon class="kemppi-symbols">Å</mat-icon>
                <span translate>reporting (kibana)</span>
            </a>
            <a *ngIf="allowedModules.reports" mat-list-item class="child-item" (click)="state.isMenuOpen = false" routerLinkActive="active" routerLink="/reporting/reporting-custom">
                <mat-icon class="kemppi-symbols">Å</mat-icon>
                <span translate>reporting (custom)</span>
            </a>
            -->
            <ng-container *ngIf="authUserScope === 'global'">
                <a *ngIf="allowedModules.reports" mat-list-item class="child-item" (click)="state.isMenuOpen = false" routerLinkActive="active" routerLink="/reporting/claims">
                    <mat-icon class="kemppi-symbols">Å</mat-icon>
                    <span>{{ 'menu.reporting' | translate }}</span>
                </a>
            </ng-container>
            <!--
            <ng-container *ngIf="authUserScope === 'global'">
                <a *ngIf="allowedModules.reports" mat-list-item class="child-item">
                    <mat-icon class="kemppi-symbols">Å</mat-icon>
                    <span translate>{{ 'menu.reporting' | translate }} (kibana)</span>
                </a>
                <a *ngIf="allowedModules.reports" mat-list-item class="child-item">
                    <mat-icon class="kemppi-symbols">Å</mat-icon>
                    <span translate>{{ 'menu.reporting' | translate }} (custom)</span>
                </a>
                <a *ngIf="allowedModules.settings" mat-list-item class="child-item" (click)="state.isMenuOpen = false" routerLinkActive="active" routerLink="/settings">
                    <mat-icon class="kemppi-symbols">©</mat-icon>
                    <span translate>{{ 'menu.permissions' | translate }}</span>
                </a>
            </ng-container>
            -->
        </ng-container>
    </mat-list>

    <div fxLayout="row"  fxFlex="grow">
        <img fxShow.lt-md="false" src="assets/images/Kemppi_logo_negative_transparent.png" class="logo">
        <!--
        <mat-icon id="app-icon" fxShow.lt-sm="false" class="kemppi-symbols">{{appInfo.icon}}</mat-icon>
        -->
        <h2 class="app-title" fxShow.lt-sm="false">
            <a routerLink="">{{appInfo.title | translate}}</a>
        </h2>

        <ng-container *ngIf="subTitle && (!file || file === '')">
            <h2 class="app-title">
                <span fxShow.lt-sm="false" class="kemppi-symbols-inline">÷</span>
                {{ 'menu.'+subTitle | translate}}
            </h2>
        </ng-container>

        <ng-container *ngIf="subTitle && (file && file !== '')">
            <h2 class="app-title" fxShow.lt-sm="false">
                <span class="kemppi-symbols-inline">÷</span>
                <a [routerLink]="parentLink">{{ 'menu.'+subTitle | translate}}</a>
            </h2>
            <h2 class="app-subtitle">
                <span fxShow.lt-sm="false" class="kemppi-symbols-inline">÷</span>
                {{file}}
            </h2>
        </ng-container>
    </div>

    <!-- This fills the remaining space of the current row -->

    <div *ngIf="hasMenu" fxFlex="150px" fxLayout="row" fxLayoutAlign="center center" fxShow.lt-sm="false">
        <mat-form-field fxFlex="130px">
            <mat-label>Open warranty claim</mat-label>
            <input matInput [(ngModel)]="claimId" (keydown.enter)="openWarrantyClaim(claimId)">
            <mat-hint class="hint">{{ 'claims.claim_id' | translate }}</mat-hint>
        </mat-form-field>
    </div>

    <div fxLayout="row" fxFlexAlign="center end">
        <!--
        <button mat-icon-button
                class="help"
                aria-label="Help"
                fxShow.lt-sm="false">
            <mat-icon class="kemppi-symbols account">~</mat-icon>
        </button>
        -->

        <section class="tool-bar-user" fxLayout="row"  *ngIf="!state.loading">

            <div fxLayout="column" fxLayoutAlign="center end">
                <section class="name"><strong>{{authService.userProfile?.name}}</strong></section>
                <section *ngIf="authService.userProfile" class="email">{{authService.userProfile?.partnerId}} / {{authService.userProfile?.company}} <small>( {{authService.userProfile?.role }} )</small></section>
            </div>

            <button mat-icon-button
                    (click)="state.isUserMenuOpen = !state.isUserMenuOpen"
                    class="account"
                    aria-label="Account">
                <section fxLayout="column"
                        fxLayoutAlign="center"
                        fxFlexAlign="center"
                        *ngIf="!state.loading">
                    <mat-icon class="kemppi-symbols account">¢</mat-icon>
                    <mat-icon class="kemppi-symbols dropdown">ù</mat-icon>
                </section>
            </button>

            <mat-list class="user-menu" *ngIf="state.isUserMenuOpen">
                <a mat-list-item (click)="state.isLanguageMenuOpen = !state.isLanguageMenuOpen">
                    <span translate>change language</span>
                </a>
                <div class="user-menu-container">
                    <mat-list class="user-menu" *ngIf="state.isLanguageMenuOpen" id="language-menu">
                        <a mat-list-item (click)="setLanguage('en')" fxLayout="column" id="en">
                            <span translate>base.locales.en</span>
                        </a>
                        <a mat-list-item (click)="setLanguage('da')" fxLayout="column" id="da">
                            <span translate>base.locales.da</span>
                        </a>
                        <a mat-list-item (click)="setLanguage('de')" fxLayout="column" id="de">
                            <span translate>base.locales.de</span>
                        </a>
                        <a mat-list-item (click)="setLanguage('zh')" fxLayout="column" id="zh">
                            <span translate>base.locales.zh</span>
                        </a>
                        <a mat-list-item (click)="setLanguage('es')" fxLayout="column" id="es">
                            <span translate>base.locales.es</span>
                        </a>
                        <a mat-list-item (click)="setLanguage('fi')" fxLayout="column" id="fi">
                            <span translate>base.locales.fi</span>
                        </a>
                        <a mat-list-item (click)="setLanguage('fr')" fxLayout="column" id="fr">
                            <span translate>base.locales.fr</span>
                        </a>
                        <a mat-list-item (click)="setLanguage('it')" fxLayout="column" id="it">
                            <span translate>base.locales.it</span>
                        </a>
                        <!--
                        <a mat-list-item (click)="setLanguage('ja')" fxLayout="column" id="ja">
                            <span translate>base.locales.ja</span>
                        </a>
                        -->
                        <a mat-list-item (click)="setLanguage('nl')" fxLayout="column" id="nl">
                            <span translate>base.locales.nl</span>
                        </a>
                        <a mat-list-item (click)="setLanguage('nb')" fxLayout="column" id="nb">
                            <span translate>base.locales.nb</span>
                        </a>
                        <a mat-list-item (click)="setLanguage('pl')" fxLayout="column" id="pl">
                            <span translate>base.locales.pl</span>
                        </a>
                        <!--
                        <a mat-list-item (click)="setLanguage('pt')" fxLayout="column" id="pt">
                            <span translate>base.locales.pt</span>
                        </a>
                        -->
                        <a mat-list-item (click)="setLanguage('ru')" fxLayout="column" id="ru">
                            <span translate>base.locales.ru</span>
                        </a>
                        <a mat-list-item (click)="setLanguage('sv')" fxLayout="column" id="sv">
                            <span translate>base.locales.sv</span>
                        </a>
                    </mat-list>
                </div>

                <a *ngIf="!authService.authenticated()" mat-list-item (click)="login()">
                    <span translate>login</span>
                </a>
                <a *ngIf="authService.authenticated()" mat-list-item (click)="logout()">
                    <span translate>logout</span>
                </a>
            </mat-list>



        </section>
    </div>
</mat-toolbar>
