import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as _ from 'lodash';

import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';

declare var require: any;

// Partner endcustomers service
// Stores end customers to warranty S3 userdata and ES warranty-endcustomers

@Injectable()
export class EndCustomersService {

    BACKENDURL: string;

    constructor(
        private appConfigService: AppConfigService,
        private authService: AuthService,
        private http: HttpClient
    ) {
        this.BACKENDURL = this.appConfigService.config.warrantyBackendUrl;
    }

    get token() {
        return this.authService.accessToken;
    }


    getEndCustomer(company, partnerId, id) {
        console.log('getEndCustomer', company, partnerId, id);
        return this.http.get(`${this.BACKENDURL}/endcustomers/company/${company}/partner/${partnerId}/${id}`, { headers: this._getHeaders() });
    }

    saveEndCustomer(company, partnerId, id, endCustomer) {
        console.log('saveEndCustomer', company, partnerId, id, endCustomer);
        if (!id) {
            // Create new (POST)
            return this.http.post(`${this.BACKENDURL}/endcustomers/company/${company}/partner/${partnerId}`, JSON.stringify(endCustomer), { headers: this._getHeaders() });
        } else {
            // Update existing one (PUT)
            return this.http.put(`${this.BACKENDURL}/endcustomers/company/${company}/partner/${partnerId}/${id}`, JSON.stringify(endCustomer), { headers: this._getHeaders() });
        }
    }

    deleteEndCustomer(company, partnerId, id) {
        return this.http.delete(`${this.BACKENDURL}/endcustomers/company/${company}/partner/${partnerId}/${id}`, { headers: this._getHeaders() });
    }

    // Returns search params ?search=
    // key=value&key2=value2,value22
    // key%3Dvalue%26key2%3Dvalue2%2Cvalue22
    _getSearchString(params) {
        const queryStringParams = _.map(params, (p) => {
            return p.key + '%3D' + p.value;
        }).join('%26').replace(/,/g, '%2C');
        if (queryStringParams) {
            return `&search=${queryStringParams}`;
        } else {
            return '';
        }
    }

      // Returns sort params ?sort=key:direction;key2:direction2
      _getSortString(params: Array<{ key: string; direction: string }>) {
        console.log(params);
        const queryStringParams = _.map(params, (p) => {
            return p.key + ':' + p.direction;
        }).join(',');
        return queryStringParams;
    }

    searchEndCustomersByPartner(company, partnerId, params, size = 50, from = 0, sort: Array<{ key: string; direction: string }>) {
        console.log('searchEndCustomersByPartner', company, partnerId, params, size);
        return this.http.get(`${this.BACKENDURL}/endcustomers/company/${company}/partner/${partnerId}?size=${size}&from=${from}${this._getSearchString(params)}&sort=${this._getSortString(sort)}`,
            { headers: this._getHeaders() }
        );
    }

    _getHeaders() {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
        });
    }

}
