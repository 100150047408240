import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

import {
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIconModule,
    MatListModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatTabsModule
} from '@angular/material';
import {FlexLayoutModule} from '@angular/flex-layout';

import { KsNavigationModule } from '../../shared/ks-navigation/ks-navigation.module';
import { WarCustomModule } from '../../shared/war-custom/war-custom.module';
// HighCharts
import { ChartModule } from 'angular-highcharts';

import { ReportingCustomPageComponent } from './components/custom-page/custom-page.component';
import { ReportingDashboardPageComponent } from './components/dashboard-page/dashboard-page.component';
import { ReportsClaimsPageComponent } from './components/reports-claims-page/reports-claims-page.component';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule, MatInputModule, MatSelectModule, MatFormFieldModule, MatIconModule, MatListModule, MatToolbarModule, MatProgressBarModule, MatTabsModule,
        FormsModule,
        FlexLayoutModule,
        HttpClientModule,
        TranslateModule, // See app.module for loader
        RouterModule,

        KsNavigationModule,
        WarCustomModule,
        ChartModule
    ],
    declarations: [
        ReportingCustomPageComponent,
        ReportingDashboardPageComponent,
        ReportsClaimsPageComponent
    ],
    exports: [
        ReportingCustomPageComponent,
        ReportingDashboardPageComponent,
        ReportsClaimsPageComponent
    ]
})
export class ReportingModule {
}
