import * as moment from 'moment';

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { StateService } from 'src/app/services/state.service';

import { Registration } from 'src/app/models/registration';
import { MachineRegistration, RegistrationsService } from 'src/app/services/registrations.service';

import { Company, Companies } from 'src/app/models/company';
import { Permissions } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { LocaleService } from 'src/app/services/locale.service';
import { ExcelService } from 'src/app/services/excel.service';

import * as _ from 'lodash';

@Component({
    selector: 'app-list-registrations-page',
    templateUrl: './list-registrations-page.component.html',
    styleUrls: ['./list-registrations-page.component.scss']
})
export class ListRegistrationsPageComponent implements OnInit {

    /**
     * Registrations
     */
    registrations: Array<MachineRegistration> = [];

    /**
     * Claims count
     */
    resultHits: number;

    /**
     * Claims total count
     */
    totalHits: number;

    /**
     * List of companies
     */
    companies: Array<Company> = [];

    /**
     * Search parameters, passed to search
     */
    searchParams: any = [];

    /**
     * Number of claims returned with search
     */
    searchSize: number;

    /**
     * Starting point of search results
     */
    searchFrom: number;

    /**
     * Permission to create new registrations, check from auth service 'registrations.write' action
     */
    canCreate = false;

    /**
     * User permissions, same as authService.userProfile.permissions
     */
    permissions: Permissions = {
        scope: '',
        modules: {},
        actions: {}
    };

    /**
     * Filter by company
     */
    registrationCompanyNumber: Array<string>;

    /**
     * Filter chips
     */
    filterChips = [];

    /**
     * Current search bar value
     */
    searchText = '';

    /**
     * Current search value behind the text
     */
    searchValue = '';

    /**
     * Filter names mapping, (field path: translation key)
     */
    filterNames = {
        'id': 'common.serial_number',
        'registeredEndCustomerName': 'common.end_customer',
        'registeredEndCustomerName.normalize': 'common.end_customer',
        'productId': 'common.product_number',
        'partnerId': 'common.partner_id'
    };

    /**
     * All registration rows selected
     */
    allRegistrationsChecked: boolean;

    /**
     * Collection of registration id's that are checked
     */
    selectedRegistrations: object;
    // Selected registrations and action button flags
    anyRegistrationSelected: boolean;

    registrationsLoading: boolean;

    sortParams: any = {
        key: 'partnerRegistrationDate',
        direction: 'desc'
    };

    now: string;

    constructor(
        private router: Router,
        public stateService: StateService,
        private registrationsService: RegistrationsService,
        private authService: AuthService,
        private localeService: LocaleService,
        private excelService: ExcelService
    ) {
        this.now = new Date().toISOString();

        this.companies = Companies;
        this.stateService.state.hasBack = false;
    }

    ngOnInit() {
        setTimeout(() => {
            this.stateService.state.file = '';

            this.registrationCompanyNumber = [];
            this.allRegistrationsChecked = false;
            this.selectedRegistrations = {};

            this.permissions = this.authService.userProfile.permissions;

            this.searchSize = 50;
            this.searchFrom = 0;

            this.canCreate = this.authService.isAllowedTo('registrations.write');

            this.search();
        });
    }

    /**
     * Get registrations
     * According to user permission get all (global) / company / partner registrations
     */
    getRegistrations() {
        this.registrationsLoading = true;
        if (this.permissions.scope === 'global') {
            console.log('search all registrations', this.searchParams);
            this.registrationsService.searchRegistrationsAll(this.searchParams, this.searchSize, this.searchFrom, [this.sortParams])
            .subscribe((response: any) => {
                if (typeof response.items !== 'undefined' && typeof response.totalCount !== 'undefined') {
                    this.registrations.push(...response.items);
                    this.totalHits = response.totalCount;
                } else {
                    this.registrations = response;
                }
                this.resultHits = this.registrations.length;
                this.resetSelectedRegistrations();
                this.registrationsLoading = false;
            }, (error) => {
                this.registrationsLoading = false;
            });
        } else if (this.permissions.scope === 'company') {
            console.log('search company registrations', this.searchParams);
            this.registrationsService.searchRegistrationsByCompany(this.authService.userProfile.company,
                this.searchParams, this.searchSize, this.searchFrom, [this.sortParams])
            .subscribe((response: any) => {
                if (typeof response.items !== 'undefined' && typeof response.totalCount !== 'undefined') {
                    this.registrations.push(...response.items);
                    this.totalHits = response.totalCount;
                } else {
                    this.registrations = response;
                }
                this.resultHits = this.registrations.length;
                this.resetSelectedRegistrations();
                this.registrationsLoading = false;
            }, (error) => {
                this.registrationsLoading = false;
            });
        } else if (this.permissions.scope === 'partner') {
            console.log('search partner registrations', this.searchParams);
            this.registrationsService.searchRegistrationsByPartner(this.authService.userProfile.company, this.authService.userProfile.partnerId,
                this.searchParams, this.searchSize, this.searchFrom, [this.sortParams])
            .subscribe((response: any) => {
                if (typeof response.items !== 'undefined' && typeof response.totalCount !== 'undefined') {
                    this.registrations.push(...response.items);
                    this.totalHits = response.totalCount;
                } else {
                    this.registrations = response;
                }
                this.resultHits = this.registrations.length;
                this.resetSelectedRegistrations();
                this.registrationsLoading = false;
            }, (error) => {
                this.registrationsLoading = false;
            });
        }
    }

    loadMore() {
        this.searchFrom = this.searchFrom + this.searchSize;
        this.searchSize = 50;
        this.getRegistrations();
    }

    search() {
        // Reset hits and start new searching from begining
        this.totalHits = 0;
        this.resultHits = 0;
        this.registrations = [] as Array<MachineRegistration>;
        this.searchSize = 50;
        this.searchFrom = 0;
        this.getRegistrations();
    }

    sort(sortKey) {
        console.log('sort', sortKey);
        const currentSortObj = this.sortParams || null;
        let sortDirection = 'desc';
        if (currentSortObj && currentSortObj.key && currentSortObj.key === sortKey) {
            sortDirection = this.sortParams.direction === 'desc' ? 'asc' : 'desc';
        }
        this.sortParams = {
            key: sortKey,
            direction: sortDirection
        };
        this.search();
    }

    setFilter(key, value) {
        if (value === '') {
            this.removeFilter(key);
        } else {
            // Find item index
            const index = _.findIndex(this.searchParams, { key: key });
            if (index !== -1) {
                // Replace item at index
                this.searchParams.splice(index, 1, { key: key, value: value });
            } else {
                // Add new item
                this.searchParams.push({ key: key, value: value });
            }
            this.search();
        }
    }

    removeFilter(key) {
        // Find item index
        const index = _.findIndex(this.searchParams, { key: key });
        if (index !== -1) {
            // Remove item at index
            this.searchParams.splice(index, 1);
        }
        this.search();
    }

    searchKeyEvent(event) {
        // backspace
        if (event.keyCode === 8 && this.searchText === '') {
            this.removeFilterChip(this.filterChips.length - 1);
        }
    }

    searchValueUpdate() {
        const value = this.searchText.trim();
        this.searchValue = value;
    }

    addFilterChip(key, value) {
        value = value.trim();
        if (key === 'partnerId' || key === 'productId') {
            value = value.toUpperCase();
        }
        if (value !== '') {
            this.filterChips.push({ key: key, text: value });
            this.setFilter(key, '*' + value + '*' );
            this.searchText = '';
            this.searchValue = '';
        }
    }

    removeFilterChip(index) {
        if (this.filterChips[index]) {
            const key = this.filterChips[index].key;
            this.filterChips.splice(index, 1);
            this.removeFilter(key);
        }
    }

    resetSearchParams() {
        this.searchParams = [];
        this.filterChips = [];
        this.search();
    }

    /**
     * Reset selected registrations / deselect all selections
     */
    resetSelectedRegistrations() {
        this.allRegistrationsChecked = false;
        this.selectedRegistrations = {};
        for (const reg of this.registrations) {
            this.selectedRegistrations[reg.id] = false;
        }
        this.checkSelectedRegistrations();
    }

    /**
     * Check or uncheck allRegistrationsChecked
     */
    selectAllRegistrations(checked) {
        _.map(this.registrations, (reg) => {
            this.selectedRegistrations[reg.id] = checked;
        });
        this.checkSelectedRegistrations();
    }
    /**
     * Check selected registrations
     * Raise flags to disable action buttons if action is not allowed
     */
    checkSelectedRegistrations() {
        this.anyRegistrationSelected = _.some(_.values(this.selectedRegistrations), (value) => value === true);
    }

    /**
     * Export selected claims to Excel XLSX format
     */
    async exportSelectedRegistrationsToXLSX() {
        const regs = await this._getSelectedRegistrationsForExport();
        const sheets = [
            { name: 'registrations', json: regs }
        ];
        this.excelService.exportAsExcelFileMultisheet(sheets, 'registrations');
    }

    /**
     * Get export data
     * Depends on user role / scope / permissions
     */
    async _getSelectedRegistrationsForExport() {
        const items = _.map(
            _.filter(this.registrations, (reg) => {
                return this.selectedRegistrations[reg.id] ? true : false;
            }),
            (reg) => {
                const regRow = {
                    'Serial': reg.serialNumber,
                    'Product number': reg.productId,
                    'Product name': _.get(reg, 'productInfo.partDescription', ''),
                    'Customer': _.get(reg, 'registeredEndCustomerName', ''),
                    'Registration date': reg.partnerRegistrationDate ? moment(reg.partnerRegistrationDate).format('YYYY-MM-DD') : '',
                    'Sales date': reg.salesDate ? moment(reg.salesDate).format('YYYY-MM-DD') : '',
                    'Customer registration': reg.endCustomerRegistrationDate ? moment(reg.endCustomerRegistrationDate).format('YYYY-MM-DD') : '',
                    'Warranty expire date': reg.warrantyExpireDate ? moment(reg.warrantyExpireDate).format('YYYY-MM-DD') : ''
                };
                return regRow;
            }
        );
        return items;
    }

    openRegistration(company, partnerId, serialNumber) {
        this.router.navigate(['registrations', company, partnerId, serialNumber]);
    }

    createNewRegistration() {
        const user = this.authService.userProfile;
        this.router.navigate(['registrations', user.company, user.partnerId, 'new']);
    }

}
