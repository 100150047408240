<div class="main">
    <div class="full" class="page-container" fxLayout="column" fxLayoutGap="20px">
        <fieldset fxFlex>
            <legend>Filters</legend>
            <div class="full" fxLayout="column">
                <div fxLayout="row" fxLayoutGap="20px">
                    <mat-form-field>
                        <mat-label>Date type</mat-label>
                        <mat-select [(value)]="filter.dateType">
                            <mat-option value="deliveryDate">Delivery date</mat-option>
                            <mat-option value="manufactureDate">Manufacture date</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Start date</mat-label>
                        <input matInput [(ngModel)]="filter.startDate">
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>End date</mat-label>
                        <input matInput [(ngModel)]="filter.endDate">
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutGap="20px">
                    <mat-form-field>
                        <mat-label>Product id</mat-label>
                        <input matInput [(ngModel)]="filter.productId">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Product group</mat-label>
                        <input matInput [(ngModel)]="filter.productGroup">
                    </mat-form-field>

                </div>

                <div fxLayout="row">
                    <button mat-button mat-raised-button color="accent" (click)="getData()">
                    Update
                    </button>
                </div>
            </div>
        </fieldset>

        <fieldset fxFlex>
            <legend>Results</legend>
            <div class="results" *ngIf="machinesAgg && claimsAgg && machinesTypes">
                Faulty %
                <div fxLayout="row">
                    <div style="width:150px;">productId</div>
                    <div style="width:320px;">productName</div>
                    <div style="width:120px;">productGroup</div>
                    <div style="width:120px;"># of claims</div>
                    <div style="width:120px;">Total quantity</div>
                    <div style="width:120px;">Faulty % (Claims)</div>
                </div>
                    <div fxLayout="row">
                    <div style="width:150px;"><b>Total</b></div>
                    <div style="width:320px;"></div>
                    <div style="width:120px;"></div>
                    <div style="width:120px;"><b>{{claimsHits}}</b></div>
                    <div style="width:120px;"><b>{{machinesHits}}</b></div>
                    <div style="width:120px;"><b>{{ (100 / machinesHits * claimsHits) | number:'1.2-2' }}</b></div>
                </div>
                <div *ngIf="machinesAgg && claimsAgg && machinesTypes" fxLayout="column">
                    <div *ngFor="let product of machinesAgg" fxLayout="row">
                        <div style="width:150px;">{{product.key}}</div>
                        <div style="width:320px;">{{machinesTypes[product.key]?.productName}}</div>
                        <div style="width:120px;">{{machinesTypes[product.key]?.productGroup}}</div>
                        <div style="width:120px;">{{ (lodash.find(claimsAgg, { key: product.key }) || { doc_count: 0 }).doc_count }}</div>
                        <div style="width:120px;">{{product.doc_count}}</div>
                        <div style="width:120px;">{{ (100 / product.doc_count * ((lodash.find(claimsAgg, { key: product.key }) || { doc_count: 0 }).doc_count)) | number:'1.2-2' }}</div>
                    </div>
                </div>
            </div>
        </fieldset>
    </div>
<!--
    <div class="debug">
        Machines
        <div fxLayout="row">
            <div style="width:150px;"></div>
            <div style="width:300px;"></div>
            <div style="width:120px;"></div>
            <div>{{machinesHits}}</div>
        </div>
        <div *ngIf="machinesAgg && machinesTypes" fxLayout="column">
            <div *ngFor="let product of machinesAgg" fxLayout="row">
                <div style="width:150px;">{{product.key}}</div>
                <div style="width:320px;">{{machinesTypes[product.key]?.productName}}</div>
                <div style="width:120px;">{{machinesTypes[product.key]?.productGroup}}</div>
                <div>{{product.doc_count}}</div>
            </div>
        </div>
    </div>

    <div class="debug">
        Claims
        <div fxLayout="row">
            <div style="width:120px;">Total</div>
            <div>{{claimsHits}}</div>
        </div>
        <div *ngIf="claimsAgg" fxLayout="column">
            <div *ngFor="let product of claimsAgg" fxLayout="row">
                <div style="width:120px;">{{product.key}}</div>
                <div>{{product.doc_count}}</div>
            </div>
        </div>
    </div>
-->
    <!--
    <div class="debug">
            <p>Machines</p>
            <pre>
            {{machines | json}}
            </pre>

            <p>Claims</p>
            <pre>
            {{claims | json}}
            </pre>
    </div>
    -->

</div>