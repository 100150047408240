import * as _ from 'lodash';

import { UserProfile } from 'src/app/models/user';
import { Claim } from 'src/app/models/claim';
import { AuthService } from 'src/app/services/auth.service';

import { CompanyCodes } from 'src/app/models/company';

const flattenKeys = (obj, path = []) =>
    !_.isObject(obj)
        ? { [path.join('.')]: obj }
        : _.reduce(obj, (cum, next, key) => _.merge(cum, flattenKeys(next, [...path, key])), {});


export interface ApprovalActions {
    add: boolean;
    remove?: boolean;
}

export interface ClaimPageActions {
    can: {
        /**
         * Permission to edit the claim fields, check from auth service 'claims.write' action and user role
         * Disables fields and buttons in template
         */
        edit: boolean;

        /**
         * Permission to save the claim, check from auth service 'claims.write' action and claim current state
         * Disables approval and save buttons on toolbar
         */
        save: boolean;

        /**
         * Permission to delete the claim, check from auth service 'claims.write' action
         * And current status
         * Delers and subsidiaries can only delete drafts
         */
        delete: boolean;

        /**
         * Permission to add safety test (after claim is already sent to approval, but before its invoiced)
         */
        addSafety: boolean;

        /**
         * Permission to edit claim order number fields, for logistics usage.
         */
        editOrderNumbers: boolean;
    };
    approvals: {
        partner: ApprovalActions;
        inProgress: ApprovalActions;
        returned: ApprovalActions;
        rejected: ApprovalActions;
        subsidiary: ApprovalActions;
        accepted: ApprovalActions;
        invoiced: ApprovalActions;
        invoicedInternal: ApprovalActions;
        refunded: ApprovalActions;
        refundedInternal: ApprovalActions;
        inQueue: ApprovalActions;
        logistics: ApprovalActions;
    };
}

export class ClaimActions {
    /**
     * Available company codes suchs as '100', '109', '130'...
     */
    companyCodes: Array<string>;

    private authService: AuthService;
    private user: UserProfile;
    private authUserScope: string;
    private authUserRole: string;
    private claim: Claim;
    private actions: ClaimPageActions;

    public isDealerClaim: boolean;
    public isDistributorClaim: boolean;

    constructor(authService, claim) {
        this.companyCodes = CompanyCodes;

        this.authService = authService;
        this.user = this.authService.userProfile;
        this.authUserScope = this.user.permissions.scope;
        this.authUserRole = this.user.role;
        this.claim = claim;

        this.init();
        this.update();
    }

    get can() {
        return this.actions.can;
    }

    get approvals() {
        return flattenKeys(this.actions.approvals);
    }

    async init() {
        this.actions = {
            can: {
                save: false,
                edit: false,
                delete: false,
                addSafety: false,
                editOrderNumbers: false
            },
            approvals: {
                partner: {
                    add: false,
                    remove: false
                },
                inProgress: {
                    add: false
                },
                returned: {
                    add: false
                },
                rejected: {
                    add: false,
                    remove: false
                },
                subsidiary: {
                    add: false,
                    remove: false
                },
                accepted: {
                    add: false,
                    remove: false
                },
                invoiced: {
                    add: false
                },
                invoicedInternal: {
                    add: false
                },
                refunded: {
                    add: false
                },
                refundedInternal: {
                    add: false
                },
                inQueue: {
                    add: false,
                    remove: false
                },
                logistics: {
                    add: false,
                    remove: false
                }
            }
        };
    }

    async update() {
        console.log('CLAIM ACTIONS UPDATE');
        if (!this.actions) {
            this.init();
        }

        // Check claim owner type
        this.checkClaimOwnerType();

        // Can edit and save claim
        this.setCanEditAndSave();
        console.log('CLAIM CAN', this.can);

        // Can delete document?
        this.setCanDelete();

        // Can add/remove approvals?
        this.setApprovals();
        console.log('CLAIM APPROVALS', this.approvals);
    }


    /**
     * Sets canEdit and canSave boolean flags.
     * Checks claim current states, user scopes and roles to allow edit or save.
     */
    setCanEditAndSave() {
        // CLAIMS WRITE permission required
        if (this.authService.isAllowedTo('claims.write', this.claim.company, this.claim.partnerId)
            || this.authService.isWuxiAllowedTo('claims.write', this.claim)
        ) {
            if (this.claim.state.closed) {
                // claim = CLOSED
                this.actions.can.edit = false;
                this.actions.can.save = false;
            } else if (this.claim.id === 'new') {
                // claim = NEW
                this.actions.can.edit = true;
                this.actions.can.save = true;
            } else if (this.authUserScope === 'partner' && (
                this.claim.state.currentState === 'DRAFT'
                || this.claim.state.currentState === 'RETURNED'
            )) {
                // roles: cus_dealer, cus_direct, cus_distributor, cus_workshop
                this.actions.can.edit = true;
                this.actions.can.save = true;
            } else if (this.authUserScope === 'company' && (
                this.claim.state.currentState === 'DRAFT'
                || this.claim.state.currentState === 'OPEN'
                || this.claim.state.currentState === 'IN PROGRESS'
                || (this.claim.state.currentState === 'ACCEPTED' && this.claim.state.internalState === 'RETURNED')
                || (this.claim.state.currentState === 'ACCEPTED' && this.authService.isWuxiAllowedTo('claims.write', this.claim))
            )) {
                // roles: sub_user
                this.actions.can.edit = true;
                this.actions.can.save = true;
            } else if (this.authUserScope === 'global') {
                // roles: sys_admin, koy_user, koy_logistics
                if (this.authUserRole === 'koy_logistics') {
                    this.actions.can.edit = false;
                    this.actions.can.editOrderNumbers = true;
                } else {
                    this.actions.can.edit = true;
                    this.actions.can.editOrderNumbers = true;
                }
                this.actions.can.save = true;
            } else {
                this.actions.can.edit = false;
                this.actions.can.save = false;
            }

            // Extra permission to add safety test during the approval process
            if (this.claim.id && this.claim.id !== 'new' && !this.claim.data.safetyTest && (this.claim.state.invoiceState === '')) {
                this.actions.can.addSafety = true;
            } else {
                this.actions.can.addSafety = false;
            }
        } else {
            // readonly users edit and save always false
            this.actions.can.edit = false;
            this.actions.can.save = false;
        }
    }

    /**
     * Sets canDelete boolean flag. Checks is user allowed to delete the claim.
     */
    setCanDelete() {
        if (this.authService.isAllowedTo('claims.write', this.claim.company, this.claim.partnerId) && (this.claim.state.currentState === 'DRAFT')) {
            this.actions.can.delete = true;
        }
    }

    setApprovals() {
        // Approval buttons, if action is allowed button is shown in claim-page.component template
        // PARTNER
        if (this.authUserScope === 'partner') {
            // New or returned claim
            if (this.actions.can.save && this.claim.state.currentState === 'DRAFT' || this.claim.state.currentState === 'RETURNED') {
                this.actions.approvals.partner.add = true;
            }

            // Cancel send to approval, not yet handled by subsidiary
            if (this.claim.state.currentState === 'OPEN' && (this.claim.state.internalState === '' || this.claim.state.internalState === 'WAITING')) {
                this.actions.approvals.partner.remove = true;
            }
        }

        // COMPANY
        if (this.authUserScope === 'company') {
            // In progress
            if (this.actions.can.save && this.claim.state.currentState === 'OPEN') {
                this.actions.approvals.inProgress.add = true;
            }
            // Waiting for subsidiary acception
            if (this.actions.can.save && (
                    this.claim.state.currentState === 'DRAFT' ||
                    this.claim.state.currentState === 'OPEN' ||
                    this.claim.state.currentState === 'IN PROGRESS' ||
                    (this.claim.state.currentState === 'ACCEPTED' && this.claim.state.internalState === 'RETURNED')
                )
            ) {
                this.actions.approvals.returned.add = this.claim.state.currentState !== 'ACCEPTED';
                this.actions.approvals.rejected.add = this.claim.state.currentState !== 'ACCEPTED';
                this.actions.approvals.subsidiary.add = true;
            }

            // Accepted by subsidiary, but not handled by KOY
            if (this.claim.state.currentState === 'ACCEPTED' && this.claim.state.internalState === 'WAITING') {
                if (this.authService.isWuxiAllowedTo('claims.write', this.claim)) {
                    // WUXI
                    this.actions.approvals.returned.add = true;
                    this.actions.approvals.rejected.add = true;
                    this.actions.approvals.accepted.add = true;
                } else {
                    // NORMAL SUBSIDIARY
                    this.actions.approvals.subsidiary.remove = true;
                }
            }

            // Cancel the acception. Accepted, but not handled by logistics yet.
            if (this.claim.state.currentState === 'ACCEPTED' && this.claim.state.internalState === 'ACCEPTED' && this.claim.state.logisticsState === '' && this.claim.state.invoiceState === '') {
                // WUXI
                if (this.authService.isWuxiAllowedTo('claims.write', this.claim)) {
                    this.actions.approvals.accepted.remove = true;
                }
            }
        }

        // GLOBAL
        if (this.authUserScope === 'global') {
            console.log('GLOBAL', this.actions.can.save, this.actions.can.edit, (this.claim.state.internalState === '' || this.claim.state.internalState === 'WAITING'));
            // Waiting for acception
            if (this.actions.can.save && this.actions.can.edit && (this.claim.state.internalState === '' || this.claim.state.internalState === 'WAITING')) {
                if (this.claim.state.currentState !== 'RETURNED') {
                    this.actions.approvals.returned.add = true;
                }
                this.actions.approvals.rejected.add = true;
                this.actions.approvals.accepted.add = true;
            }
            // Cancel the acception. Accepted, but not handled by logistics yet.
            if (this.claim.state.currentState === 'ACCEPTED' && this.claim.state.internalState === 'ACCEPTED' && this.claim.state.logisticsState === '' && this.claim.state.invoiceState === '') {
                this.actions.approvals.accepted.remove = true;
            }
        }

        // INVOICE
        if (this.isDealerClaim && this.claim.state.currentState === 'ACCEPTED' && this.claim.state.internalState === 'ACCEPTED' && this.claim.state.invoiceState === '') {
            this.actions.approvals.invoiced.add = true;
        }

        // REFUND and INTERNAL INVOICE
        if ((this.authUserScope === 'company' || this.authUserScope === 'global') && this.claim.state.currentState === 'ACCEPTED' && this.claim.state.internalState === 'ACCEPTED') {
            if (this.claim.state.invoiceState === '') {
                this.actions.approvals.refunded.add = true;
            }
            if (!this.isDistributorClaim && !this.claim.partnerId.startsWith('IN') && this.claim.state.internalInvoiceState === '') {
                this.actions.approvals.invoicedInternal.add = true;
            }
        }

        // LOGISTICS and INTERNAL REFUND
        if (this.actions.can.save && this.authUserScope === 'global' && (this.claim.state.currentState === 'ACCEPTED' && this.claim.state.internalState === 'ACCEPTED')) {
            // Distributor or Indian dealer claim, allow send to logistics
            if ((this.isDistributorClaim || this.claim.partnerId.startsWith('IN'))
            && (this.claim.state.logisticsState === '')) {
                this.actions.approvals.inQueue.add = true;
            }

            // Cancel send to logistics. Not yet handled by logistics.
            if (this.claim.state.logisticsState && this.claim.state.logisticsState === 'QUEUED' && this.claim.state.invoiceState === '') {
                this.actions.approvals.inQueue.remove = true;
            }

            // Handle by logistics.
            if (this.claim.state.logisticsState && this.claim.state.logisticsState === 'QUEUED') {
                this.actions.approvals.logistics.add = true;
            }

            // Cancel handled to logistics. Not yet closed.
            if (this.claim.state.logisticsState && this.claim.state.logisticsState === 'HANDLED' && this.claim.state.closed === false) {
                this.actions.approvals.logistics.remove = true;
            }

            // Refund
            if (this.actions.can.edit && this.claim.company === '100' && this.claim.state.invoiceState === 'INVOICED') {
                this.actions.approvals.refunded.add = true;
            }

            // Refund internal
            if (this.actions.can.edit && this.claim.state.internalInvoiceState === 'INVOICED') {
                this.actions.approvals.refundedInternal.add = true;
            }

        }

        // Remove rejected
        if ((this.authUserScope === 'company' || this.authUserScope === 'global') && (this.claim.state.currentState === 'REJECTED')) {
            this.actions.approvals.rejected.remove = true;
        }

    }

    checkClaimOwnerType() {
        if (this.claim.company === '100' && !this.claim.partnerId.startsWith('FI') && this.companyCodes.indexOf(this.claim.partnerId) === -1) {
            this.isDistributorClaim = true;
        } else {
            this.isDistributorClaim = false;
        }

        if (this.companyCodes.indexOf(this.claim.partnerId) === -1) {
            this.isDealerClaim = true;
        } else {
            this.isDealerClaim = false;
        }
    }

}
