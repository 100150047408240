import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { StateService } from '../../../../services/state.service';

@Component({
    selector: 'app-settings-page',
    templateUrl: './settings-page.component.html',
    styleUrls: ['./settings-page.component.scss']
})
export class SettingsPageComponent implements OnInit {

    state: any;

    permissionRoles: Array<string>;
    permissionScopes: Array<string>;
    permissionModules: Array<string>;
    permissionActions: Array<any>;
    permissions: any;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public stateService: StateService
    ) {
        this.stateService.state.hasBack = false;

        this.state = {
            busy: false
        };

        this.permissionRoles = [
            'sys_admin',
            'koy_admin',
            'koy_user',
            'sub_admin',
            'sub_user',
            'cus_direct',
            'cus_dealer',
            'cus_dealer_no_service',
            'cus_workshop'
        ];
        this.permissionScopes = [
            'global',
            'company',
            'partner'
        ];
        this.permissionModules = [
            'registrations',
            'claims',
            'machines',
            'reports',
            'settings'

        ];
        this.permissionActions = [
            'registrations.read',
            'registrations.write',
            'claims.read',
            'claims.write',
            'machines.read',
            'reports.read',
            'settings.read',
            'settings.write'
        ];
    }

    ngOnInit() {
        setTimeout(() => {
            this.stateService.state.file = '';
        });
        this.permissions = this.getDebugRolePermissions();
    }

    save() {

    }
    cancel() {
        this.router.navigate(['registrations', 'registrations']);
    }

    getDebugRolePermissions() {
        return {
            sys_admin: {
                scope: 'global',
                modules: {
                    'registrations': true,
                    'claims': true,
                    'machines': true,
                    'reports': true,
                    'settings': true
                },
                actions: {
                    'registrations.read': true,
                    'registrations.write': true,
                    'claims.read': true,
                    'claims.write': true,
                    'machines.read': true,
                    'reports.read': true,
                    'settings.read': true,
                    'settings.write': true
                }
            },
            koy_admin: {
                scope: 'global',
                modules: {
                    'registrations': true,
                    'claims': true,
                    'machines': true,
                    'reports': true,
                    'settings': true
                },
                actions: {
                    'registrations.read': true,
                    'registrations.write': true,
                    'claims.read': true,
                    'claims.write': true,
                    'machines.read': true,
                    'reports.read': true,
                    'settings.read': true
                }
            },
            koy_user: {
                scope: 'global',
                modules: {
                    'registrations': true,
                    'claims': true,
                    'machines': true,
                    'reports': true,
                    'settings': true
                },
                actions: {
                    'registrations.read': true,
                    'claims.read': true,
                    'machines.read': true,
                    'reports.read': true,
                    'settings.read': true
                }
            },
            sub_admin: {
                scope: 'company',
                modules: {
                    'registrations': true,
                    'claims': true,
                    'machines': true
                },
                actions: {
                    'registrations.read': true,
                    'registrations.write': true,
                    'claims.read': true,
                    'claims.write': true,
                    'machines.read': true
                }
            },
            sub_user: {
                scope: 'company',
                modules: {
                    'registrations': true,
                    'claims': true,
                    'machines': true
                },
                actions: {
                    'registrations.read': true,
                    'claims.read': true,
                    'machines.read': true
                }
            },
            cus_direct: {
                scope: 'partner',
                modules: {
                    'registrations': true,
                    'claims': true
                },
                actions: {
                    'registrations.read': true,
                    'registrations.write': true,
                    'claims.read': true,
                    'claims.write': true
                }
            },
            cus_dealer: {
                scope: 'partner',
                modules: {
                    'registrations': true,
                    'claims': true
                },
                actions: {
                    'registrations.read': true,
                    'registrations.write': true,
                    'claims.read': true,
                    'claims.write': true
                }
            },
            cus_dealer_no_service: {
                scope: 'partner',
                modules: {
                    'registrations': true
                },
                actions: {
                    'registrations.read': true,
                    'registrations.write': true
                }
            },
            cus_workshop: {
                scope: 'partner',
                modules: {
                    'claims': true
                },
                actions: {
                    'claims.read': true,
                    'claims.write': true
                }
            }
        };
    }
}
