import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';

import { StateService } from '../../../../services/state.service';
import { ClaimsService } from '../../../../services/claims.service';
import { MachinesService } from '../../../../services/machines.service';

import * as _ from 'lodash';

@Component({
    selector: 'app-custom-page',
    templateUrl: './custom-page.component.html',
    styleUrls: ['./custom-page.component.scss']
})
export class ReportingCustomPageComponent implements OnInit {

    lodash = _;

    id: string;
    state: any;

    machines: any;
    machinesHits: number;
    machinesFiltered: any;
    machinesAgg: any;
    machinesTypes: any;
    claims: any;
    claimsHits: number;
    claimsFiltered: any;
    claimsAgg: any;

    filter = {
        dateType: 'manufactureDate',
        startDate: '2014-01-01T00:00:00Z',
        endDate: '2019-01-01T00:00:00Z',
        productId: '',
        productGroup: ''
    };

    mappings = {
        machines: {
            manufactureDate: 'manufactureDate',
            deliveryDate: 'deliveryDate',
            productId: 'productId',
            productGroup: 'productGroup',
        },
        claims: {
            manufactureDate: 'data.products.manufactureDate',
            deliveryDate: 'data.products.deliveryDate',
            productId: 'data.products.productId',
            productGroup: 'data.products.productGroup',
        }
    };

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public stateService: StateService,
        private claimsService: ClaimsService,
        private machinesService: MachinesService,
        private snackBar: MatSnackBar
    ) {
        this.stateService.state.hasBack = false;

        this.state = {
            busy: false
        };
    }

    ngOnInit() {
        setTimeout(() => {
            this.id = this.activatedRoute.snapshot.params['id'];
            this.stateService.state.file = this.id;

            this.getData();
        });
    }

    getData() {
        // Load summarised data
        // Machines
        this.getMachines();
        // Claims
        this.getClaims();

        this.getMachineTypes();
    }

    getMachines() {
        const options: any  = {
            fields: ['productId', 'productGroup', 'manufactureDate'],
            filters: [
                // { type: 'range', key: 'manufactureDate', value: { gt: this.filter.startDate, lt: this.filter.endDate } }
                { type: 'range', key: this.mappings.machines[this.filter.dateType], value: { gt: this.filter.startDate, lt: this.filter.endDate } }
            ],
            aggregations: [
                { type: 'terms', field: 'productId', name: 'agg_terms_productId', options: { size: 100 } }
            ]
        };
        if (this.filter.productId !== '') {
            options.filters.push({ type: 'match', key: this.mappings.machines.productId, value: this.filter.productId });
        }
        if (this.filter.productGroup !== '') {
            options.filters.push({ type: 'match', key: this.mappings.machines.productGroup, value: this.filter.productGroup });
        }
        this.machinesService.getMachinesReport(options).subscribe((esResults: any) => {
            console.log('esResults', esResults);
            this.machinesHits = _.get(esResults, 'hits.total', 0);
            // Just for debuggin
            this.machines = _.map(esResults.hits.hits, (hit) => {
                return hit._source;
            });
            this.machinesFiltered = this.machines;
            this.machinesAgg = _.get(esResults, 'aggregations.agg_terms_productId.buckets');
            this.machinesAgg.push({ key: 'others', doc_count: _.get(esResults, 'aggregations.agg_terms_productId.sum_other_doc_count') });
        }, (error) => {
            this.showError(error);
        });

    }

    getMachineTypes() {
        this.machinesService.getMachineTypes().subscribe((esResults: any) => {
            console.log('esResults', esResults);

            const buckets = _.get(esResults, 'aggregations.agg_terms_productId.buckets');
            this.machinesTypes = {};
            buckets.forEach(b => {
                this.machinesTypes[_.get(b, 'productName.hits.hits[0]._source.productId')] = {
                    productName: _.get(b, 'productName.hits.hits[0]._source.productName'),
                    productGroup: _.get(b, 'productName.hits.hits[0]._source.productGroup')
                };
            });
            /*
            this.machinesTypes = _.map(buckets, function(b) {
                return {
                    productId: _.get(b, 'productName.hits.hits[0]._source.productId'),
                    productName: _.get(b, 'productName.hits.hits[0]._source.productName'),
                };
            });
            */

            // console.log('machineTypes', this.machinesTypes);

            /*
            this.machinesHits = _.get(esResults, 'hits.total', 0);
            // Just for debuggin
            this.machines = _.map(esResults.hits.hits, (hit) => {
                return hit._source;
            });
            this.machinesFiltered = this.machines;
            this.machinesAgg = _.get(esResults, 'aggregations.agg_terms_productId.buckets');
            this.machinesAgg.push({ key: 'others', doc_count: _.get(esResults, 'aggregations.agg_terms_productId.sum_other_doc_count') });
            */
        }, (error) => {
            this.showError(error);
        });
    }

    getClaims() {
        const options: any = {
            // fields: ['productId', 'manufactureDate'],
            size: 0,
            filters: [
                // { type: 'range', key: 'data.products.manufactureDate', value: { gt: this.filter.startDate, lt: this.filter.endDate } }
                { type: 'range', key: this.mappings.claims[this.filter.dateType], value: { gt: this.filter.startDate, lt: this.filter.endDate } }
            ],
            aggregations: [
                { type: 'terms', field: 'data.products.productId', name: 'agg_terms_products_productId', options: { size: 100 } }
            ]
        };
        if (this.filter.productId !== '') {
            options.filters.push({ type: 'match', key: this.mappings.claims.productId, value: this.filter.productId });
        }
        if (this.filter.productGroup !== '') {
            options.filters.push({ type: 'match', key: this.mappings.claims.productGroup, value: this.filter.productGroup });
        }
        /*
        this.claimsService.getClaimsReport(options).subscribe((esResults: any) => {
            console.log('esResults', esResults);
            this.claimsHits = _.get(esResults, 'hits.total', 0);
            // Just for debuggin
            this.claims = _.map(esResults.hits.hits, (hit) => {
                return hit._source;
            });
            this.claimsFiltered = this.claims;
            this.claimsAgg = _.get(esResults, 'aggregations.agg_terms_products_productId.buckets');
            this.claimsAgg.push({ key: 'Others', doc_count: _.get(esResults, 'aggregations.agg_terms_products_productId.sum_other_doc_count') });
        }, (error) => {
            console.log('error', error);
            this.showError(error);
        });
        */
    }

    showError(error) {
        const message = _.get(error, 'message');
        const type = _.get(error, 'error.error.caused_by.caused_by.type') || _.get(error, 'error.error.caused_by.type');
        const reason = _.get(error, 'error.error.caused_by.caused_by.reason') || _.get(error, 'error.error.caused_by.reason');

        this.snackBar.open(message + '\n\n' + type + '\n' + reason, 'OK', {
            duration: 5000,
            panelClass: ['war-snackbar', 'war-snackbar-error-message']
        });
    }
}
