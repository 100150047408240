
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Injectable, OnInit } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { BehaviorSubject, Observable, of as observableOf } from 'rxjs';
import { MatDialog, MatDialogConfig, MatSnackBar } from '@angular/material';
import { CostDialogComponent } from '../cost-dialog/cost-dialog.component';

import { AuthService } from 'src/app/services/auth.service';
import { CustomersService, ICustomerInfo } from 'src/app/services/customers.service';
import { PricesService } from 'src/app/services/prices.service';

import { CurrencyCodes } from 'src/app/models/currency';
import { Permissions } from 'src/app/models/user';

import { DiscardConfirmDialogComponent } from 'src/app/shared/dialogs/components/discard-dialog/discard-confirm-dialog.component';

import * as _ from 'lodash';

// Example structure
// Kemppi OY defines Company level internal costs for subsidiary and set maximum cost that can be set to partners
// Subsidiaries defines partner costs for their own company
// {
//     companies: {
//         '100': {
//             name: 'KEMPPI OY',
//             maxCosts: {
//                 'EUR': 100,
//                 'SEK': 200
//             },
//             partners: {
//                 '109': { name: 'KEMPPI AUSTRALIA PTY LIMITED', cost: 100, currency: 'EUR', subsidiary: true },
//                 '130': { name: 'KEMPPI SVERIGE AB', cost: 1000, currency: 'SEK', subsidiary: true },
//                 'FI003035': { name: 'TANKKI OY', cost: 100, currency: 'EUR' }
//             }
//         },
//         '109': {
//             name: 'KEMPPI AUSTRALIA PTY LIMITED',
//             maxCosts: {},
//             partners: {}
//         },
//         '130': {
//             name: 'KEMPPI SVERIGE AB',
//             maxCosts: {},
//             partners: {
//                 'SE1001': { cost: 300, currency: 'SEK' },
//                 'SE1002': { cost: 400, currency: 'SEK' }
//             }
//         },
//         '140': {
//             name: 'KEMPPI NORGE AS',
//             maxCosts: {},
//             partners: {}
//         }
//     }
// };

@Component({
    selector: 'app-labour-costs-page',
    templateUrl: './labour-costs-page.component.html',
    styleUrls: ['./labour-costs-page.component.scss']
})
export class LabourCostsPageComponent implements OnInit {

    lodash = _;

    /**
     * State of page
     */
    state: any;

    data: any;

    dataTree: Array<any>;

    companies: Array<string>;

    partners: Array<string>;

    /**
     * User permissions, same as authService.userProfile.permissions
     */
    permissions: Permissions = {
        scope: '',
        modules: {},
        actions: {}
    };

    userCompany: string;

    /**
     * Available currecy codes suchs as 'EUR', 'USD'...
     */
    currencyCodes: Array<string>;

    /**
     * Permission to edit the claim fields, check from auth service 'claims.write' action and user role
     * Disables fields and buttons in template
     */
    canEdit = false;

    /**
     * Permission to save the claim, check from auth service 'claims.write' action and claim current state
     * Disables approval and save buttons on toolbar
     */
    canSave = false;

    constructor(
        private authService: AuthService,
        private customersService: CustomersService,
        private pricesService: PricesService,
        private snackBar: MatSnackBar,
        private dialog: MatDialog
    ) {
        this.state = {};
        this.currencyCodes = CurrencyCodes;
    }

    ngOnInit(): void {
        this.permissions = this.authService.userProfile.permissions;
        this.userCompany = this.authService.userProfile.company;

        // Set canEdit and canSave boolean flags.
        // PRICES WRITE permission required
        if (this.authService.isAllowedTo('prices.write')) {
            this.canEdit = true;
            this.canSave = true;
        }

        // this.parseData(this.userCompany);
        this.data = {
            id: 'labour-costs',
            companies: {}
        };

        this.loadData();
    }

    /**
     * Detect changes, if current claim data contains unsaved changes open discard confirmation dialog
     */
    canDeactivate(): Promise<boolean> | boolean {
        return new Promise( (resolve, reject) => {
            // If current claim data is remained unchanged return true and allow routing to another url
            if (_.isEqual(this.data, this.state.originalData)) {
                resolve(true);
                return;
            }
            // Otherwise ask the user confirmation with the dialog. Return its promise which resolves to true or false when the user decides
            const dialogRef = this.dialog.open(DiscardConfirmDialogComponent);
            dialogRef.afterClosed().subscribe(results => {
                resolve(results);
            });
        });
    }

    /**
     * Store original claim data.
     * Used for canDeactivate() to check any changes. (Shows discard changes dialog)
     */
    storeOriginalData() {
        this.state.originalData = _.cloneDeep(this.data);
    }

    loadData() {
        console.log('loadData');

        this.pricesService.getLabourCosts().subscribe((response) => {
            if (response) {
                this.data = response;
                this.parseData(this.userCompany);
                this.storeOriginalData();
            }
        }, (error) => {
            this.showError(error);
        });
    }

    save() {
        console.log('save', this.data);

        this.pricesService.saveLabourCosts(this.userCompany, this.data).subscribe((response) => {
            this.storeOriginalData();
            this.notify('Labour costs successfully saved');
        }, (error) => {
            this.showError(error);
        });
    }

    parseData(expandedCompany = null) {
        this.dataTree = [];
        for (const companyId in this.data.companies) {
            if (this.data.companies.hasOwnProperty(companyId)) {
                // Partners
                let partners = [];
                for (const partnerId in this.data.companies[companyId].partners) {
                    if (this.data.companies[companyId].partners.hasOwnProperty(partnerId)) {
                        partners.push({ id: partnerId, selected: false });
                    }
                }
                partners = _.orderBy(partners, 'id', 'asc');

                // Max costs
                let maxCosts = [];
                for (const currencyCode in this.data.companies[companyId].maxCosts) {
                    if (this.data.companies[companyId].maxCosts.hasOwnProperty(currencyCode)) {
                        maxCosts.push({ id: currencyCode });
                    }
                }
                maxCosts = _.orderBy(maxCosts, 'id', 'asc');

                this.dataTree.push({
                    id: companyId,
                    partners: partners,
                    maxCosts: maxCosts,
                    selected: false,
                    expanded: companyId === expandedCompany,
                    expandedSubsidiaries: true,
                    expandedDistributors: true,
                    expandedDealers: true,
                });
            }
        }
    }

    openCost(company, partnerId = null) {
        if (partnerId) {
            // Partner
            this.openCostDialog(
                'partner',
                company,
                partnerId === 'new' ? null : partnerId,
                this.data.companies[company].partners[partnerId] ? this.data.companies[company].partners[partnerId].name : null,
                null,
                this.data.companies[company].partners[partnerId] ? this.data.companies[company].partners[partnerId].cost : null,
                this.data.companies[company].partners[partnerId] ? this.data.companies[company].partners[partnerId].currency : null,
                this.data.companies[company].maxCosts
            );
        } else {
            // Company
            this.openCostDialog(
                'company',
                null
            );
        }
    }

    openCostForSelected() {
        let company;
        const partners = [];
        const cost = 0;
        const currencies = [];
        const maxCosts = [];
        for (const companyObj of this.dataTree) {
            // if (companyObj.selected) {
            //     company = companyObj.id;
            //     partners.push({
            //         company: company
            //     });
            // }
            for (const partner of companyObj.partners) {
                if (partner.selected) {
                    company = companyObj.id;
                    partners.push({
                        company: company,
                        partnerId: partner.id
                    });
                    currencies.push(this.data.companies[company].partners[partner.id].currency);
                    maxCosts.push(this.data.companies[company].maxCosts);
                }
            }
        }
        const currency = _.uniq(currencies);
        const maxCost = _.uniq(maxCosts);
        if (partners.length === 0) {
            this.showError({ message: 'Please select partners first' });
        } else if (currency.length === 1 && maxCost.length === 1) {
            this.openCostDialog('multiple', company, null, null, partners, cost, currency[0], maxCost[0]);
        } else {
            this.showError({ message: 'Cannot set costs. Selections have multiple currencies' });
        }
    }

    openMaxCost(company, currency) {
        currency = currency === 'new' ? null : currency;
        const cost = currency === 'new' ? 0 : this.data.companies[company].maxCosts[currency];
        this.openCostDialog('maxCost', company, null, null, null, cost, currency);
    }

    openCostDialog(type, company, partnerId = null, name = null, partners = null, cost = 0, currency = null, maxCosts = null) {
        const data = {
            type: type,
            company: company,
            partnerId: partnerId,
            maxCosts: maxCosts,
            name: name,
            partners: partners,
            cost: cost,
            currency: currency,
            // subsidiary: false
            groupCode: null,
            groupDesc: null
        };
        const dialogConfig: MatDialogConfig = {
            data: data
        };

        console.log('openCostDialog', data);
        const dialogRef = this.dialog.open(CostDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            console.log('Dialog close: ', result);
            if (result === 'ok') {
                console.log('SET DATA', data);
                if (type === 'multiple' && data.partners) {
                    // Loop partners
                    for (const partner of data.partners) {
                        // this.setCost(partner.company, partner.partnerId, null, data.cost, data.currency, data.subsidiary);
                        this.setCost(partner.company, partner.partnerId, data.cost, data.currency, null);
                    }
                } else if (type === 'partner' && data.company && data.partnerId) {
                    // this.setCost(data.company, data.partnerId, data.name, data.cost, data.currency, data.subsidiary);
                    if (data.name && data.groupCode && data.groupDesc) {
                        this.setCost(data.company, data.partnerId, data.cost, data.currency, { name: data.name, groupCode: data.groupCode, groupDesc: data.groupDesc });
                    } else {
                        this.setCost(data.company, data.partnerId, data.cost, data.currency);
                    }
                } else if (type === 'company' && data.partnerId) {
                    this.addCompany(data.company, data.name);
                } else if (type === 'maxCost' && data.company) {
                    this.setMaxCost(data.company, data.cost, data.currency);
                }

                this.parseData(company);
            }
        });
    }

    setCost(company, partnerId, cost, currency, details = null) {
        partnerId = partnerId.toUpperCase();
        // Single partner
        if (!this.data.companies[company].partners[partnerId]) {
            this.data.companies[company].partners[partnerId] = {};
        }
        this.data.companies[company].partners[partnerId].cost = cost;
        this.data.companies[company].partners[partnerId].currency = currency;
        if (details) {
            this.data.companies[company].partners[partnerId].name = details.name;
            this.data.companies[company].partners[partnerId].groupCode = details.groupCode;
            this.data.companies[company].partners[partnerId].groupDesc = details.groupDesc;
        }
        this.parseData(company);
    }

    setMaxCost(company, cost, currency) {
        if (!this.data.companies[company].maxCosts) {
            this.data.companies[company].maxCosts = {};
        }
        this.data.companies[company].maxCosts[currency] = cost;
        this.parseData(company);
    }

    addCompany(company, name) {
        if (this.data.companies[company]) {
            this.data.companies[company].name = name;
        } else {
            this.data.companies[company] = {
                name: name,
                maxCosts: {},
                partners: {}
            };
        }
    }

    remove(company, partnerId) {
        delete this.data.companies[company].partners[partnerId];
        this.parseData(company);
    }

    removeMaxCost(company, currency) {
        delete this.data.companies[company].maxCosts[currency];
        this.parseData(company);
    }

    togglePartners(companyId, type) {
        console.log('togglePartners', type);
        // Get index by company id
        const companyIndex = _.findIndex(this.dataTree, { id: companyId });

        if (type === 'subsidiaries') {
            this.dataTree[companyIndex].expandedSubsidiaries = !this.dataTree[companyIndex].expandedSubsidiaries;
        } else if (type === 'distributors') {
            this.dataTree[companyIndex].expandedDistributors = !this.dataTree[companyIndex].expandedDistributors;
        } else if (type === 'dealers') {
            this.dataTree[companyIndex].expandedDealers = !this.dataTree[companyIndex].expandedDealers;
        }
    }

    // -----------------
    // Notifications
    // -----------------

    notify(message) {
        this.snackBar.open(message, 'OK', {
            duration: 2000,
            panelClass: ['war-snackbar']
        });
    }

    showError(error) {
        const message = _.get(error, 'message');
        const type = _.get(error, 'error.error.caused_by.caused_by.type') || _.get(error, 'error.error.caused_by.type') || '';
        const reason = _.get(error, 'error.error.caused_by.caused_by.reason') || _.get(error, 'error.error.caused_by.reason') || '';

        this.snackBar.open(message + '\n\n' + type + '\n' + reason, 'OK', {
            duration: 5000,
            panelClass: ['war-snackbar', 'war-snackbar-error-message']
        });
    }
}
