import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as _ from 'lodash';

import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { HistogramQueryParams, TermsQueryParams } from '../models/report';

@Injectable()
export class ReportsService {

    BACKENDURL: string;
    APIROOT: string;

    constructor(
        private appConfigService: AppConfigService,
        private authService: AuthService,
        private http: HttpClient
    ) {
        this.BACKENDURL = appConfigService.config.warrantyBackendUrl;
    }

    get token() {
        return this.authService.accessToken;
    }

    getClaimsHistogram(params: HistogramQueryParams) {
        console.log('getHistogram', params);
        return this.http.post(`${this.BACKENDURL}/reports/claims/histogram`, JSON.stringify(params), { headers: this._getHeaders() });
    }

    getClaimsTerms(params: TermsQueryParams) {
        console.log('getTerms', params);
        return this.http.post(`${this.BACKENDURL}/reports/claims/terms`, JSON.stringify(params), { headers: this._getHeaders() });
    }

    getParts() {
        return this.http.get(`${this.BACKENDURL}/reports/parts`, { headers: this._getHeaders() });
    }

    _getHeaders() {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
        });
    }
}
