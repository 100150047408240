import { Component } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { StateService } from './services/state.service';
import { AuthService } from './services/auth.service';
import { AppConfigService } from './services/app-config.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    appInfo = {
        icon: 's',
        title: 'eWarranty'
    };

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private translate: TranslateService,
        public stateService: StateService,
        private authService: AuthService,
        private appConfigService: AppConfigService
    ) {
        // Add DEV / TEST to application title
        const frontendurl = this.appConfigService.config.frontendUrl;
        const environment = this.appConfigService.config.environment;
        if (environment) {
            if (environment === 'dev') {
                this.appInfo.title = 'eWarranty (DEV)';
            } else if (environment === 'stage') {
                this.appInfo.title = 'eWarranty (STAGE)';
            }
        }

        // Clear loading state after navigation end
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.stateService.state.loading = false;
            }
        });

        this.route.queryParams.subscribe(params => {
            this.stateService.state.isPrinting = params['print'] === 'true' ? true : false;
            console.log('queryParams', params);
        });

        // this language will be used as a fallback when a translation isn't found in the current language
        translate.setDefaultLang('en');
    }

    back() {
        if (this.router.url.split('/')[1] === 'claims') {
            this.router.navigate(['claims']);
        } else if (this.router.url.split('/')[1] === 'registrations') {
            this.router.navigate(['registrations']);
        }
    }
}
