import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';

import { StateService } from '../../../../services/state.service';
import { ReportsService } from 'src/app/services/reports.service';

import { TermsQueryParams, HistogramQueryParams } from 'src/app/models/report';
import { FaultyDescriptionCategories, FaultyDescriptionTypes, FaultyDescriptionSections, FaultyDescriptionReasons } from 'src/app/models/faulty-description';
import { CompanyCodes } from 'src/app/models/company';

import { Chart } from 'angular-highcharts';

import * as _ from 'lodash';

@Component({
    selector: 'app-reports-claims-page',
    templateUrl: './reports-claims-page.component.html',
    styleUrls: ['./reports-claims-page.component.scss']
})
export class ReportsClaimsPageComponent implements OnInit {

    _ = _;

    id: string;
    state: any;

    parts: Array<any>;

    results;
    resultField;
    resultShowCount;
    resultShowName;
    resultsHistogram;

    report = {
        filters: [],
        field: 'productId',
        aggs: ['count', 'name'],
        size: 100,
        // nested
        sumField: 'count',
        histogramField: 'repairDate',
        histogramInterval: 'month'
    };

    filters = [
        { type: 'match', name: 'DATA - Company id', field: 'company', values: CompanyCodes },
        { type: 'match', name: 'DATA - Partner id', field: 'partnerId' },
        { type: 'range', name: 'REPAIR INFO - Repair date', field: 'data.repairDate' },
        { type: 'range', name: 'VERSION - Creation date', field: 'version.created.timestamp' },
        { type: 'range', name: 'APPROVALS - Acception date', field: 'approvals.accepted.timestamp' },
        { type: 'range', name: 'PRODUCT - Manufacture date', field: 'data.products.manufactureDate' },
        { type: 'range', name: 'PRODUCT - Delivery date', field: 'data.products.deliveryDate' },
        { type: 'match', name: 'PRODUCT - Product id', field: 'data.products.productId' },
        { type: 'match', name: 'PRODUCT - Product group', field: 'data.products.productGroup' },
        { type: 'match', name: 'STATUS - Current state', field: 'state.currentState', values: ['DRAFT', 'OPEN', 'IN PROGRESS', 'ACCEPTED', 'REJECTED', 'RETURNED'] },
        { type: 'match', name: 'STATUS - Internal state', field: 'state.internalState', values: ['', 'WAITING', 'ACCEPTED', 'REJECTED', 'RETURNED'] },
        { type: 'match', name: 'STATUS - Logistics state', field: 'state.logisticsState', values: ['', 'QUEUED', 'HANDLED'] },
        { type: 'match', name: 'STATUS - Invoice state', field: 'state.invoiceState', values: ['', 'INVOICED', 'REFUNDED'] },
        { type: 'match', name: 'STATUS - Internal invoice state', field: 'state.internalInvoiceState', values: ['', 'INVOICED', 'REFUNDED'] },
        { type: 'match', name: 'INVOICE - Reference number', field: 'data.invoice.reference' },
        { type: 'match', name: 'INVOICE - Internal reference number', field: 'data.invoice.internalReference' },
        { type: 'match', name: 'FAULTY DESCRIPTION - Category', field: 'data.faultyDescription.category', values: _.map(FaultyDescriptionCategories, 'key') },
        { type: 'match', name: 'FAULTY DESCRIPTION - Types', field: 'data.faultyDescription.type', values: _.map(FaultyDescriptionTypes, 'key') },
        { type: 'match', name: 'FAULTY DESCRIPTION - Section', field: 'data.faultyDescription.section', values: _.map(FaultyDescriptionSections, 'key') },
        { type: 'match', name: 'FAULTY DESCRIPTION - Reason', field: 'data.faultyDescription.reason', values: _.map(FaultyDescriptionReasons, 'key') }
    ];

    fields = {
        repairDate:     { name: 'Repair date', field: 'data.repairDate' },
        creationDate:   { name: 'Creation date', field: 'version.created.timestamp' },
        acceptionDate:  { name: 'Acception date', field: 'approvals.accepted.timestamp' },
        manufactureDate: { name: 'Manufacture date', field: 'data.products.manufactureDate' },
        deliveryDate:   { name: 'Delivery date', field: 'data.products.deliveryDate' },
        id:             { name: 'Claim id', field: 'id' },
        company:        { name: 'Company', field: 'company' },
        partner:        { name: 'Partner id', field: 'partnerId', topHits: { source: 'partnerName', result: 'partnerName'} },
        productId:      { name: 'Product id', field: 'data.products.productId', topHits: { source: 'data.products.productName', result: 'data.products[0].productName' } },
        productGroup:   { name: 'Product group', field: 'data.products.productGroup' },
        serialNumber:   { name: 'Serial number', field: 'data.products.serialNumber' },
        part:           { name: 'Part number', field: 'data.parts.partNumber', nested: 'data.parts', topHits: { source: 'data.parts.partName', result: 'partName' } }
    };

    aggs = {
        count: null, // (count / doc_count)
        name: null, // (topHits name)
        partQuantity:   { type: 'sum', name: 'partQuantity', field: 'data.parts.quantity' },
        partCostsOKH:      { type: 'sum', name: 'partCostsOKH', field: 'reporting.parts.costsEUR' },
        partCosts:      { type: 'sum', name: 'partCosts', field: 'data.costs.partCostsEUR' },
        repairCosts:    { type: 'sum', name: 'repairCosts', field: 'data.costs.repairCostsEUR' },
        otherCosts:     { type: 'sum', name: 'otherCosts', field: 'data.costs.otherCostsEUR' },
        totalCosts:     { type: 'sum', name: 'totalCosts', field: 'data.costs.totalCostsEUR' }
    };

    selectFilter: any;
    selectFilterValue: any;
    selectFilterStart: any;
    selectFilterEnd: any;

    public chart: any;
    public totalSum: number;
    public unit: string;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public stateService: StateService,
        private reportsService: ReportsService,
        // private machinesService: MachinesService,
        private snackBar: MatSnackBar
    ) {
        this.stateService.state.hasBack = false;

        this.state = {
            busy: false
        };

        this.parts = [];
        this.chart = null;
    }

    ngOnInit() {
        setTimeout(() => {
            this.id = this.activatedRoute.snapshot.params['id'];
            this.stateService.state.file = this.id;

            // this.getData();
        });
    }

    getClaimsTerms() {
        console.log('report', this.report);
        const params: TermsQueryParams = {
            filters: this.report.filters,
            field: this.fields[this.report.field].field,
            size: this.report.size,
            aggs: [],
            nested: this.fields[this.report.field].nested || null,
            topHits: this.fields[this.report.field].topHits || null
        };
        // Add aggs
        for (const agg of this.report.aggs) {
            console.log('agg', agg);
            if (this.aggs[agg]) {
                params.aggs.push(this.aggs[agg]);
            }
        }

        this.reportsService.getClaimsTerms(params).subscribe((response: any) => {
            this.results = response;
            if (this.fields[this.report.field].topHits) {
                this.results.forEach(result => {
                    result.name = _.get(result, 'tophits.hits.hits[0]._source[' + this.fields[this.report.field].topHits.result + ']');
                });
            }
            this.resultField = this.report.field;
            this.resultShowCount = _.includes(this.report.aggs, 'count');
            this.resultShowName = _.includes(this.report.aggs, 'name');
        }, (error) => {
            this.showError(error);
        });
    }

    getClaimsHistogram() {
        console.log('report', this.report);
        const params: HistogramQueryParams = {
            filters: this.report.filters,
            sumField: this.aggs[this.report.sumField] ? this.aggs[this.report.sumField].field : null,
            histogramField: this.fields[this.report.histogramField].field,
            histogramInterval: <any>this.report.histogramInterval
        };

        this.reportsService.getClaimsHistogram(params).subscribe((response: any) => {
            this.resultsHistogram = response;
            console.log('resultsHistogram', this.resultsHistogram);
            this.totalSum = this.resultsHistogram.reduce(function (a, b) {
                return a + (params.sumField ? _.get(b, 'value.value') : _.get(b, 'doc_count'));
            }, 0);
            this.unit = params.sumField ? '€' : '';
            this.createChart();
        }, (error) => {
            this.showError(error);
        });
    }


    getParts() {
        this.reportsService.getParts().subscribe((response: any) => {
            this.parts = response;
        }, (error) => {
            this.showError(error);
        });
    }

    addReportFilter() {
        if (this.selectFilter && this.selectFilter.type === 'match') {
            if (Array.isArray(this.selectFilterValue)) {
                // Add SHOULD filter
                this.report.filters.push({ name: this.selectFilter.name, type: 'should', field: this.selectFilter.field, shouldValues: this.selectFilterValue });
            } else if (this.selectFilterValue.indexOf(',') > -1) {
                // Array of values, convert to should filter
                let shouldValues = this.selectFilterValue.split(',');
                // Trim all values
                shouldValues = shouldValues.map(s => s.trim());
                // Add SHOULD filter
                this.report.filters.push({ name: this.selectFilter.name, type: 'should', field: this.selectFilter.field, shouldValues: shouldValues });
            } else {
                // Add MATCH filter
                this.report.filters.push({ name: this.selectFilter.name, type: 'match', field: this.selectFilter.field, matchValue: this.selectFilterValue });
            }
            this.selectFilter = null;
            this.selectFilterValue = null;
        } else if (this.selectFilter && this.selectFilter.type === 'range') {
            // Add RANGE filter
            this.report.filters.push({ name: this.selectFilter.name, type: 'range', field: this.selectFilter.field, rangeStart: this.selectFilterStart, rangeEnd: this.selectFilterEnd });
            this.selectFilter = null;
            this.selectFilterStart = null;
            this.selectFilterEnd = null;
        }
    }

    editReportFilter(index) {
        this.selectFilter = null;
        this.selectFilterValue = null;
        this.selectFilterStart = null;
        this.selectFilterEnd = null;
        const filter = this.report.filters[index];
        this.selectFilter = _.find(this.filters, { field: filter.field });
        console.log('FILTER', this.selectFilter);
        if (filter.type === 'should') {
            this.selectFilterValue = filter.shouldValues;
        } else if (filter.type === 'match') {
            this.selectFilterValue = filter.matchValue;
        } else if (filter.type === 'range') {
            this.selectFilterStart = filter.rangeStart;
            this.selectFilterEnd = filter.rangeEnd;
        }
        this.report.filters.splice(index, 1);
    }

    removeReportFilter(index) {
        this.report.filters.splice(index, 1);
    }

    checkResultFields() {
        if (this.report.field === 'part') {
            _.pull(this.report.aggs, 'partCosts', 'repairCosts', 'otherCosts', 'totalCosts');
        } else {
            _.pull(this.report.aggs, 'part');
        }
    }

    createChart() {
        this.chart = new Chart({
            chart: {
                type: 'column'
            },
            title: {
                text: (Math.round(this.totalSum * 100) / 100) + ' ' + this.unit
            },
            colors: ['#009dde'],
            credits: {
                enabled: false
            },
            xAxis: {
                categories: this.resultsHistogram.map(h => h.key_as_string.slice(0, 10))
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                }
            },
            tooltip: {
                valueSuffix: this.unit
            },
            plotOptions: {
                column: {
                    pointPadding: 0,
                    groupPadding: 0.05,
                    borderWidth: 0
                }
            },
            series: [
                {
                    type: 'column',
                    // name: 'SUM ' + this.params.field,
                    name: 'SUM',
                    data: this.resultsHistogram.map(h => Math.round(_.get(h, 'value.value', h.doc_count) * 100) / 100)
                }
            ]
        });
    }



    // getData() {
    //     // Load summarised data
    //     // Machines
    //     this.getMachines();
    //     // Claims
    //     this.getClaims();

    //     this.getMachineTypes();
    // }

    // getMachines() {
    //     const options: any  = {
    //         fields: ['productId', 'productGroup', 'manufactureDate'],
    //         filters: [
    //             // { type: 'range', key: 'manufactureDate', value: { gt: this.filter.startDate, lt: this.filter.endDate } }
    //             { type: 'range', key: this.mappings.machines[this.filter.dateType], value: { gt: this.filter.startDate, lt: this.filter.endDate } }
    //         ],
    //         aggregations: [
    //             { type: 'terms', field: 'productId', name: 'agg_terms_productId', options: { size: 100 } }
    //         ]
    //     };
    //     if (this.filter.productId !== '') {
    //         options.filters.push({ type: 'match', key: this.mappings.machines.productId, value: this.filter.productId });
    //     }
    //     if (this.filter.productGroup !== '') {
    //         options.filters.push({ type: 'match', key: this.mappings.machines.productGroup, value: this.filter.productGroup });
    //     }
    //     this.machinesService.getMachinesReport(options).subscribe((esResults: any) => {
    //         console.log('esResults', esResults);
    //         this.machinesHits = _.get(esResults, 'hits.total', 0);
    //         // Just for debuggin
    //         this.machines = _.map(esResults.hits.hits, (hit) => {
    //             return hit._source;
    //         });
    //         this.machinesFiltered = this.machines;
    //         this.machinesAgg = _.get(esResults, 'aggregations.agg_terms_productId.buckets');
    //         this.machinesAgg.push({ key: 'others', doc_count: _.get(esResults, 'aggregations.agg_terms_productId.sum_other_doc_count') });
    //     }, (error) => {
    //         this.showError(error);
    //     });

    // }

    // getMachineTypes() {
    //     this.machinesService.getMachineTypes().subscribe((esResults: any) => {
    //         console.log('esResults', esResults);

    //         const buckets = _.get(esResults, 'aggregations.agg_terms_productId.buckets');
    //         this.machinesTypes = {};
    //         buckets.forEach(b => {
    //             this.machinesTypes[_.get(b, 'productName.hits.hits[0]._source.productId')] = {
    //                 productName: _.get(b, 'productName.hits.hits[0]._source.productName'),
    //                 productGroup: _.get(b, 'productName.hits.hits[0]._source.productGroup')
    //             };
    //         });
    //     }, (error) => {
    //         this.showError(error);
    //     });
    // }

    // getClaims() {
    //     const options: any = {
    //         // fields: ['productId', 'manufactureDate'],
    //         size: 0,
    //         filters: [
    //             // { type: 'range', key: 'data.products.manufactureDate', value: { gt: this.filter.startDate, lt: this.filter.endDate } }
    //             { type: 'range', key: this.mappings.claims[this.filter.dateType], value: { gt: this.filter.startDate, lt: this.filter.endDate } }
    //         ],
    //         aggregations: [
    //             { type: 'terms', field: 'data.products.productId', name: 'agg_terms_products_productId', options: { size: 100 } }
    //         ]
    //     };
    //     if (this.filter.productId !== '') {
    //         options.filters.push({ type: 'match', key: this.mappings.claims.productId, value: this.filter.productId });
    //     }
    //     if (this.filter.productGroup !== '') {
    //         options.filters.push({ type: 'match', key: this.mappings.claims.productGroup, value: this.filter.productGroup });
    //     }
    // }

    showError(error) {
        this.snackBar.open(error, 'OK', {
            duration: 5000,
            panelClass: ['war-snackbar', 'war-snackbar-error-message']
        });
    }
}
