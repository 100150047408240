<div class="main">
    <div *ngIf="registrationInfo" class="page-container" fxLayout="column" fxLayoutAlign="start start">
        <div class="full" fxLayout="row">
            <h1 fxFlex>{{ 'registrations.partner_product_registration' | translate }}</h1>
        </div>
        <!-- NEW -->

        <!-- PARTNER INFO-->
        <div class="full" fxLayout="row">
            <fieldset fxFlex="true">
                <legend>{{ 'common.partner' | translate }}</legend>
                <div class="full" fxLayout="column">
                    <div fxLayout="row" fxLayout.lt-md="column">
                        <mat-form-field fxFlex="50%" readonly="true">
                            <mat-label>{{ 'common.name' | translate }}</mat-label>
                            <input matInput [(ngModel)]="registrationInfo.data.partner.name" readonly="true">
                            <mat-hint *ngIf="authUserScope !== 'partner'" class="limited-access">{{registrationInfo.data.partner.groupDesc}}</mat-hint>
                        </mat-form-field>
                        <div fxLayout="row" fxFlex="50%">
                            <mat-form-field fxFlex="50%" [attr.readonly]="id !== 'new' || authUserScope === 'partner'">
                                <mat-label>{{ 'common.partner_id' | translate }}</mat-label>
                                <input matInput [(ngModel)]="registrationInfo.partnerId" [readonly]="id !== 'new' || authUserScope === 'partner'" (change)="getCustomerInfo()">
                            </mat-form-field>
                            <mat-form-field fxFlex="50%" [attr.readonly]="id !== 'new' || authUserScope !== 'global'">
                                <mat-label>{{ 'common.company' | translate }}</mat-label>
                                <input matInput [(ngModel)]="registrationInfo.company" [readonly]="id !== 'new' || authUserScope !== 'global'" (change)="getCustomerInfo()">
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column">
                        <mat-form-field fxFlex="25%" readonly="true">
                            <mat-label>{{ 'registrations.street_address' | translate }}</mat-label>
                            <input matInput [(ngModel)]="registrationInfo.data.partner.streetAddress" readonly="true">
                        </mat-form-field>
                        <mat-form-field fxFlex="25%" readonly="true">
                            <mat-label>{{ 'registrations.postal_code' | translate }}</mat-label>
                            <input matInput [(ngModel)]="registrationInfo.data.partner.postalCode" readonly="true">
                        </mat-form-field>
                        <mat-form-field fxFlex="25%" readonly="true">
                            <mat-label>{{ 'registrations.city' | translate }}</mat-label>
                            <input matInput [(ngModel)]="registrationInfo.data.partner.city" readonly="true">
                        </mat-form-field>
                        <mat-form-field fxFlex="25%" readonly="true">
                            <mat-label>{{ 'registrations.country' | translate }}</mat-label>
                            <input matInput [(ngModel)]="registrationInfo.data.partner.country" readonly="true">
                        </mat-form-field>
                    </div>
                </div>
            </fieldset>
        </div>

        <!-- CUSTOMER AND PARTNER INFO -->
        <div class="full" fxLayout="row">
            <fieldset fxFlex="true">
                <legend>{{ 'common.end_customer' | translate }}</legend>
                <div class="full" fxLayout="column">
                    <div *ngIf="authUserScope === 'company' || authUserScope === 'global'" fxLayout="row wrap">
                        <div fxLayout="row wrap">
                            <mat-checkbox [(ngModel)]="registrationInfo.data.directCustomer" (change)="checkDirectCustomerCompany()" class="limited-access direct-customer-checkbox">{{ 'common.direct_customer' | translate }}</mat-checkbox>
                            <mat-form-field fxFlex="300px" [attr.readonly]="!registrationInfo.data.directCustomer" class="limited-access">
                                <mat-label>{{ 'common.partner_name' | translate }}</mat-label>
                                <input type="text" matInput [formControl]="customersFormControl" [matAutocomplete]="directCustomer" [(ngModel)]="searchPartner" (keyup)="searchCustomers()" [readonly]="!registrationInfo.data.directCustomer">
                                <mat-autocomplete #directCustomer="matAutocomplete" (optionSelected)="changeCustomer($event.option)">
                                    <mat-option *ngFor="let customer of customersList" [value]="customer">
                                        <small fxFlex>{{ customer.name }}</small>
                                        <small fxFlex="10px"></small>
                                        <small fxFlex="40px"><b>{{ customer.company }}</b></small>
                                        <small fxFlex="80px"><b>{{ customer.custId }}</b></small>
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row wrap">
                            <mat-form-field fxFlex="50%" [attr.readonly]="!registrationInfo.data.directCustomer" class="limited-access">
                                <mat-label>{{ 'common.partner_id' | translate }}</mat-label>
                                <input matInput [(ngModel)]="registrationInfo.data.customer.partnerId" [readonly]="!registrationInfo.data.directCustomer" (change)="getDirectCustomerInfo()">
                            </mat-form-field>
                            <mat-form-field fxFlex="50%" [attr.readonly]="!registrationInfo.data.directCustomer || authUserScope !== 'global'" class="limited-access">
                                <mat-label>{{ 'common.company' | translate }}</mat-label>
                                <input matInput [(ngModel)]="registrationInfo.data.customer.company" [readonly]="!registrationInfo.data.directCustomer || authUserScope !== 'global'" (change)="getDirectCustomerInfo()">
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column">
                        <!--
                        <mat-form-field fxFlex="50%" [attr.readonly]="!canSave">
                            <mat-label>{{ 'common.name' | translate }}</mat-label>
                            <input matInput [(ngModel)]="registrationInfo.data.customer.name" (change)="validate()" [attr.readonly]="!canSave" required>
                        </mat-form-field>
                        -->
                        <form fxFlex="50%">
                            <mat-form-field [attr.readonly]="!canSave || registrationInfo.data.directCustomer">
                                <mat-label>{{ 'common.company' | translate }} / {{ 'common.name' | translate }}</mat-label>
                                <input type="text" matInput [formControl]="endCustomersFormControl" [matAutocomplete]="endCustomer" [(ngModel)]="registrationInfo.data.customer.name" (keyup)="searchEndCustomers()" (change)="validate()" [readonly]="!canSave || registrationInfo.data.directCustomer" required>
                                <mat-autocomplete #endCustomer="matAutocomplete" (optionSelected)="changeEndCustomer($event.option)" [displayWith]="getOptionText">
                                    <mat-option *ngFor="let endcustomer of savedEndCustomers" [value]="endcustomer">
                                        <div fxFlex>{{ endcustomer.data.name }}</div>
                                        <small fxFlex="120px">{{ endcustomer.data.city }}</small>
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </form>

                        <div fxFlex="25%"></div>
                        <mat-form-field fxFlex="25%" [attr.readonly]="!canSave || registrationInfo.data.directCustomer">
                            <mat-label>{{ 'registrations.VAT_number' | translate }}</mat-label>
                            <input matInput [(ngModel)]="registrationInfo.data.customer.vatNumber" [readonly]="!canSave || registrationInfo.data.directCustomer">
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayout.lt-md="column">
                        <mat-form-field fxFlex="25%" [attr.readonly]="!canSave || registrationInfo.data.directCustomer">
                            <mat-label>{{ 'registrations.street_address' | translate }}</mat-label>
                            <input matInput [(ngModel)]="registrationInfo.data.customer.streetAddress" [readonly]="!canSave || registrationInfo.data.directCustomer">
                        </mat-form-field>
                        <mat-form-field fxFlex="25%" [attr.readonly]="!canSave || registrationInfo.data.directCustomer">
                            <mat-label>{{ 'registrations.postal_code' | translate }}</mat-label>
                            <input matInput [(ngModel)]="registrationInfo.data.customer.postalCode" [readonly]="!canSave || registrationInfo.data.directCustomer">
                        </mat-form-field>
                        <mat-form-field fxFlex="25%" [attr.readonly]="!canSave || registrationInfo.data.directCustomer">
                            <mat-label>{{ 'registrations.city' | translate }}</mat-label>
                            <input matInput [(ngModel)]="registrationInfo.data.customer.city" [readonly]="!canSave || registrationInfo.data.directCustomer">
                        </mat-form-field>
                        <mat-form-field fxFlex="25%" [attr.readonly]="!canSave || registrationInfo.data.directCustomer">
                            <mat-label>{{ 'registrations.country' | translate }}</mat-label>
                            <input matInput [(ngModel)]="registrationInfo.data.customer.country" [readonly]="!canSave || registrationInfo.data.directCustomer">
                        </mat-form-field>
                    </div>
                    <div *ngIf="canSave" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <div fxFlex></div>
                        <div>
                            <small *ngIf="!registrationInfo.data.customer.name" class="weak" matSuffix>*</small>
                            <small *ngIf="registrationInfo.data.customer.name && !registrationInfo.data.customer.id && !registrationInfo.data.directCustomer" class="weak" matSuffix>NEW</small>
                            <small *ngIf="registrationInfo.data.customer.name && !registrationInfo.data.customer.id && registrationInfo.data.directCustomer" class="weak" matSuffix>ERP</small>
                            <small *ngIf="registrationInfo.data.customer.id" class="weak" matSuffix>{{ registrationInfo.data.customer.id.split('-').pop() }}</small>
                        </div>
                        <button mat-icon-button mat-raised-button color="accent" (click)="saveEndCustomer()" [disabled]="!registrationInfo.data.customer.name || registrationInfo.data.customer.name === '' || registrationInfo.data.directCustomer">
                            <mat-icon>save</mat-icon>
                        </button>
                        <button mat-icon-button mat-raised-button color="warn" (click)="deleteEndCustomer()" [disabled]="!registrationInfo.data.customer.id || registrationInfo.data.directCustomer">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>
            </fieldset>
        </div>


        <!-- EXISTING REGISTRATION -->
        <div *ngIf="id !== 'new'" class="full" fxLayout="row">
            <fieldset fxFlex>
                <legend>{{ 'common.product' | translate }}</legend>
                <div class="full" fxLayout="column">
                    <div class="full" fxLayout="row">
                        <mat-form-field fxFlex="120px" readonly="true">
                            <mat-label>{{ 'common.serial_number' | translate }}</mat-label>
                            <input matInput [(ngModel)]="registrationInfo.serialNumber" readonly="true">
                        </mat-form-field>
                        <mat-form-field fxFlex="120px" readonly="true">
                            <mat-label>{{ 'common.security_code' | translate }}</mat-label>
                            <input matInput [(ngModel)]="registrationInfo.secureId" readonly="true">
                        </mat-form-field>
                        <mat-form-field fxFlex="120px" readonly="true">
                            <mat-label>{{ 'common.product_number' | translate }}</mat-label>
                            <input matInput [(ngModel)]="registrationInfo.productId" readonly="true">
                        </mat-form-field>
                        <mat-form-field fxFlex readonly="true">
                            <mat-label>{{ 'common.product_name' | translate }}</mat-label>
                            <input matInput [(ngModel)]="registrationInfo.productName" readonly="true">
                        </mat-form-field>
                    </div>
                    <div class="full" fxLayout="row">
                        <war-datepicker
                            [(ngModel)]="deliveryDate"
                            label="{{ 'common.delivery_date' | translate }}"
                            [showPicker]="false"
                            [readonly]="true"></war-datepicker>
                        <war-datepicker
                            [(ngModel)]="registrationInfo.data.salesDate"
                            label="{{ 'common.sales_date' | translate }}"
                            [showPicker]="true"
                            [readonly]="!canSave"
                            (changeEvent)="validate()"></war-datepicker>
                        <war-datepicker
                            [(ngModel)]="registrationInfo.data.registrationDate"
                            label="{{ 'common.registration_date' | translate }}"
                            [showPicker]="false"
                            [readonly]="true"></war-datepicker>
                        <war-datepicker
                            [(ngModel)]="endCustomerRegistrationDate"
                            label="{{ 'common.customer_registration' | translate }}"
                            [showPicker]="false"
                            [readonly]="true"></war-datepicker>
                        <war-datepicker
                            [(ngModel)]="warrantyExpireDate"
                            label="{{ 'common.warranty_expiry_date' | translate }}"
                            [showPicker]="false"
                            [readonly]="true"></war-datepicker>
                        <mat-form-field fxFlex readonly="true">
                            <mat-label>{{ 'registrations.warranty_in_months' | translate }}</mat-label>
                            <input matInput [(ngModel)]="warrantyPeriodInMonths" readonly="true">
                        </mat-form-field>
                    </div>
                    <small class="weak hide-in-print"><i>{{ 'registrations.data_automatically_updated_info' | translate }}</i></small>
                </div>
            </fieldset>
        </div>

        <!-- NEW REGISTRATION -->
        <div *ngIf="id === 'new'" class="full" fxLayout="row">
            <fieldset fxFlex>
                <legend>{{ 'common.product_s' | translate }}</legend>
                <div class="full" fxLayout="column">

                    <div class="full" fxLayout="row" *ngFor="let registration of registrations; let i = index; first as isFirst">
                        <button fxFlex="40px" mat-icon-button class="button-hover" color="accent" (click)="deleteSerial(i)" [disabled]="!canSave"><mat-icon>delete</mat-icon></button>
                        <mat-form-field fxFlex="120px" readonly="true">
                            <mat-label *ngIf="isFirst">{{ 'common.serial_number' | translate }}</mat-label>
                            <input matInput [(ngModel)]="registration.serialNumber" readonly="true">
                        </mat-form-field>
                        <mat-form-field fxFlex="80px" readonly="true">
                            <mat-label *ngIf="isFirst">{{ 'common.security_code' | translate }}</mat-label>
                            <input matInput [(ngModel)]="registration.secureId" readonly="true">
                        </mat-form-field>
                        <mat-form-field fxFlex="120px" readonly="true">
                            <mat-label *ngIf="isFirst">{{ 'common.product_number' | translate }}</mat-label>
                            <input matInput [(ngModel)]="registration.productId" readonly="true">
                        </mat-form-field>
                        <mat-form-field fxFlex readonly="true">
                            <mat-label *ngIf="isFirst">{{ 'common.product_name' | translate }}</mat-label>
                            <input matInput [(ngModel)]="registration.productName" readonly="true">
                        </mat-form-field>
                        <war-datepicker
                            [(ngModel)]="registration.data.salesDate"
                            [label]="isFirst ? 'Sales date' : null"
                            [showPicker]="true"
                            [required]="true"
                            (changeEvent)="validate()"></war-datepicker>
                    </div>

                    <fieldset class="sub-fieldset">
                        <legend>{{ 'registrations.add_product' | translate }}</legend>

                        <div class="full" fxLayout="row">
                            <mat-form-field fxFlex="140px">
                                <input matInput placeholder="Serial number" [(ngModel)]="newSerialNumber" (keyup.enter)="addSerial(newSerialNumber, newSecureId)" [required]="!registrations || registrations.length === 0">
                                <mat-hint *ngIf="alreadyRegistered" class="warn">*{{ 'warning.already_registered' | translate }}</mat-hint>
                                <mat-hint *ngIf="invalidSerialNumber" class="warn">*{{ 'warning.invalid' | translate }}</mat-hint>
                                <mat-hint *ngIf="notShippedSerial" class="warn">*{{ 'warning.not_shipped' | translate }}</mat-hint>
                            </mat-form-field>
                            <mat-form-field fxFlex="100px">
                                <mat-label>{{ 'common.security_code' | translate }}</mat-label>
                                <input matInput [(ngModel)]="newSecureId" (keyup.enter)="addSerial(newSerialNumber, newSecureId)" [required]="!registrations || registrations.length === 0">
                                <mat-hint *ngIf="invalidSecureId" class="warn">*{{ 'warning.invalid' | translate }}</mat-hint>
                            </mat-form-field>
                            <div>
                                <button mat-raised-button color="accent" (click)="addSerial(newSerialNumber, newSecureId)">
                                    <mat-icon class="kemppi-symbols">ř</mat-icon>
                                    {{ 'common.add' | translate }}
                                </button>
                            </div>
                        </div>
                    </fieldset>

                </div>
            </fieldset>
        </div>


        <ng-container *ngIf="authUserScope === 'global'">

        <div class="full" fxLayout="row">
            <fieldset fxFlex class="limited-access">
                <legend>{{ 'common.meta' | translate }}</legend>
                <div fxLayout="row" fxLayoutAlign="start start">
                    <div *ngIf="registrationInfo.version.created" class="user-stamp">
                        <b>{{ 'common.created' | translate }}</b><br>
                        {{registrationInfo.version.created.timestamp | date:'short':undefined:localeService.locale}}<br>
                        {{registrationInfo.version.created.user?.name}}
                    </div>
                    <div *ngIf="registrationInfo.version.modified" class="user-stamp">
                        <b>{{ 'common.modified' | translate }}</b><br>
                        {{registrationInfo.version.modified.timestamp | date:'short':undefined:localeService.locale}}<br>
                        {{registrationInfo.version.modified.user?.name}}
                    </div>
                </div>
            </fieldset>
        </div>

        </ng-container>

    </div>

</div>

<ks-actionbar
        [hasCancel]="true"
        [hasSave]="true"
        [isSaving]="state.busy"
        [canSave]="!state.busy && valid && canSave"
        (save)="save()"
        (cancel)="cancel()"
>
    <button *ngIf="id !== 'new'" mat-button mat-raised-button mat-icon-button color="primary" (click)="print()">
        <mat-icon>print</mat-icon>
    </button>
    <button *ngIf="id !== 'new' && registrationInfo" mat-button mat-raised-button mat-icon-button color="primary" (click)="getWarrantyCertificate(registrationInfo.serialNumber, registrationInfo.secureId)">
        <mat-icon class="kemppi-symbols avatar-icon">è</mat-icon>
    </button>

    <div fxFlex></div>
</ks-actionbar>
