import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar, MatDialog, MatDialogConfig } from '@angular/material';

import { StateService } from 'src/app/services/state.service';
import { MachinesService } from 'src/app/services/machines.service';

import { EndCustomer } from 'src/app/models/endcustomer';
import { Registration } from 'src/app/models/registration';
import { IMachineInfo } from 'src/app/models/IMachineInfo';

import { AuthService } from 'src/app/services/auth.service';
import { EndCustomersService } from 'src/app/services/endcustomers.service';
import { CustomersService, ICustomerInfo } from 'src/app/services/customers.service';
import { RegistrationsService } from 'src/app/services/registrations.service';
import { LocaleService } from 'src/app/services/locale.service';
import { MachineryService } from 'src/app/services/machinery.service';

import { ConfirmDialogComponent } from 'src/app/shared/dialogs/components/confirm-dialog/confirm-dialog.component';
import { DiscardConfirmDialogComponent } from 'src/app/shared/dialogs/components/discard-dialog/discard-confirm-dialog.component';

import * as _ from 'lodash';

@Component({
    selector: 'app-registration-page',
    templateUrl: './registration-page.component.html',
    styleUrls: ['./registration-page.component.scss']
})
export class RegistrationPageComponent implements OnInit {

    /**
     * State of page
     */
    state: any;

    /**
     * Registration company, url parameter registrations/:company/:partnerId/:id
     */
    company: string;

    /**
     * Registration partnerId, url parameter registrations/:company/:partnerId/:id
     */
    partnerId: string;

    /**
     * Registration id, url parameter registrations/:company/:partnerId/:id
     */
    id: string;

    /**
     * Partner registration object
     */
    registrationInfo: Registration;

    /**
     * List of registrations.
     * When creating new registrations, can be added multiple serial numbers at once.
     * Registration info is cloned for all serials
     */
    registrations: Array<Registration>;

    /**
     * Add product - new serial number
     */
    newSerialNumber: string;

    /**
     * Add product - new secure id
     */
    newSecureId: string;

    // Customer/partner text search
    searchPartner: string;
    searchCustomerTimer: any;
    customersList = [];
    customersFormControl = new FormControl();

    // End customer search
    savedEndCustomers: Array<EndCustomer>;
    endCustomersFormControl = new FormControl();
    endCustomerSelection: any;
    searchEndCustomerTimer: any;

    // Fetched from machine registry
    deliveryDate?: string;
    endCustomerRegistrationDate?: string;
    warrantyExpireDate?: string;
    warrantyPeriodInMonths?: number;

    // Flags
    canSave: boolean;
    valid: boolean;
    alreadyRegistered: boolean;
    invalidSerialNumber: boolean;
    invalidSecureId: boolean;
    notShippedSerial: boolean;

    authUserScope = 'partner';

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        public stateService: StateService,
        private endCustomersService: EndCustomersService,
        private customersService: CustomersService,
        private registrationsService: RegistrationsService,
        private machineryService: MachineryService,
        private machinesServices: MachinesService,
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
        private localeService: LocaleService,
        private translateService: TranslateService
    ) {
        this.state = {
            busy: true
        };

        this.valid = false;
        this.alreadyRegistered = false;
        this.registrations = [];
    }

    ngOnInit() {
        setTimeout(() => {
            this.company = this.activatedRoute.snapshot.params['company'];
            this.partnerId = this.activatedRoute.snapshot.params['partnerId'];
            this.id = this.activatedRoute.snapshot.params['id'];
            this.stateService.state.file = this.id;
            this.authUserScope = this.authService.userProfile.permissions.scope;

            if (this.id === 'new') {
                this.registrationInfo = this.getNewRegistrationTemplate();
                this.getCustomerInfo(true);
            } else {
                this.getRegistrationInfo();
            }
            this.setCanSave();
            this.stateService.state.hasBack = true;
            this.state.busy = false;
        });
    }

    /**
     * Detect changes, if current claim data contains unsaved changes open discard confirmation dialog
     */
    canDeactivate(): Promise<boolean> | boolean {
        return new Promise( (resolve, reject) => {
            // If current claim data is remained unchanged return true and allow routing to another url
            if (_.isEqual(this.registrationInfo, this.state.originalData) && _.isEqual(this.registrations, [])) {
                resolve(true);
                return;
            }
            // Otherwise ask the user confirmation with the dialog. Return its promise which resolves to true or false when the user decides
            const dialogRef = this.dialog.open(DiscardConfirmDialogComponent);
            dialogRef.afterClosed().subscribe(results => {
                resolve(results);
            });
        });
    }

    /**
     * Store original claim data.
     * Used for canDeactivate() to check any changes. (Shows discard changes dialog)
     */
    storeOriginalData() {
        this.state.originalData = _.cloneDeep(this.registrationInfo);
    }

    setCanSave() {
        if (this.authService.isAllowedTo('registrations.write', this.company, this.partnerId)) {
            if (this.id === 'new') {
                this.canSave = true;
            } else if (this.authUserScope === 'partner') {
                this.canSave = false; // Partner can't save existing registration
            } else if (this.authUserScope === 'company') {
                this.canSave = true;
            } else if (this.authUserScope === 'global') {
                this.canSave = true;
            } else {
                this.canSave = false;
            }
        }
    }

    async save() {
        this.state.busy = true;
        console.log('save');

        this.validate();
        if (!this.valid) {
            console.log('Not valid');
            this.state.busy = false;
            return;
        }
        // Check end customer, need saving?
        await this.checkEndCustomerSaving();

        if (this.id === 'new') {
            const registrationPromises = [];
            for (const registration of this.registrations) {
                registrationPromises.push(new Promise((resolve, reject) => {
                    const salesDate = registration.data.salesDate;
                    registration.data = _.cloneDeep(this.registrationInfo.data);
                    registration.data.salesDate = salesDate;
                    registration.company = this.registrationInfo.company;
                    registration.partnerId = this.registrationInfo.partnerId;
                    registration.partnerName = this.registrationInfo.partnerName;
                    console.log('saveRegistration', registration);

                    this.registrationsService.saveRegistration(registration.company, registration.partnerId, registration.id, registration).subscribe((result: any) => {
                        if (result) {
                            this.registrationInfo.id = result;
                        }
                        this.notify('registrations.notifications.registration_successfully_saved', this.registrationInfo.id);
                        resolve(result);
                    }, (error) => {
                        this.showError(error);
                        this.state.busy = false;
                        reject();
                    });
                }));
            }
            await Promise.all(registrationPromises).then(values => {
                if (values.length === 1) {
                    // Wait for couple seconds that, machine-event handler does it's magic
                    setTimeout(() => {
                        this.state.busy = false;
                        this.registrations = [];
                        this.storeOriginalData();
                        this.router.navigate([`/registrations/${this.registrationInfo.company}/${this.registrationInfo.partnerId}/${this.registrationInfo.id}`]);
                        this.ngOnInit();
                    }, 3000);
                } else {
                    // Redirect back to listing
                    setTimeout(() => {
                        this.state.busy = false;
                        this.registrations = [];
                        this.storeOriginalData();
                        this.router.navigate([`/registrations`]);
                        this.ngOnInit();
                    }, 3000);
                }
            });

        } else {
            this.registrationsService.saveRegistration(this.registrationInfo.company, this.registrationInfo.partnerId, this.registrationInfo.id, this.registrationInfo).subscribe((result: any) => {
                if (result) {
                    this.registrationInfo.id = result;
                }
                this.notify('registrations.notifications.registration_successfully_saved', this.registrationInfo.id);
                // Wait for couple seconds that, machine-event handler does it's magic
                setTimeout(() => {
                    this.state.busy = false;
                    this.router.navigate([`/registrations/${this.registrationInfo.company}/${this.registrationInfo.partnerId}/${this.registrationInfo.id}`]);
                    this.ngOnInit();
                }, 3000);
            }, (error) => {
                this.showError(error);
                this.state.busy = false;
            });
        }
    }

    delete() {
        console.log('delete');
        this.registrationsService.deleteRegistration(this.registrationInfo.company, this.registrationInfo.partnerId, this.registrationInfo.id).subscribe((result: any) => {
            this.notify('registrations.notifications.registration_successfully_deleted', this.registrationInfo.id);
            this.router.navigate([`/registrations`]);
        }, (error) => {
            this.showError(error);
        });
    }

    cancel() {
        this.router.navigate(['registrations']);
    }

    validate() {
        console.log('validate', this.registrationInfo);
        this.valid = true;
        if (this.id === 'new') {
            if (!this.registrations || this.registrations.length === 0) { this.valid = false; }
            if (this.alreadyRegistered) { this.valid = false; }
            for (const registration of this.registrations) {
                if (!registration.serialNumber || registration.serialNumber === null || registration.serialNumber === '') { this.valid = false; }
                // No secure id check at this point. Check it when adding products (skipped on older products)
                // if (!registration.secureId || registration.secureId === null || registration.secureId === '') { this.valid = false; }
                if (!registration.productId || registration.productId === null || registration.productId === '') { this.valid = false; }
                if (!registration.data.salesDate || registration.data.salesDate === null || registration.data.salesDate === '') { this.valid = false; }
                if (!this.registrationInfo.data.customer.name || this.registrationInfo.data.customer.name === null || this.registrationInfo.data.customer.name === '') { this.valid = false; }
                if (registration.data.salesDate > registration.data.registrationDate) { this.valid = false; }
            }
        } else {
            console.log('validate', this.registrationInfo);
            if (!this.registrationInfo.serialNumber || this.registrationInfo.serialNumber === null || this.registrationInfo.serialNumber === '') { this.valid = false; }
            if (!this.registrationInfo.secureId || this.registrationInfo.secureId === null || this.registrationInfo.secureId === '') { this.valid = false; }
            if (!this.registrationInfo.productId || this.registrationInfo.productId === null || this.registrationInfo.productId === '') { this.valid = false; }
            if (!this.registrationInfo.data.salesDate || this.registrationInfo.data.salesDate === null || this.registrationInfo.data.salesDate === '') { this.valid = false; }
            if (!this.registrationInfo.data.customer.name || this.registrationInfo.data.customer.name === null || this.registrationInfo.data.customer.name === '') { this.valid = false; }
            if (this.registrationInfo.data.salesDate > this.registrationInfo.data.registrationDate) { this.valid = false; }
        }
        console.log('validate', this.valid);
    }

    /**
     * Get registration from backend by company number, partner id and serial number
     * There should be registration details stored in [company]/[partnerId]/registrations/[serialNumber]
     * But if that is not found for example older migrated machines, we generate the details here on the fly.
     */
    getRegistrationInfo() {
        this.registrationsService.getRegistration(this.company, this.partnerId, this.id).subscribe((registration: Registration) => {
            this.registrationInfo = registration;
            console.log('registration', this.registrationInfo);
            if (this.registrationInfo) {
                this.getSerialInfo(this.registrationInfo.serialNumber, this.registrationInfo.secureId);
            } else {
                // this.notify('Warning, unable to load registration details for serial ' + this.id, 5000);
                // throw new Error('Error fetching registration');
                this.generateRegistrationInfo();
            }
        }, (error) => {
            this.showError(error);
        });
    }

    /**
     * Get serial info
     * SecureId or (company + partnerId) must match
     */
    getSerialInfo(serialNumber, secureId, company = null, partnerId = null) {
        this.alreadyRegistered = false;
        this.machinesServices.getMachineInfo(serialNumber).subscribe((machine: any) => {
            console.log('machine', machine);
            // Machine must have delivery date
            if (machine && machine.deliveryDate
                && (machine.secureId === secureId
                    || (machine.registeredCompany === company && machine.registeredPartnerId === partnerId)
                )
            ) {
                if (machine && machine.partnerRegistrationDate) {
                    this.alreadyRegistered = true;
                }
                this.registrationInfo.secureId = machine.secureId;
                this.registrationInfo.productId = machine.productId;
                this.registrationInfo.productName = machine.productInfo.partDescription;
                this.deliveryDate = machine.deliveryDate;
                this.registrationInfo.data.registrationDate = machine.partnerRegistrationDate ? machine.partnerRegistrationDate : new Date().toISOString();
                this.registrationInfo.data.salesDate = machine.salesDate ? machine.salesDate : null;
                this.endCustomerRegistrationDate = machine.endCustomerRegistrationDate;
                this.warrantyExpireDate = machine.warrantyExpireDate;
                if (this.registrationInfo.data.salesDate && this.warrantyExpireDate) {
                    this.warrantyPeriodInMonths = this.monthDiff(new Date(this.registrationInfo.data.salesDate), new Date(this.warrantyExpireDate));
                }

                // If customer name not set, and found from serial info, update it
                if (machine.registeredEndCustomerName && _.get(this.registrationInfo, 'data.customer.name', '') === '') {
                    _.set(this.registrationInfo, 'data.customer.name', machine.registeredEndCustomerName);
                }
            } else {
                this.registrationInfo.productId = null;
                this.registrationInfo.productName = null;
                this.deliveryDate = null;
                this.warrantyExpireDate = null;
            }
            this.validate();
            this.storeOriginalData();
        });
    }

    addSerial(serialNumber, secureId) {
        if (!serialNumber || serialNumber === '') {
            return;
        }
        // Trim
        serialNumber = serialNumber ? serialNumber.trim() : '';
        this.newSerialNumber = serialNumber;
        secureId = secureId ? secureId.trim() : '';
        this.newSecureId = secureId;

        if (_.find(this.registrations, { serialNumber: serialNumber })) {
            return;
        }
        this.alreadyRegistered = false;
        this.invalidSerialNumber = false;
        this.invalidSecureId = false;
        this.notShippedSerial = false;
        let skipSecureIdCheck = false;
        this.machinesServices.getMachineInfo(serialNumber).subscribe((machine: any) => {
            console.log('machine', machine);

            if (!machine) {
                this.invalidSerialNumber = true;
            }
            if (machine && this.shouldSkipSecureIdCheck(machine)) {
                skipSecureIdCheck = true;
            }
            if (machine && !skipSecureIdCheck && secureId && machine.secureId !== secureId) {
                this.invalidSecureId = true;
            }
            if (machine && !machine.deliveryDate && (skipSecureIdCheck || machine.secureId === secureId)) {
                this.notShippedSerial = true;
            }

            // Machine must have delivery date
            if (machine && machine.deliveryDate && (skipSecureIdCheck || machine.secureId === secureId)) {
                if (machine && machine.partnerRegistrationDate) {
                    this.alreadyRegistered = true;
                } else {
                    const newRegistration = this.getNewRegistrationTemplate();
                    newRegistration.serialNumber = machine.serialNumber;
                    newRegistration.secureId = machine.secureId;
                    newRegistration.productId = machine.productId;
                    newRegistration.productName = machine.productInfo.partDescription;
                    const salesDate = new Date();
                    salesDate.setUTCHours(0, 0, 0, 0);
                    newRegistration.data.salesDate = salesDate.toISOString();
                    this.registrations.push(newRegistration);
                    this.newSerialNumber = '';
                    this.newSecureId = '';
                }
            } else {
                this.registrationInfo.productId = null;
                this.registrationInfo.productName = null;
                this.deliveryDate = null;
                this.warrantyExpireDate = null;
            }
            this.validate();
        });
    }

    deleteSerial(index) {
        this.registrations.splice(index, 1);
    }

    shouldSkipSecureIdCheck(machine: IMachineInfo): boolean  {
        // Skip for old machines
        const isOld = machine.manufactureDate < '2018-01-01T00:00:00.000Z';

        // Skip for torches and guns (example product number: GXE205G5)
        // Serials are in format "Kxxxxxx-x" or "Kxxxxxx-xx" where K = K and x = integer.
        const GunOrTorchSerialRegex = /K\d{6}-\d{1,2}/;
        const isGunOrTorch = GunOrTorchSerialRegex.test(machine.serialNumber);

        return isOld || isGunOrTorch;
    }

    // -----------------------------------------
    // DIRECT CUSTOMER SEARCHING

    searchCustomers() {
        if (this.searchPartner.length > 3) {
            clearTimeout(this.searchCustomerTimer);
            this.searchCustomerTimer = setTimeout(() => {
                const params = [
                    { key: 'searchName', value: '*' + this.searchPartner.toUpperCase() + '*' },
                    { key: 'groupCode', value: 'A' }
                ];
                this.machineryService.searchCustomers(params).subscribe((results) => {
                    this.customersList = results as Array<any>;
                }, (error) => {
                    console.log('Error, searchCustomers', error);
                });
            }, 1000); // Time in ms before calling the backend search
        }
    }

    changeCustomer(customer) {
        if (customer && customer.value && customer.value.name && customer.value.custId && customer.value.company) {
            console.log('changeCustomer', customer.value);
            this.searchPartner = customer.value.name;
            this.registrationInfo.data.customer.partnerId = customer.value.custId;
            this.registrationInfo.data.customer.company = customer.value.company;
            this.getDirectCustomerInfo();
        }
    }

    // -----------------------------------------
    // END CUSTOMER SEARCHING, SAVE AND DELETING

    getOptionText(option) {
        return option && option.data && option.data.name ? option.data.name : option || '';
    }
    searchEndCustomers() {
        // Name must contain at least 1 letters and then wait x millisecods before searching (trying to prevent unnecessary requests)
        if (this.registrationInfo.data.customer.name.length > 0) {
            clearTimeout(this.searchEndCustomerTimer);
            this.searchEndCustomerTimer = setTimeout(() => {
                this.registrationInfo.data.customer.id = null;

                const params = [
                    { key: 'data.name.normalize', value: '*' + this.registrationInfo.data.customer.name + '*' }
                ];
                const sort = [
                    { key: 'data.name.normalize', direction: 'asc' }
                ];
                this.endCustomersService.searchEndCustomersByPartner(this.registrationInfo.company, this.registrationInfo.partnerId, params, 50, 0, sort).subscribe(response => {
                    if (response) {
                        this.savedEndCustomers = response as Array<EndCustomer>;
                    }
                });
            }, 500); // Time in ms before calling the backend search
        }
    }

    changeEndCustomer(endcustomer) {
        if (endcustomer && endcustomer.value && endcustomer.value.data && endcustomer.value.data.name) {
            this.registrationInfo.data.customer.id = endcustomer.value.id;
            this.registrationInfo.data.customer.name = endcustomer.value.data.name;
            this.registrationInfo.data.customer.streetAddress = endcustomer.value.data.streetAddress;
            this.registrationInfo.data.customer.postalCode = endcustomer.value.data.postalCode;
            this.registrationInfo.data.customer.city = endcustomer.value.data.city;
            this.registrationInfo.data.customer.country = endcustomer.value.data.country;
            this.registrationInfo.data.customer.vatNumber = endcustomer.value.data.vatNumber;
        }
    }

    saveEndCustomer() {
        return new Promise((resolve, reject) => {
            const id = this.registrationInfo.data.customer.id || null;
            const endCustomer: EndCustomer = {
                id: id,
                company: this.registrationInfo.company,
                partnerId: this.registrationInfo.partnerId,
                partnerName: this.registrationInfo.partnerName,
                data: {
                    name: this.registrationInfo.data.customer.name,
                    streetAddress: this.registrationInfo.data.customer.streetAddress,
                    postalCode: this.registrationInfo.data.customer.postalCode,
                    city: this.registrationInfo.data.customer.city,
                    country: this.registrationInfo.data.customer.country,
                    vatNumber: this.registrationInfo.data.customer.vatNumber
                },
                schema: {
                    type: 'warranty-endcustomer',
                    version: '1.0'
                },
                version: {}
            };
            this.endCustomersService.saveEndCustomer(this.registrationInfo.company, this.registrationInfo.partnerId, id, endCustomer).subscribe((response: any) => {
                if (response) {
                    this.registrationInfo.data.customer.id = response;
                }
                this.notify('registrations.notifications.endcustomer_successfully_saved', response);
                resolve(true);
            });
        });
    }

    deleteEndCustomer() {
        const id = this.registrationInfo.data.customer.id || null;
        if (id) {
            this.endCustomersService.deleteEndCustomer(this.registrationInfo.company, this.registrationInfo.partnerId, id).subscribe((response: any) => {
                this.registrationInfo.data.customer.id = null;
                this.notify('registrations.notifications.endcustomer_successfully_deleted', response);
            });
        }
    }

    /**
     * Open 'Do you want to save end customer' dialog
     */
    checkEndCustomerSaving() {
        return new Promise((resolve, reject) => {
            if (!this.registrationInfo.data.directCustomer && !this.registrationInfo.data.customer.id) {
                const dialogConfig: MatDialogConfig = {
                    data: {
                        title: 'confirm_dialog.unsaved_end_customer',
                        content: 'confirm_dialog.do_you_want_to_save_end_customer'
                    }
                };

                const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
                dialogRef.afterClosed().subscribe(async result => {
                    if (result === 'ok') {
                        // Save end customer before saving the registration
                        await this.saveEndCustomer();
                        resolve(true);
                    } else {
                        // Do not save end customer
                        resolve(true);
                    }
                });
            } else {
                resolve(true);
            }
        });
    }

    /**
     * Get "Customer" info = PARTNER
     */
    getCustomerInfo(initNew = false) {
        this.registrationInfo.company = this.registrationInfo.company.trim().toUpperCase();
        this.registrationInfo.partnerId = this.registrationInfo.partnerId.trim().toUpperCase();
        this.customersService.getCustomerInfo(this.registrationInfo.company, this.registrationInfo.partnerId).subscribe(async (customerInfo: ICustomerInfo) => {
            if (customerInfo) {
                this.registrationInfo.partnerName = customerInfo.name;
                this.registrationInfo.data.language = customerInfo.langNameId;
                this.registrationInfo.data.partner.name = customerInfo.name;
                this.registrationInfo.data.partner.streetAddress = customerInfo.address.address;
                this.registrationInfo.data.partner.postalCode = customerInfo.address.zip;
                this.registrationInfo.data.partner.city = customerInfo.address.city;
                this.registrationInfo.data.partner.country = customerInfo.address.country;
                this.registrationInfo.data.partner.groupDesc = customerInfo.groupDesc;
                // Direct customer
                if (customerInfo.groupCode === 'A') {
                    this.registrationInfo.data.customer.name = customerInfo.name;
                    this.registrationInfo.data.customer.streetAddress = _.get(customerInfo, 'address.address1');
                    this.registrationInfo.data.customer.postalCode = _.get(customerInfo, 'address.zip');
                    this.registrationInfo.data.customer.city = _.get(customerInfo, 'address.city');
                    this.registrationInfo.data.customer.country = _.get(customerInfo, 'address.country');
                } else {
                    this.registrationInfo.data.customer.name = '';
                    this.registrationInfo.data.customer.streetAddress = '';
                    this.registrationInfo.data.customer.postalCode = '';
                    this.registrationInfo.data.customer.city = '';
                    this.registrationInfo.data.customer.country = '';
                }
                if (initNew) {
                    this.storeOriginalData();
                }
            }
            this.validate();
        }, (error) => {
            console.log('ERROR fetching customerInfo');
        });
    }

    /**
     * Get "Direct Customer" info = End customer as direct customer
     */
    getDirectCustomerInfo() {
        if (!this.registrationInfo.data.customer.company || !this.registrationInfo.data.customer.partnerId) {
            return;
        }
        this.registrationInfo.data.customer.company = this.registrationInfo.data.customer.company.trim().toUpperCase();
        this.registrationInfo.data.customer.partnerId = this.registrationInfo.data.customer.partnerId.trim().toUpperCase();
        this.customersService.getCustomerInfo(this.registrationInfo.data.customer.company, this.registrationInfo.data.customer.partnerId).subscribe(async (customerInfo: ICustomerInfo) => {
            if (customerInfo) {
                // Direct customer
                if (customerInfo.groupCode === 'A') {
                    this.registrationInfo.data.customer.name = customerInfo.name;
                    this.registrationInfo.data.customer.streetAddress = _.get(customerInfo, 'address.address1');
                    this.registrationInfo.data.customer.postalCode = _.get(customerInfo, 'address.zip');
                    this.registrationInfo.data.customer.city = _.get(customerInfo, 'address.city');
                    this.registrationInfo.data.customer.country = _.get(customerInfo, 'address.country');
                } else {
                    this.registrationInfo.data.customer.name = '';
                    this.registrationInfo.data.customer.streetAddress = '';
                    this.registrationInfo.data.customer.postalCode = '';
                    this.registrationInfo.data.customer.city = '';
                    this.registrationInfo.data.customer.country = '';
                }
            }
            this.validate();
        }, (error) => {
            console.log('ERROR fetching directCustomerInfo');
        });
    }

    checkDirectCustomerCompany() {
        console.log('checkDirectCustomerCompany', this.registrationInfo.data.directCustomer);
        if (!this.registrationInfo.data.directCustomer) {
            delete this.registrationInfo.data.customer.company;
            delete this.registrationInfo.data.customer.partnerId;
            delete this.searchPartner;
        }
        if (this.registrationInfo.data.directCustomer && (!this.registrationInfo.data.customer.company || this.registrationInfo.data.customer.company === '')) {
            this.registrationInfo.data.customer.company = this.registrationInfo.company;
        }
    }

    generateRegistrationInfo() {
        this.registrationInfo = this.getNewRegistrationTemplate();
        this.getCustomerInfo();
        this.getSerialInfo(this.id, null, this.company, this.partnerId);
        this.registrationInfo.serialNumber = this.id;
        this.canSave = false;
        this.storeOriginalData();
    }

    getNewRegistrationTemplate() {
        const registration: Registration = {
            id: null,
            serialNumber: null,
            secureId: null,
            productId: null,
            productName: null,
            company: this.company,
            partnerId: this.partnerId,
            partnerName: '',
            data: {
                registrationDate: new Date().toISOString(),
                customer: {},
                language: 'enu',
                partner: {
                    name: '',
                    streetAddress: '',
                    postalCode: '',
                    city: '',
                    country: ''
                }
            },
            schema: {
                type: 'partner-registration',
                version: '0.1'
            },
            version: {}
        };
        return registration;
    }

    getWarrantyCertificate(serial, secureId) {
        // Create window instantly so that popup don't get blocked by the browser
        const newTab = window.open('', '_blank');

        const language = this.localeService.language;
        newTab.location.href = `/registrations/certificate/${serial}?sec=${secureId}&print=true&language=${language}`;
    }

    /**
     * Calculate warranty perioid in months
     */
    monthDiff(d1, d2) {
        let months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
    }

    showError(error) {
        const message = _.get(error, 'message');
        const type = _.get(error, 'error.error.caused_by.caused_by.type') || _.get(error, 'error.error.caused_by.type') || '';
        const reason = _.get(error, 'error.error.caused_by.caused_by.reason') || _.get(error, 'error.error.caused_by.reason') || '';

        this.snackBar.open(message + '\n\n' + type + '\n' + reason, 'OK', {
            duration: 5000,
            panelClass: ['war-snackbar', 'war-snackbar-error-message']
        });
    }

    /**
     * Show snackbar notification
     */
    notify(translationKey, id = '', duration = 3000) {
        this.translateService.get(translationKey).subscribe(translation => {
            const message = _.join([translation, id], '\n');
            this.snackBar.open(message, 'OK', {
                duration: duration,
                panelClass: ['war-snackbar']
            });
        });
    }

    print() {
        window.print();
    }
}
