<div class="main">
    <div class="list-container">
        <div class="full main-header" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <mat-icon class="not-kemppi-symbols">euro_symbol</mat-icon>
            <div fxLayout="column">
                <h2>{{ 'menu.labour_costs' | translate }}</h2>
                <div>{{'menu.settings' | translate}} - {{'menu.prices' | translate}}</div>
            </div>
        </div>
    
        <div *ngFor="let company of dataTree">
            <ng-container *ngIf="permissions.scope === 'global' || (permissions.scope === 'company' && userCompany === company.id)">
                <div fxLayout="row" fxLayoutAlign="start center" class="list-row" (click)="company.expanded = !company.expanded">
                    <button mat-icon-button color="accent">
                        <mat-icon *ngIf="!company.expanded">chevron_right</mat-icon>
                        <mat-icon *ngIf="company.expanded">expand_more</mat-icon>
                    </button>

                    <!--
                    <ng-container *ngIf="permissions.scope === 'global'">
                        <mat-checkbox [(ngModel)]="company.selected" class="select-checkbox"></mat-checkbox>
                        <button fxFlex="100px" mat-button color="primary" (click)="openCost(company.id)">{{ company.id }}</button>
                    </ng-container>
                    <ng-container *ngIf="permissions.scope === 'company'">
                        <div fxFlex="100px">{{ company.id }}</div>
                    </ng-container>
                    -->
                    <div fxFlex="100px"><b>{{ company.id }}</b> - <i>({{company.partners.length}})</i></div>
                    <div fxFlex="80px">
                        <!--
                        {{ data.companies[company.id].cost }}
                        {{ data.companies[company.id].currency }}
                        -->
                    </div>
                    <div fxFlex="500px"><b>{{ data.companies[company.id].name }}</b></div>
                </div>

                <ng-container *ngIf="company.expanded">
                    <fieldset class="partner">
                        <legend>{{ 'labour_costs.max_costs' | translate }}</legend>
                        <ng-container *ngIf="permissions.scope === 'global'">
                            <div *ngFor="let maxCost of company.maxCosts">
                                <div fxLayout="row" fxLayoutAlign="start center">
                                    <button fxFlex="100px" mat-button color="accent" (click)="openMaxCost(company.id, maxCost.id)">{{ maxCost.id }}</button>
                                    <div fxFlex="80px">
                                        {{ data.companies[company.id].maxCosts[maxCost.id] }}
                                        {{ maxCost.id }}
                                    </div>
                                    <button mat-icon-button color="warn" (click)="removeMaxCost(company.id, maxCost.id)" [disabled]="!canEdit">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                            <div>
                                <button mat-button mat-raised-button color="accent" (click)="openMaxCost(company.id, 'new')" [disabled]="!canEdit">
                                    <mat-icon>add</mat-icon> {{ 'common.add' | translate }}
                                </button>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="permissions.scope === 'company' && userCompany === company.id">
                            <div *ngFor="let maxCost of company.maxCosts">
                                <div fxLayout="row" fxLayoutAlign="start center">
                                    <div fxFlex="80px">
                                        {{ data.companies[company.id].maxCosts[maxCost.id] }}
                                        {{ maxCost.id }}
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </fieldset>


                    <fieldset class="partner">
                        <legend>{{ 'labour_costs.partners' | translate }}</legend>

                        <!-- Subsidiaries (Company 100 only) -->
                        <fieldset *ngIf="company.id === '100'" class="sub-fieldset">
                            <legend (click)="togglePartners(company.id, 'subsidiaries')">
                                <mat-icon *ngIf="!company.expandedSubsidiaries">chevron_right</mat-icon>
                                <mat-icon *ngIf="company.expandedSubsidiaries">expand_more</mat-icon>
                                {{ 'labour_costs.subsidiaries' | translate }}
                            </legend>
                            <ng-container *ngIf="company.expandedSubsidiaries">
                                <div *ngFor="let partner of company.partners">
                                    <div *ngIf="data.companies[company.id].partners[partner.id].groupCode === 'E'" fxLayout="row" fxLayoutAlign="start center" [ngClass]="{
                                        'warn': !data.companies[company.id].maxCosts[data.companies[company.id].partners[partner.id].currency] || data.companies[company.id].partners[partner.id].cost > data.companies[company.id].maxCosts[data.companies[company.id].partners[partner.id].currency] }">
                                        <mat-checkbox [(ngModel)]="partner.selected" class="select-checkbox"></mat-checkbox>
                                        <button fxFlex="100px" mat-button color="accent" (click)="openCost(company.id, partner.id)">{{ partner.id }}</button>
                                        <div fxFlex="80px">
                                            {{ data.companies[company.id].partners[partner.id].cost }}
                                            {{ data.companies[company.id].partners[partner.id].currency }}
                                        </div>
                                        <div fxFlex="25px">
                                            <small class="extra-small">{{ data.companies[company.id].partners[partner.id].groupCode }}</small>
                                        </div>
                                        <div fxFlex="110px">
                                            <small class="extra-small">{{ data.companies[company.id].partners[partner.id].groupDesc }}</small>
                                        </div>
                                        <div fxFlex="400px">
                                            <small>{{ data.companies[company.id].partners[partner.id].name }}</small>
                                        </div>
                                        <button mat-icon-button color="warn" (click)="remove(company.id, partner.id)" [disabled]="!canEdit">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </ng-container>
                        </fieldset>

                        <!-- Distributors (Company 100 only) -->
                        <fieldset *ngIf="company.id === '100'" class="sub-fieldset">
                            <legend (click)="togglePartners(company.id, 'distributors')">
                                <mat-icon *ngIf="!company.expandedDistributors">chevron_right</mat-icon>
                                <mat-icon *ngIf="company.expandedDistributors">expand_more</mat-icon>
                                {{ 'labour_costs.distributors' | translate }}
                            </legend>
                            <ng-container *ngIf="company.expandedDistributors">
                                <div *ngFor="let partner of company.partners">
                                    <div *ngIf="data.companies[company.id].partners[partner.id].groupCode === 'B11' || data.companies[company.id].partners[partner.id].groupCode === 'B12' || data.companies[company.id].partners[partner.id].groupCode === 'B13'"  fxLayout="row" fxLayoutAlign="start center" [ngClass]="{
                                        'warn': !data.companies[company.id].maxCosts[data.companies[company.id].partners[partner.id].currency] || data.companies[company.id].partners[partner.id].cost > data.companies[company.id].maxCosts[data.companies[company.id].partners[partner.id].currency] }">
                                        <mat-checkbox [(ngModel)]="partner.selected" class="select-checkbox"></mat-checkbox>
                                        <button fxFlex="100px" mat-button color="accent" (click)="openCost(company.id, partner.id)">{{ partner.id }}</button>
                                        <div fxFlex="80px">
                                            {{ data.companies[company.id].partners[partner.id].cost }}
                                            {{ data.companies[company.id].partners[partner.id].currency }}
                                        </div>
                                        <div fxFlex="25px">
                                            <small class="extra-small">{{ data.companies[company.id].partners[partner.id].groupCode }}</small>
                                        </div>
                                        <div fxFlex="110px">
                                            <small class="extra-small">{{ data.companies[company.id].partners[partner.id].groupDesc }}</small>
                                        </div>
                                        <div fxFlex="400px">
                                            <small>{{ data.companies[company.id].partners[partner.id].name }}</small>
                                        </div>
                                        <button mat-icon-button color="warn" (click)="remove(company.id, partner.id)" [disabled]="!canEdit">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </ng-container>
                        </fieldset>

                        <!-- Dealers & Direct customers -->
                        <fieldset class="sub-fieldset">
                            <legend (click)="togglePartners(company.id, 'dealers')">
                                <mat-icon *ngIf="!company.expandedDealers">chevron_right</mat-icon>
                                <mat-icon *ngIf="company.expandedDealers">expand_more</mat-icon>
                                {{ 'labour_costs.dealers_and_direct_customers' | translate }}
                            </legend>
                            <ng-container *ngIf="company.expandedDealers">
                                <div *ngFor="let partner of company.partners">
                                    <div *ngIf="data.companies[company.id].partners[partner.id].groupCode !== 'E' && data.companies[company.id].partners[partner.id].groupCode !== 'B11' && data.companies[company.id].partners[partner.id].groupCode !== 'B12' && data.companies[company.id].partners[partner.id].groupCode !== 'B13'" fxLayout="row" fxLayoutAlign="start center" [ngClass]="{
                                        'warn': !data.companies[company.id].maxCosts[data.companies[company.id].partners[partner.id].currency] || data.companies[company.id].partners[partner.id].cost > data.companies[company.id].maxCosts[data.companies[company.id].partners[partner.id].currency] }">
                                        <mat-checkbox [(ngModel)]="partner.selected" class="select-checkbox"></mat-checkbox>
                                        <button fxFlex="100px" mat-button color="accent" (click)="openCost(company.id, partner.id)">{{ partner.id }}</button>
                                        <div fxFlex="80px">
                                            {{ data.companies[company.id].partners[partner.id].cost }}
                                            {{ data.companies[company.id].partners[partner.id].currency }}
                                        </div>
                                        <div fxFlex="25px">
                                            <small class="extra-small">{{ data.companies[company.id].partners[partner.id].groupCode }}</small>
                                        </div>
                                        <div fxFlex="110px">
                                            <small class="extra-small">{{ data.companies[company.id].partners[partner.id].groupDesc }}</small>
                                        </div>
                                        <div fxFlex="400px">
                                            <small>{{ data.companies[company.id].partners[partner.id].name }}</small>
                                        </div>
                                        <button mat-icon-button color="warn" (click)="remove(company.id, partner.id)" [disabled]="!canEdit">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </ng-container>
                        </fieldset>

                        <div>
                            <button mat-button mat-raised-button color="accent" (click)="openCost(company.id, 'new')" [disabled]="!canEdit">
                                <mat-icon>add</mat-icon> {{ 'common.add' | translate }}
                            </button>
                        </div>
                    </fieldset>
                </ng-container>
            </ng-container>
        </div>
        <ng-container *ngIf="permissions.scope === 'global'">
            <div fxLayout="row">
                <button fxFlex="100px" mat-button mat-raised-button color="accent" (click)="openCost()" [disabled]="!canEdit">
                    <mat-icon>add</mat-icon> {{ 'common.add' | translate }}
                </button>
            </div>
        </ng-container>
    </div>
    <!--
    <pre>
        {{ data | json }}
    </pre>
    -->
</div>

<!-- ACTIONBAR BUTTONS -->
<ks-actionbar
        [hasCancel]="false"
        [hasSave]="true"
        [isSaving]="false"
        [canSave]="canSave"
        (save)="save()"
>
    <div fxLayout="row" fxFlex>

        <div fxFlex></div>

        <ng-container>
            <div class="actions" fxLayout="row" fxLayout.lt-md="column">
                <ng-container>
                    <button mat-button mat-raised-button color="accent" (click)="openCostForSelected()" [disabled]="!canEdit">
                            <mat-icon class="kemppi-symbols">ó</mat-icon> {{ 'labour_costs.set_costs_for_selected' | translate }}
                    </button>
                </ng-container>
            </div>
        </ng-container>
        <div fxFlex></div>

        <!-- cancel and save buttons are added -->
    </div>
</ks-actionbar>
