import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as _ from 'lodash';
import * as bodybuilder from 'bodybuilder';

import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';

declare var require: any;

// Now calls straight machinery endpoints.
// TODO: Move all this functionality to warranty backend REST endpoint

@Injectable()
export class MachinesService {

    BACKENDURL: string;
    APIROOT: string;

    constructor(
        private appConfigService: AppConfigService,
        private authService: AuthService,
        private http: HttpClient
    ) {
        this.BACKENDURL = appConfigService.config.machineryBackendUrl;
    }

    get token() {
        return this.authService.accessToken;
    }

    getMachineInfo(serialNumber) {
        console.log('getMachineInfo', serialNumber);
        return this.http.get(`${this.BACKENDURL}/machines/${serialNumber}`, { headers: this._getHeaders() });
    }

    _getHeaders() {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
        });
    }

    // Returns search params ?search=key:value;key2:value2
    _getSearchString(params) {
        const queryStringParams = _.map(params, (p) => {
            return p.key + ':' + p.value;
        }).join(',');
        return queryStringParams;
    }

    // Returns sort params ?sort=key:direction;key2:direction2
    _getSortString(params) {
        const queryStringParams = _.map(params, (p) => {
            return p.key + ':' + p.direction;
        }).join(',');
        return queryStringParams;
    }

    getMachinesReport(options) {
        console.log('getMachinesReport', options);
        const builder = bodybuilder();
        if (options.fields) {
            builder.rawOption('_source', { includes: options.fields });
        }
        if (options.filters) {
            options.filters.forEach(filter => {
                builder.filter(filter.type || 'match', filter.key, filter.value);
            });
        }
        if (options.aggregations) {
            options.aggregations.forEach(aggregation => {
                builder.aggregation(aggregation.type, aggregation.field, aggregation.name, aggregation.options);
            });
        }
        if (options.sort) {
            builder.sort(options.sort.field, options.sort.direction || 'desc');
        }
        const body = builder.build();
        console.log('body', JSON.stringify(body));

        return this.http.post(`${this.APIROOT}/_search`, body);
    }

    getMachineTypes() {
        console.log('getMachineTypes');
        const builder = bodybuilder();
        const body = builder
            .size(0)
            .aggregation('terms', 'productId', {
                size: 10000
            }, agg => agg.aggregation('top_hits', undefined, { _source: { includes: ['productId', 'productGroup', 'productName'] }, size: 1 }, 'productName' ))
            .build();

        console.log('body', JSON.stringify(body));

        return this.http.post(`${this.APIROOT}/_search`, body);
    }

}
