<!-- PARTNER -->
<ng-container *ngIf="data.type === 'partner'">
    <div mat-dialog-title>
        <h3>{{ 'labour_costs.set_partner_labour_cost' | translate }}</h3>
    </div>
    <div mat-dialog-content>
        <div fxLayoutGap="20px">
            <mat-form-field>
                <mat-label>{{ 'common.partner_id' | translate }}</mat-label>
                <input matInput [(ngModel)]="data.partnerId" (change)="customerChanged()" required>
            </mat-form-field>
            <mat-form-field readonly="true">
                <mat-label>{{ 'common.company' | translate }}</mat-label>
                <input matInput [(ngModel)]="data.company" (change)="customerChanged()" required readonly="true">
            </mat-form-field>
        </div>
        <div fxLayout="row">
            <mat-form-field fxFlex readonly="true">
                <mat-label>{{ 'common.partner_name' | translate }}</mat-label>
                <input matInput [(ngModel)]="data.name" required readonly="true">
            </mat-form-field>
            <mat-form-field fxFlex readonly="true">
                <mat-label>{{ 'common.type' | translate }}</mat-label>
                <input matInput [(ngModel)]="data.groupDesc" required readonly="true">
            </mat-form-field>
        </div>
        <div fxLayoutGap="20px">
            <mat-form-field>
                <mat-label>{{ 'common.cost' | translate }}</mat-label>
                <input matInput [(ngModel)]="data.cost" type="number" min="0" [max]="data.maxCosts[data.currency]"
                    [disabled]="!data.maxCosts[data.currency]" required>
                <mat-hint *ngIf="data.maxCosts[data.currency] > 0">MAX {{data.maxCosts[data.currency]}} {{data.currency}}</mat-hint>
                <mat-hint *ngIf="data.currency && data.maxCosts[data.currency] === undefined" class="warn">Missing max value for {{ data.currency }}</mat-hint>
            </mat-form-field>
            <mat-form-field readonly="true">
                <mat-label>{{ 'common.currency' | translate }}</mat-label>
                <input matInput [(ngModel)]="data.currency" required readonly="true">
            </mat-form-field>
        </div>
    </div>
    <div mat-dialog-actions fxLayoutAlign="center center" fxLayoutGap="10px">
        <button mat-button mat-raised-button mat-dialog-close="" color="primary"><span translate>common.cancel</span></button>
        <button mat-button mat-raised-button mat-dialog-close="ok" color="accent" [disabled]="!data.cost || !data.currency || data.cost <= 0 || data.cost > data.maxCosts[data.currency]"><span translate>common.ok</span></button>
    </div>
</ng-container>

<!-- MULTIPLE -->
<ng-container *ngIf="data.type === 'multiple'">
    <div mat-dialog-title>
        <h3>{{ 'labour_costs.set_multiple_labour_cost' | translate }}</h3>
    </div>
    <div mat-dialog-content>
        <div *ngIf="data.partners">
            <small *ngFor="let partner of data.partners">{{partner.partnerId}} / {{partner.company}}<br></small>
        </div>
        <div fxLayoutGap="20px">
            <mat-form-field>
                <mat-label>{{ 'common.cost' | translate }}</mat-label>
                <input matInput [(ngModel)]="data.cost" type="number" min="0" [max]="data.maxCosts[data.currency]"
                    [disabled]="!data.maxCosts[data.currency]" required>
                <mat-hint *ngIf="data.maxCosts[data.currency] > 0">MAX {{data.maxCosts[data.currency]}} {{data.currency}}</mat-hint>
                <mat-hint *ngIf="data.currency && data.maxCosts[data.currency] === undefined" class="warn">Missing max value for {{ data.currency }}</mat-hint>
            </mat-form-field>
            <mat-form-field readonly="true">
                <mat-label>{{ 'common.currency' | translate }}</mat-label>
                <input matInput [(ngModel)]="data.currency" required readonly="true">
            </mat-form-field>
        </div>
    </div>
    <div mat-dialog-actions fxLayoutAlign="center center" fxLayoutGap="10px">
        <button mat-button mat-raised-button mat-dialog-close="" color="primary"><span translate>common.cancel</span></button>
        <button mat-button mat-raised-button mat-dialog-close="ok" color="accent" [disabled]="!data.cost || !data.currency || data.cost <= 0 || data.cost > data.maxCosts[data.currency]"><span translate>common.ok</span></button>
    </div>
</ng-container>

<!-- MAX COST -->
<ng-container *ngIf="data.type === 'maxCost'">
    <div mat-dialog-title>
        <h3>{{ 'labour_costs.set_max_labour_cost' | translate }}</h3>
    </div>
    <div mat-dialog-content>
        <div fxLayoutGap="20px">
            <mat-form-field>
                <mat-label>{{ 'common.cost' | translate }}</mat-label>
                <input matInput [(ngModel)]="data.cost" type="number" min="0" max="1000" required>
            </mat-form-field>
            <mat-form-field readonly="true">
                <mat-label>{{ 'common.currency' | translate }}</mat-label>
                <mat-select [(value)]="data.currency" required>
                    <mat-option *ngFor="let currencyCode of currencyCodes" [value]="currencyCode">{{currencyCode}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div mat-dialog-actions fxLayoutAlign="center center" fxLayoutGap="10px">
        <button mat-button mat-raised-button mat-dialog-close="" color="primary"><span translate>common.cancel</span></button>
        <button mat-button mat-raised-button mat-dialog-close="ok" color="accent" [disabled]="!data.cost || !data.currency || data.cost <= 0"><span translate>common.ok</span></button>
    </div>
</ng-container>

<!-- COMPANY -->
<ng-container *ngIf="data.type === 'company'">
    <div mat-dialog-title>
        <h3>{{ 'labour_costs.set_company' | translate }}</h3>
    </div>
    <div mat-dialog-content>
        <div fxLayoutGap="20px">
            <mat-form-field>
                <mat-label>{{ 'common.partner_id' | translate }}</mat-label>
                <input matInput [(ngModel)]="data.partnerId" (change)="customerChanged()" required>
            </mat-form-field>
            <mat-form-field readonly="true">
                <mat-label>{{ 'common.company' | translate }}</mat-label>
                <input matInput [(ngModel)]="data.company" (change)="customerChanged()" required>
            </mat-form-field>
        </div>
        <div fxLayout="row">
            <mat-form-field fxFlex readonly="true">
                <mat-label>{{ 'common.partner_name' | translate }}</mat-label>
                <input matInput [(ngModel)]="data.name" required readonly="true">
            </mat-form-field>
        </div>
    </div>
    <div mat-dialog-actions fxLayoutAlign="center center" fxLayoutGap="10px">
        <button mat-button mat-raised-button mat-dialog-close="" color="primary"><span translate>common.cancel</span></button>
        <button mat-button mat-raised-button mat-dialog-close="ok" color="accent" [disabled]="!data.company || !data.name"><span translate>common.ok</span></button>
    </div>
</ng-container>
