<div class="main">
    <div *ngIf="claim && actions && actions.can && actions.approvals" class="page-container" fxLayout="column" fxLayoutAlign="start start">

        <!-- CLAIM STATUS -->
        <div class="full" fxLayout="wrap row" fxLayoutGap="4px">
            <div fxLayout="column">
                <h1>{{ 'claims.warranty_claim' | translate }}</h1>
                <!-- GENERAL -->
                <div class="full" fxLayout="row">
                    <fieldset fxFlex>
                        <!--
                        <legend>{{ 'common.general' | translate }}</legend>
                        -->
                        <div class="full" fxLayout="column">
                            <div fxLayout="row wrap">
                                <mat-form-field fxFlex="150px" class="bold" readonly="true">
                                    <mat-label>{{ 'claims.claim_id' | translate }}</mat-label>
                                    <input matInput [ngModel]="claim.id" readonly="true">
                                </mat-form-field>
                                <mat-form-field fxFlex="150px" class="bold" readonly="true">
                                    <mat-label>{{ 'claims.repair_date' | translate }}</mat-label>
                                    <input matInput [ngModel]="claim.data.repairDate | date:'shortDate':undefined:localeService.locale" readonly="true">
                                </mat-form-field>
                                <mat-form-field fxFlex="150px" *ngIf="authUserScope === 'global'" class="limited-access" [attr.readonly]="!actions.can.edit">
                                    <mat-label>{{ 'claims.approval_process' | translate }}</mat-label>
                                    <mat-select [(value)]="claim.state.approvalProcess" [disabled]="!actions.can.edit" matTooltip="{{ 'claims.info_text.approval_process' | translate }}">
                                        <mat-option value="KOY">KOY</mat-option>
                                        <mat-option value="WUXI">WUXI</mat-option>
                                        <mat-option value="SUBSIDIARY">SUBSIDIARY</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field fxFlex="150px" *ngIf="authUserScope === 'global' || authUserScope === 'company'" class="limited-access" [attr.readonly]="!actions.can.edit">
                                    <mat-label>{{ 'claims.project' | translate }}</mat-label>
                                    <input matInput [(ngModel)]="claim.data.project" [readonly]="!actions.can.edit">
                                </mat-form-field>
                                <mat-form-field fxFlex="150px" *ngIf="authUserScope === 'global' || authUserScope === 'company'" class="limited-access" readonly="true">
                                    <mat-label>{{ 'common.closed' | translate }}</mat-label>
                                    <input matInput [ngModel]="claim.state.closed" readonly="true">
                                </mat-form-field>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
            <div fxFlex></div>

            <fieldset fxLayout="column" class="header-box">
                <legend>{{ 'common.status' | translate }}</legend>
                <mat-icon class="info" matTooltip="{{'claims.info_text.status' | translate}}" matTooltipClass="info-text">error_outline</mat-icon>
                <mat-form-field readonly="true">
                    <mat-label>{{ 'common.current' | translate }}</mat-label>
                    <input matInput [ngModel]="claim.state.currentState" readonly="true">
                </mat-form-field>
                <mat-form-field *ngIf="authUserScope === 'global' || authUserScope === 'company'" class="limited-access" readonly="true">
                    <mat-label>{{ 'common.internal' | translate }}</mat-label>
                    <input matInput [ngModel]="claim.state.internalState" readonly="true">
                </mat-form-field>
                <mat-form-field *ngIf="authUserScope === 'global' || authUserScope === 'company'" class="limited-access" readonly="true">
                    <mat-label>{{ 'claims.logistics' | translate }}</mat-label>
                    <input matInput [ngModel]="claim.state.logisticsState" readonly="true">
                </mat-form-field>
                <mat-form-field *ngIf="claim.data.invoice && (authUserScope === 'global' || authUserScope === 'company')" class="limited-access" [attr.readonly]="!actions.can.editOrderNumbers">
                    <mat-label>{{ 'claims.sales_order_number' | translate }}</mat-label>
                    <input matInput [(ngModel)]="claim.data.invoice.salesOrderNumber" [readonly]="!actions.can.editOrderNumbers">
                </mat-form-field>
            </fieldset>

            <fieldset *ngIf="claim.state.invoiceState !== '' || claim.state.internalInvoiceState !== '' || authUserRole === 'sys_admin'" fxLayout="column" class="header-box">
                <legend>{{ 'claims.invoice' | translate }}</legend>
                <mat-icon class="info" matTooltip="{{'claims.info_text.invoice' | translate}}" matTooltipClass="info-text">error_outline</mat-icon>
                <mat-form-field readonly="true">
                    <mat-label>{{ 'common.status' | translate }}</mat-label>
                    <input matInput [ngModel]="claim.state.invoiceState" readonly="true">
                </mat-form-field>
                <mat-form-field *ngIf="claim.data.invoice" [attr.readonly]="!actions.can.edit">
                    <mat-label>{{ 'common.reference' | translate }}</mat-label>
                    <input matInput [(ngModel)]="claim.data.invoice.reference" [readonly]="!actions.can.edit">
                </mat-form-field>
                <ng-container *ngIf="authUserScope === 'global' || authUserScope === 'company'">
                    <mat-form-field class="limited-access" readonly="true">
                        <mat-label>{{ 'common.internal' | translate }}</mat-label>
                        <input matInput [ngModel]="claim.state.internalInvoiceState" readonly="true">
                    </mat-form-field>
                    <mat-form-field *ngIf="claim.data.invoice" class="limited-access" [attr.readonly]="!actions.can.edit">
                        <mat-label>{{ 'common.reference' | translate }}</mat-label>
                        <input matInput [(ngModel)]="claim.data.invoice.internalReference" [readonly]="!actions.can.edit">
                    </mat-form-field>
                </ng-container>

            </fieldset>

        </div>
        <hr>

        <!-- PARTNER INFO-->
        <div class="full" fxLayout="row">
            <fieldset fxFlex>
                <legend>{{ 'common.partner' | translate }}</legend>
                <div class="full" fxLayout="column">
                    <div fxLayout="row" fxLayout.lt-sm="column">
                        <mat-form-field fxFlex="50%" readonly="true">
                            <mat-label>{{ 'common.partner_name' | translate }}</mat-label>
                            <input matInput [(ngModel)]="claim.partnerName" readonly="true">
                            <mat-hint *ngIf="authUserScope === 'global'" class="limited-access">{{claim.data.partner.groupDesc}}</mat-hint>
                        </mat-form-field>
                        <div fxLayout="row" fxFlex="25%">
                            <mat-form-field fxFlex="50%" [attr.readonly]="!actions.can.edit || authUserScope !== 'global' || id !== 'new'">
                                <mat-label>{{ 'common.partner_id' | translate }}</mat-label>
                                <input matInput [(ngModel)]="claim.partnerId" [readonly]="!actions.can.edit || authUserScope !== 'global' || id !== 'new'" (change)="getCustomerInfo()">
                            </mat-form-field>
                            <mat-form-field fxFlex="50%" [attr.readonly]="!actions.can.edit || authUserScope !== 'global' || id !== 'new'">
                                <mat-label>{{ 'common.company' | translate }}</mat-label>
                                <mat-select [(value)]="claim.company" [disabled]="!actions.can.edit || authUserScope !== 'global' || id !== 'new'" (selectionChange)="getCustomerInfo()">
                                    <mat-option *ngFor="let companyCode of companyCodes" [value]="companyCode">{{companyCode}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxFlex="25%">
                            <mat-form-field fxFlex="50%" readonly="true">
                                <mat-label>{{ 'common.currency' | translate }}</mat-label>
                                <input matInput [(ngModel)]="claim.data.currency.code" readonly="true">
                            </mat-form-field>
                            <mat-form-field fxFlex="50%" readonly="true">
                                <mat-label>{{ 'common.language' | translate }}</mat-label>
                                <input matInput [(ngModel)]="claim.data.language" readonly="true">
                            </mat-form-field>
                        </div>

                    </div>

                    <div fxLayout="row" fxLayout.lt-sm="column" *ngIf="authUserScope === 'global' || authUserScope === 'company'">
                        <div fxLayout="row">
                            <mat-form-field fxFlex="180px" *ngIf="authUserScope === 'global' || authUserScope === 'company'" readonly="true" class="limited-access">
                                <mat-label>{{ 'common.currency_rate' | translate }}</mat-label>
                                <input matInput [value]="claim.data.currency.currencyRate" readonly="true">
                                <span matSuffix>{{claim.data.currency.code}} = 1EUR</span>
                                <mat-hint matTooltip="Effective date, based on repair date">{{ claim.data.currency.effectiveDate | date:'short':undefined:localeService.locale }}</mat-hint>
                            </mat-form-field>
                            <mat-form-field fxFlex="180px" *ngIf="(authUserScope === 'global' || authUserScope === 'company') && claim.data.currency.internal" readonly="true" class="limited-access">
                                <mat-label>{{ 'common.currency_rate' | translate }} (internal)</mat-label>
                                <input matInput [value]="claim.data.currency.internal.currencyRate" readonly="true">
                                <span matSuffix>{{claim.data.currency.internal.code}} = 1EUR</span>
                                <mat-hint matTooltip="Effective date, based on repair date">{{ claim.data.currency.internal.effectiveDate | date:'short':undefined:localeService.locale }}</mat-hint>
                            </mat-form-field>
                            <mat-form-field fxFlex="180px" *ngIf="authUserScope === 'global' || authUserScope === 'company'" readonly="true" class="limited-access">
                                <mat-label>{{ 'claims.labour_cost_per_h' | translate }}</mat-label>
                                <input matInput [value]="claim.data.repair?.labourPrice" matTooltip="Repair costs, labour work price per hour" readonly="true">
                                <span matSuffix>{{claim.data.repair?.labourCurrency}}</span>
                            </mat-form-field>
                        </div>
                    </div>

                    <div fxLayout="row" *ngIf="authUserScope === 'global' || authUserScope === 'company'">
                        <button mat-button color="limited-access" class="smaller-button" matTooltip="Update partner info, currency rate and labour price" (click)="getCustomerInfo()" [disabled]="!actions.can.edit">
                            <mat-icon>refresh</mat-icon>
                            {{ 'common.update' | translate }}
                        </button>
                    </div>
                </div>
            </fieldset>
        </div>

        <!-- END CUSTOMER -->
        <div class="full" fxLayout="row">
            <fieldset fxFlex>
                <legend>{{ 'common.end_customer' | translate }}</legend>
                <div class="full" fxLayout="column">
                    <div fxLayout="row">
                        <mat-form-field fxFlex="300px" [floatLabel]="'always'" [attr.readonly]="!actions.can.edit">
                            <mat-label>{{ 'common.company_name' | translate }}</mat-label>
                            <input matInput [(ngModel)]="claim.data.customer.companyName" [readonly]="!actions.can.edit">
                        </mat-form-field>
                    </div>
                </div>
            </fieldset>
        </div>

        <!-- PRODUCT -->
        <div class="full" fxLayout="row">
            <fieldset fxFlex>
                <legend>{{ 'common.product' | translate }}</legend>
                <mat-icon class="info" matTooltip="{{'claims.info_text.product' | translate}}" matTooltipClass="info-text">error_outline</mat-icon>
                <div class="full" fxLayout="row">
                    <radio-group-form-field>
                        <mat-label style="color: rgba(0, 0, 0, 0.54); font-size: 70%;">{{ 'claims.claim_type' | translate }}</mat-label>
                        <mat-radio-group [(ngModel)]="claim.data.claimType" fxLayoutGap="10px" (change)="deleteProductRow(0)" [disabled]="!actions.can.edit">
                            <mat-radio-button value="SERIAL">{{ 'claims.claim_types.SERIAL' | translate }}</mat-radio-button>
                            <mat-radio-button value="PRODUCT">{{ 'claims.claim_types.PRODUCT' | translate }}</mat-radio-button>
                            <mat-radio-button value="SPARE">{{ 'claims.claim_types.SPARE' | translate }}</mat-radio-button>
                        </mat-radio-group>
                    </radio-group-form-field>
                </div>

                <div class="full" fxLayout="column" *ngFor="let product of claim.data.products; let i = index">

                    <div class="full" *ngIf="claim.data.claimType" fxLayout.gt-xs="row" fxLayout.lt-sm="column" [ngClass]="{ 'weak': product.faultyUnit === false }" fxLayoutAlign="start start" fxLayoutGap="10px">
                        <button mat-icon-button class="button-hover" color="accent" (click)="deleteProductRow(i)" [disabled]="!actions.can.edit">
                            <mat-icon>delete</mat-icon>
                        </button>
                        <div>
                            <mat-form-field fxFlex="150px" *ngIf="claim.data.claimType === 'SERIAL'" [attr.readonly]="!actions.can.edit">
                                <mat-label>{{ 'common.serial_number' | translate }}</mat-label>
                                <input matInput [(ngModel)]="product.serialNumber" (change)="getSerialInfo(product, product.serialNumber, product.secureId, i)" [required]="claim.data.claimType === 'SERIAL'" [readonly]="!actions.can.edit" maxlength=15>
                                <mat-hint class="warn" *ngIf="invalidSerialNumber">*{{ 'warning.invalid' | translate }}</mat-hint>
                                <mat-hint class="warn" *ngIf="notShippedSerial">*{{ 'warning.not_shipped' | translate }}</mat-hint>
                            </mat-form-field>
                            <ng-container *ngIf="authUserScope === 'global' && authUserRole === 'sys_admin'">
                                <mat-form-field fxFlex="100px" *ngIf="claim.data.claimType === 'SERIAL'" class="limited-access" [attr.readonly]="!actions.can.edit">
                                    <mat-label>{{ 'common.security_code' | translate }}</mat-label>
                                    <input matInput [(ngModel)]="product.secureId" (change)="getSerialInfo(product, product.serialNumber, product.secureId, i)" [required]="claim.data.claimType === 'SERIAL'" [readonly]="!actions.can.edit">
                                    <mat-hint class="warn" *ngIf="invalidSecureId">*{{ 'warning.invalid' | translate }}</mat-hint>
                                </mat-form-field>
                            </ng-container>
                        </div>
                        <div fxFlex.gt-xs="150px">
                            <mat-form-field [attr.readonly]="claim.data.claimType === 'SERIAL' || !actions.can.edit">
                                <mat-label>{{ 'common.product_number' | translate }}</mat-label>
                                <input matInput [(ngModel)]="product.productId" (change)="getProductInfo(product, product.productId, i)" [required]="true" [readonly]="claim.data.claimType === 'SERIAL' || !actions.can.edit" maxlength=30>
                                <mat-hint class="accent" *ngIf="product.productId && productsOnLoad[product.serialNumber] && product.productId !== productsOnLoad[product.serialNumber].productId">*{{ 'warning.changed' | translate }} {{productsOnLoad[product.serialNumber].productId}}</mat-hint>
                            </mat-form-field>
                        </div>
                        <div fxFlex.gt-xs="300px">
                            <mat-form-field readonly="true">
                                <mat-label>{{ 'common.product_name' | translate }}</mat-label>
                                <input matInput
                                    [value]="product.partDescriptions && claim.data.language && product.partDescriptions[claim.data.language] ? product.partDescriptions[claim.data.language] : product.productName || ''"
                                    [matTooltip]="product.productName"
                                    readonly="true">
                            </mat-form-field>
                        </div>
                        <div>
                            <ng-container *ngIf="authUserScope === 'global' && authUserRole === 'sys_admin'">
                                <mat-form-field fxFlex="100px" *ngIf="claim.data.claimType === 'SERIAL'" class="limited-access" [attr.readonly]="true">
                                    <mat-label>{{ 'common.manufacture_company' | translate }}</mat-label>
                                    <input matInput [(ngModel)]="product.manufactureCompany" [readonly]="true">
                                </mat-form-field>
                            </ng-container>
                        </div>
                        <div fxFlex></div>
                        <div>
                            <h3 *ngIf="stolenSerial" class="warn-stamp" >*{{ 'warning.stolen' | translate }}</h3>
                            <h3 *ngIf="authUserScope === 'global' && testSerieSerial" class="warn-stamp limited-access" matTooltip="{{ 'warning.test_serie' | translate }}">{{ testSerieSerial }}</h3>
                            <h3 *ngIf="authUserScope === 'global' && product.manufactureCompany && product.manufactureCompany !== '100'" class="warn-stamp limited-access" matTooltip="Manufactured in">{{ product.manufactureCompany }}</h3>
                        </div>
                    </div>

                    <div class="full" *ngIf="claim.data.claimType" fxLayout="row" fxLayout.lt-sm="column" [ngClass]="{ 'weak': product.faultyUnit === false }" fxLayoutAlign="start start" fxLayoutGap="10px">
                        <div fxFlex.gt-xs="38px"></div>

                        <div *ngIf="authUserScope === 'global' || authUserScope === 'company'" class="limited-access">
                            <war-datepicker
                                *ngIf="claim.data.claimType === 'SERIAL'"
                                [(ngModel)]="product.manufactureDate"
                                [label]="'common.date_of_manufacture' | translate"
                                [hint]="(productsOnLoad[product.serialNumber] && product.manufactureDate !== productsOnLoad[product.serialNumber].manufactureDate) ? '*'+translations.changed : null"
                                hintclass="accent"
                                [showPicker]="false"
                                [readonly]="true"></war-datepicker>
                        </div>

                        <div>
                            <war-datepicker
                                *ngIf="claim.data.claimType === 'SERIAL'"
                                [(ngModel)]="product.salesDate"
                                [label]="'common.sales_date' | translate"
                                [hint]="(product.serialNumber && product.productId && !product.salesDate) ? '*'+translations.notRegistered : (
                                        (productsOnLoad[product.serialNumber] && product.salesDate !== productsOnLoad[product.serialNumber].salesDate) ? '*'+translations.changed : null
                                    )"
                                [hintclass]="(product.serialNumber && product.productId && !product.salesDate) ? 'warn' : 'accent'"
                                [showPicker]="false"
                                [readonly]="true"></war-datepicker>
                        </div>

                        <div>
                            <war-datepicker
                                *ngIf="claim.data.claimType === 'SERIAL'"
                                [(ngModel)]="product.partnerRegistrationDate"
                                [label]="'common.registration_date' | translate"
                                [hint]="(product.serialNumber && product.productId && !product.partnerRegistrationDate) ? '*'+translations.notRegistered : (
                                        (productsOnLoad[product.serialNumber] && product.partnerRegistrationDate !== productsOnLoad[product.serialNumber].partnerRegistrationDate) ? '*'+translations.changed : null
                                    )"
                                [hintclass]="(product.serialNumber && product.productId && !product.partnerRegistrationDate) ? 'warn' : 'accent'"
                                [showPicker]="false"
                                [readonly]="true"></war-datepicker>
                        </div>

                        <div>
                            <war-datepicker
                                *ngIf="claim.data.claimType === 'SERIAL'"
                                [(ngModel)]="product.endCustomerRegistrationDate"
                                [label]="'common.customer_registration' | translate"
                                [hint]="(product.serialNumber && product.productId && !product.endCustomerRegistrationDate) ? '*'+translations.notRegistered : (
                                        (productsOnLoad[product.serialNumber] && product.endCustomerRegistrationDate !== productsOnLoad[product.serialNumber].endCustomerRegistrationDate) ? '*'+translations.changed : null
                                    )"
                                [hintclass]="(product.serialNumber && product.productId && !product.endCustomerRegistrationDate) ? 'warn' : 'accent'"
                                [showPicker]="false"
                                [readonly]="true"></war-datepicker>
                        </div>

                        <div fxFlex.gt-xs="120px">
                            <war-datepicker
                                *ngIf="claim.data.claimType === 'SERIAL'"
                                [(ngModel)]="product.warrantyExpireDate"
                                [label]="'common.warranty_expiry_date' | translate"
                                [hint]="(product.warrantyExpireDate && claim.data.repairDate && product.warrantyExpireDate < claim.data.repairDate) ? '*'+translations.expired : (
                                        (productsOnLoad[product.serialNumber] && product.warrantyExpireDate !== productsOnLoad[product.serialNumber].warrantyExpireDate) ? '*'+translations.changed: null
                                    )"
                                [hintclass]="(product.warrantyExpireDate && claim.data.repairDate && product.warrantyExpireDate < claim.data.repairDate) ? 'warn' : 'accent'"
                                [showPicker]="false"
                                [readonly]="true"></war-datepicker>
                        </div>
                    </div>

                    <div class="warn" *ngIf="notShippedSerial">
                        {{ 'claims.cannot_proceed_notice' | translate }}
                    </div>

                    <div>
                        <div fxFlex="48px" fxLayoutAlign="start center">
                            <mat-progress-spinner matSuffix *ngIf="productLoading" [mode]="'indeterminate'" [color]="'primary'" [diameter]="40" [strokeWidth]="2"></mat-progress-spinner>
                        </div>
                        <button *ngIf="claim.data.claimType === 'SERIAL' && (authUserScope === 'global' || authUserScope === 'company')" mat-button color="limited-access" class="smaller-button" matTooltip="Update product info from registry" (click)="getSerialInfo(product, product.serialNumber, product.secureId, i)" [disabled]="!actions.can.edit">
                            <mat-icon>refresh</mat-icon>
                            {{ 'common.update' | translate }}
                        </button>
                    </div>
                </div>
                <!-- PREVIOUS CLAIMS -->
                <fieldset *ngIf="previousClaims && previousClaims.length > 0" fxLayout="column" class="sub-fieldset">
                    <legend>{{ 'claims.previous_claims' | translate }}</legend>
                    <div class="full" fxLayout="row" *ngFor="let previousClaim of previousClaims" fxLayoutGap="20px">
                        <ng-container *ngIf="previousClaim.id != id">
                            <div fxFlex="100px">{{previousClaim.data.repairDate | date:'shortDate':undefined:localeService.locale}}</div>
                            <div *ngIf="authUserScope === 'global' || (authUserScope === 'company' && previousClaim.company === company) || (authUserScope === 'partner' && previousClaim.company === company && previousClaim.partnerId === partnerId)">
                                <a [routerLink]="['/claims', previousClaim.company, previousClaim.partnerId, previousClaim.id]">{{previousClaim.id}}</a>
                            </div>
                        </ng-container>
                    </div>
                </fieldset>
            </fieldset>
        </div>

        <!-- REPAIR INFO -->
        <div class="full" fxLayout="row" fxLayoutGap="20px">
            <fieldset fxFlex>
                <legend>{{ 'claims.repair_info' | translate }}</legend>
                <mat-icon class="info" matTooltip="{{'claims.info_text.repair_info' | translate}}" matTooltipClass="info-text">error_outline</mat-icon>
                <div class="full" fxLayout="column">
                    <div fxLayout="row">
                        <war-datepicker
                            [(ngModel)]="claim.data.repairDate"
                            [label]="'claims.repair_date' | translate"
                            [showPicker]="true"
                            [readonly]="!actions.can.edit"></war-datepicker>
                        <div fxFlex></div>
                        <div fxFlex.gt-xs="80px">
                            <mat-form-field>
                                <mat-label>{{ 'claims.faulty_code' | translate }}</mat-label>
                                <input matInput [(ngModel)]="claim.data.faultyDescription.code" [disabled]="true">
                            </mat-form-field>
                        </div>
                    </div>

                    <div>
                        <label class="extra-label">{{ 'claims.faulty_description' | translate }}</label>
                    </div>
                    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="10px">
                        <div fxFlex.gt-xs="24%">
                            <mat-form-field [attr.readonly]="!actions.can.edit">
                                <mat-label>{{ 'claims.faulty_descriptions.category' | translate }}</mat-label>
                                <mat-select [(value)]="claim.data.faultyDescription.category" (selectionChange)="updateFaultyDescription()" required [disabled]="!actions.can.edit">
                                    <mat-option *ngFor="let fdcategory of FaultyDescriptionCategories" [value]="fdcategory.key">{{ 'claims.faulty_descriptions.categories.' + fdcategory.key | translate }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxFlex.gt-xs="24%">
                            <mat-form-field [attr.readonly]="!actions.can.edit">
                                <mat-label>{{ 'claims.faulty_descriptions.type' | translate }}</mat-label>
                                <mat-select [(value)]="claim.data.faultyDescription.type" (selectionChange)="updateFaultyDescription()" required [disabled]="!actions.can.edit">
                                    <mat-option *ngFor="let fdtype of FaultyDescriptionTypes" [value]="fdtype.key">{{ 'claims.faulty_descriptions.types.' + fdtype.key | translate }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxFlex.gt-xs="24%">
                            <mat-form-field [attr.readonly]="!actions.can.edit">
                                <mat-label>{{ 'claims.faulty_descriptions.section' | translate }}</mat-label>
                                <mat-select [(value)]="claim.data.faultyDescription.section" (selectionChange)="updateFaultyDescription()" required [disabled]="!actions.can.edit">
                                    <mat-option *ngFor="let fdsection of FaultyDescriptionSections" [value]="fdsection.key">{{ 'claims.faulty_descriptions.sections.' + fdsection.key | translate }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxFlex.gt-xs="24%">
                            <mat-form-field [attr.readonly]="!actions.can.edit">
                                <mat-label>{{ 'claims.faulty_descriptions.reason' | translate }}</mat-label>
                                <mat-select [(value)]="claim.data.faultyDescription.reason" (selectionChange)="updateFaultyDescription()" required [disabled]="!actions.can.edit">
                                    <mat-option *ngFor="let fdreason of FaultyDescriptionReasons" [value]="fdreason.key">{{ 'claims.faulty_descriptions.reasons.' + fdreason.key | translate }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <mat-form-field [attr.readonly]="!actions.can.edit">
                        <mat-label>{{ 'claims.additional_information' | translate }}</mat-label>
                        <textarea matInput [(ngModel)]="claim.data.description" rows="4" [readonly]="!actions.can.edit"></textarea>
                    </mat-form-field>
                </div>
            </fieldset>
        </div>

        <!-- SPARE PARTS -->
        <div class="full" fxLayout="row">
            <fieldset fxFlex fxLayout="column">
                <legend>{{ 'claims.spare_parts' | translate }}</legend>
                <mat-icon class="info" matTooltip="{{'claims.info_text.spare_parts' | translate}}" matTooltipClass="info-text">error_outline</mat-icon>
                <div class="full" fxLayout="row" *ngFor="let sparePart of claim.data.parts; let i = index; first as isFirst; odd as isOdd; even as isEven">
                    <div fxLayout="column" *ngIf="sparePart" fxFlex>
                        <div class="full" fxLayout="row" fxLayout="wrap row" class="tighten-row" [class.odd-row]="isOdd" fxLayoutAlign="start start">
                            <div fxLayout="row" fxLayoutAlign="start start">
                                <button fxFlex="40px" mat-icon-button class="button-hover" color="accent" (click)="deleteSparePart(i)" [disabled]="!actions.can.edit"><mat-icon>delete</mat-icon></button>
                                <mat-form-field fxFlex="90px" [attr.readonly]="!actions.can.edit">
                                    <mat-label *ngIf="isFirst">{{ 'common.part_number' | translate }}</mat-label>
                                    <input matInput [(ngModel)]="sparePart.partNumber" (change)="updatePartInfo(sparePart, sparePart.partNumber, i)" [readonly]="!actions.can.edit">
                                    <mat-hint *ngIf="sparePart.duplicate" class="warn">Duplicate</mat-hint>
                                    <mat-hint *ngIf="sparePart.inactive" class="warn">Inactive</mat-hint>
                                </mat-form-field>
                                <mat-form-field fxFlex="70px" [attr.readonly]="!actions.can.edit" matTooltip="pcs = pieces, pck = package, m = meters">
                                    <mat-label *ngIf="isFirst">{{ 'common.pieces' | translate }}</mat-label>
                                    <input matInput [(ngModel)]="sparePart.quantity" type="number" step="1" min="0" (change)="updatePartInfo(sparePart, sparePart.partNumber, i)" [readonly]="!actions.can.edit">
                                    <span matSuffix>{{sparePart.salesUM}}</span>
                                </mat-form-field>
                                <mat-form-field fxFlex="60px" readonly="true">
                                    <mat-label *ngIf="isFirst">{{ 'claims.identifier' | translate }}</mat-label>
                                    <input matInput [(ngModel)]="sparePart.faultPartCode" readonly="true">
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxFlex fxLayoutAlign="start start">
                                <mat-form-field fxFlex readonly="true">
                                    <mat-label *ngIf="isFirst">{{ 'common.name' | translate }}</mat-label>
                                    <input matInput
                                        [value]="sparePart.partDescriptions && claim.data.language && sparePart.partDescriptions[claim.data.language] ? sparePart.partDescriptions[claim.data.language] : sparePart.partName"
                                        [matTooltip]="sparePart.partName"
                                        readonly="true">
                                </mat-form-field>
                            </div>

                            <div *ngIf="sparePart.customPrice" class="custom-price-icon">
                                <mat-icon matTooltip="Custom price set manually. (Click to reset)" class="accent" (click)="removeCustomPrice(sparePart, i)">priority_high</mat-icon>
                            </div>
                            <div fxLayout="wrap row" fxLayoutAlign="end start" [ngClass]="{'custom-price': sparePart.customPrice}">
                                <div *ngIf="showPrices" fxLayout="row" fxLayoutAlign="end start">
                                    <mat-form-field fxShow.lt-lg="false" fxFlex="115px" class="limited-access" *ngIf="authUserScope === 'global'" [readonly]="authUserRole !== 'sys_admin'">
                                        <mat-label *ngIf="isFirst">{{ 'claims.transfer_list_price' | translate }}</mat-label>
                                        <input matInput [(ngModel)]="sparePart.transferPrice" type="number" min="0" step="0.01" (change)="calculateSparePartRow(sparePart, true)" class="right-align" [readonly]="authUserRole !== 'sys_admin'">
                                        <span matSuffix>{{claim.data.currency.internal?.code || claim.data.currency.code}}</span>
                                    </mat-form-field>
                                    <mat-form-field fxShow.lt-lg="false" fxFlex="65px" class="limited-access" *ngIf="authUserScope === 'global'" [readonly]="authUserRole !== 'sys_admin'">
                                        <mat-label *ngIf="isFirst">{{ 'claims.discount' | translate }}</mat-label>
                                        <input matInput [(ngModel)]="sparePart.transferDiscount" type="number" min="0" step="1" (change)="calculateSparePartRow(sparePart, true)" class="right-align" [readonly]="authUserRole !== 'sys_admin'">
                                        <span matSuffix disabled="true">%</span>
                                    </mat-form-field>
                                    <mat-form-field fxFlex="115px" class="limited-access" *ngIf="authUserScope === 'global' || authUserScope === 'company'" readonly="true">
                                        <mat-label *ngIf="isFirst">{{ 'claims.transfer_net_price' | translate }}</mat-label>
                                        <input matInput [(ngModel)]="sparePart.transferTotal" type="number" min="0" step="0.01" (change)="calculateSparePartRow(sparePart, true)" class="right-align" readonly="true">
                                        <span matSuffix>{{claim.data.currency.internal?.code || claim.data.currency.code}}</span>
                                    </mat-form-field>
                                </div>

                                <div *ngIf="showPrices" fxLayout="row" fxLayoutAlign="end start">
                                    <mat-form-field fxShow.lt-lg="false" fxFlex="115px" [readonly]="authUserRole !== 'sys_admin'">
                                        <mat-label *ngIf="isFirst">{{ 'claims.price' | translate }}</mat-label>
                                        <input matInput [(ngModel)]="sparePart.price" type="number" min="0" step="0.01" (change)="calculateSparePartRow(sparePart, true)" class="right-align" [readonly]="authUserRole !== 'sys_admin'">
                                        <span matSuffix>{{claim.data.currency.code}}</span>
                                    </mat-form-field>
                                    <mat-form-field fxShow.lt-lg="false" fxFlex="65px" [readonly]="authUserRole !== 'sys_admin'">
                                        <mat-label *ngIf="isFirst">{{ 'claims.discount' | translate }}</mat-label>
                                        <input matInput [(ngModel)]="sparePart.discount" type="number" min="0" step="1" (change)="calculateSparePartRow(sparePart, true)" class="right-align" [readonly]="authUserRole !== 'sys_admin'">
                                        <span matSuffix disabled="true">%</span>
                                    </mat-form-field>
                                    <div fxFlex></div>
                                    <mat-form-field fxFlex="115px" readonly="true">
                                        <mat-label *ngIf="isFirst">{{ 'claims.price_total' | translate }}</mat-label>
                                        <input matInput [value]="sparePart.priceTotal" type="number" class="right-align" readonly="true">
                                        <span matSuffix disabled="true">{{claim.data.currency.code}}</span>
                                    </mat-form-field>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="full" fxLayout="column">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="48px">
                            <mat-progress-spinner matSuffix *ngIf="sparePartsLoading" [mode]="'indeterminate'" [color]="'primary'" [diameter]="40" [strokeWidth]="2"></mat-progress-spinner>
                        </div>
                        <button mat-button color="accent" class="add-spare-part-button" (click)="addSparePart()" [disabled]="!actions.can.edit">
                            <mat-icon class="kemppi-symbols">ř</mat-icon>
                            {{ 'claims.add_spare_part' | translate }}
                        </button>
                        <div fxFlex></div>
                        <div *ngIf="showPrices">
                            <mat-form-field fxFlex="160px" *ngIf="authUserScope === 'global'" class="total-price limited-access">
                                <mat-label>{{ 'claims.total_transfer_list_price' | translate }}</mat-label>
                                <input matInput [(ngModel)]="claim.data.costs.partTransferCostsWithoutDiscount" type="number" class="right-align" readonly="true">
                                <span matSuffix disabled="true">{{claim.data.currency.internal?.code || claim.data.currency.code}}</span>
                            </mat-form-field>

                            <mat-form-field fxFlex="160px" *ngIf="authUserScope === 'global' || authUserScope === 'company'" class="total-price limited-access">
                                <mat-label>{{ 'claims.total_transfer_net_price' | translate }}</mat-label>
                                <input matInput [(ngModel)]="claim.data.costs.partTransferCosts" type="number" class="right-align" readonly="true">
                                <span matSuffix disabled="true">{{claim.data.currency.internal?.code || claim.data.currency.code}}</span>
                            </mat-form-field>

                            <mat-form-field fxFlex="160px" class="total-price">
                                <mat-label>{{ 'claims.sub_total' | translate }}</mat-label>
                                <input matInput [(ngModel)]="claim.data.costs.partCosts" type="number" class="right-align" readonly="true">
                                <span matSuffix disabled="true">{{claim.data.currency.code}}</span>
                                <mat-hint *ngIf="claim.data.costs.currency != 'EUR'">{{claim.data.costs.partCostsEUR}} EUR</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center">
                    <div fxFlex="48px"></div>
                    <button *ngIf="authUserScope === 'global' || authUserScope === 'company'" mat-button color="limited-access" class="smaller-button" matTooltip="Update spare part costs" (click)="updateAllSpareParts()" [disabled]="!actions.can.edit || !claim.data.parts || claim.data.parts.length === 0">
                        <mat-icon>refresh</mat-icon>
                        {{ 'common.update' | translate }}
                    </button>
                </div>

            </fieldset>
        </div>

        <!-- COSTS -->
        <div class="full" fxLayout="row">
            <fieldset fxFlex fxLayout="column">
                <legend>{{ 'claims.costs' | translate }}</legend>
                <mat-icon class="info" matTooltip="{{'claims.info_text.costs' | translate}}" matTooltipClass="info-text">error_outline</mat-icon>
                <fieldset class="sub-fieldset">
                    <legend>{{ 'claims.repair_costs' | translate }}</legend>
                    <div class="full" fxLayout="row" *ngFor="let repair of claim.data.repairs; let i = index; first as isFirst; odd as isOdd; even as isEven">
                        <div fxLayout="column" *ngIf="repair" fxFlex>
                            <div fxLayout="row" class="tighten-row" [class.odd-row]="isOdd" fxLayoutAlign="start center">
                                <button fxFlex="40px" mat-icon-button class="button-hover" color="accent" (click)="deleteRepair(i)" [disabled]="!actions.can.edit"><mat-icon>delete</mat-icon></button>
                                <mat-form-field fxFlex="100px" readonly="true">
                                    <mat-label *ngIf="isFirst">{{ 'common.part_number' | translate }}</mat-label>
                                    <input matInput [(ngModel)]="repair.partNumber" readonly="true">
                                </mat-form-field>
                                <mat-form-field fxFlex="300px" readonly="true">
                                    <mat-label *ngIf="isFirst">{{ 'common.name' | translate }}</mat-label>
                                    <input matInput
                                        [value]="repair.partDescriptions && claim.data.language && repair.partDescriptions[claim.data.language] ? repair.partDescriptions[claim.data.language] : repair.partName"
                                        [matTooltip]="repair.partName"
                                        readonly="true">
                                </mat-form-field>
                                <mat-form-field fxFlex="200px" [attr.readonly]="!actions.can.edit">
                                    <mat-label *ngIf="isFirst">{{ 'common.description' | translate }}</mat-label>
                                    <input matInput [(ngModel)]="repair.description" [readonly]="!actions.can.edit">
                                </mat-form-field>
                                <div fxFlex></div>
                                <mat-form-field fxFlex="80px" [attr.readonly]="repair.partNumber !== constants.ADDEDREPAIR">
                                    <mat-label *ngIf="isFirst">{{ 'common.minutes' | translate }}</mat-label>
                                    <input matInput [(ngModel)]="repair.minutes" (change)="updateRepairCosts(); calculateRepairCosts()" type="number" min="0" step="1" class="right-align" [readonly]="repair.partNumber !== constants.ADDEDREPAIR">
                                    <span matSuffix disabled="true">{{ 'common.min' | translate }}</span>
                                </mat-form-field>
                                <mat-form-field *ngIf="showPrices" fxFlex="80px" readonly="true">
                                    <mat-label *ngIf="isFirst">{{ 'claims.price' | translate }} / h</mat-label>
                                    <input matInput [(ngModel)]="repair.pricePerHour" type="number" min="0" step="0.01" class="right-align" readonly="true">
                                    <span matSuffix>{{claim.data.currency.code}}</span>
                                </mat-form-field>
                                <div fxFlex></div>
                                <mat-form-field *ngIf="showPrices" fxFlex="120px" [attr.readonly]="true">
                                    <mat-label *ngIf="isFirst">{{ 'claims.repair_costs_total' | translate }}</mat-label>
                                    <input matInput [(ngModel)]="repair.repairCost" (change)="calculateRepairCosts()" type="number" min="0" step="0.01" class="right-align" [readonly]="true">
                                    <span matSuffix>{{claim.data.currency.code}}</span>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="48px">
                            <mat-progress-spinner matSuffix *ngIf="sparePartsLoading" [mode]="'indeterminate'" [color]="'primary'" [diameter]="40" [strokeWidth]="2"></mat-progress-spinner>
                        </div>
                        <button mat-button color="accent" class="add-spare-part-button" (click)="addRepairCost()" [disabled]="!actions.can.edit">
                            <mat-icon class="kemppi-symbols">ř</mat-icon>
                            {{ 'claims.add_additional_repair_cost' | translate }}
                        </button>
                        <div fxFlex></div>

                        <mat-form-field fxFlex="160px" class="total-price">
                            <mat-label>{{ 'claims.total_repair_time' | translate }}</mat-label>
                            <input matInput [value]="claim.data.repair?.time" class="right-align" readonly="true">
                        </mat-form-field>

                        <mat-form-field *ngIf="showPrices" fxFlex="160px" class="total-price">
                            <mat-label>{{ 'claims.sub_total' | translate }}</mat-label>
                            <input matInput [(ngModel)]="claim.data.costs.repairCosts" type="number" min="0" step="0.01" class="right-align" readonly="true">
                            <span matSuffix>{{claim.data.currency.code}}</span>
                            <mat-hint *ngIf="claim.data.costs.currency !== 'EUR'">{{claim.data.costs.repairCostsEUR}} EUR</mat-hint>
                        </mat-form-field>
                    </div>
                </fieldset>

                <fieldset class="sub-fieldset">
                    <legend>{{ 'claims.other_costs' | translate }}</legend>
                    <div fxLayout="row" *ngFor="let other of claim.data.others; let i = index; first as isFirst">
                        <div fxLayout="column" *ngIf="other" fxFlex>
                            <div fxLayout="row" class="tighten-row" fxLayoutAlign="start center">
                                <button fxFlex="40px" mat-icon-button class="button-hover" color="accent" (click)="deleteOtherCost(i)" [disabled]="!actions.can.edit"><mat-icon>delete</mat-icon></button>
                                <mat-form-field class="field-xl">
                                    <mat-label *ngIf="isFirst">{{ 'common.name' | translate }}</mat-label>
                                    <input matInput [(ngModel)]="other.description">
                                </mat-form-field>
                                <div fxFlex></div>
                                <mat-form-field *ngIf="showPrices" fxFlex="120px">
                                    <mat-label *ngIf="isFirst">{{ 'claims.other_cost' | translate }}</mat-label>
                                    <input matInput [(ngModel)]="other.otherCost" (change)="calculateOtherCosts()" type="number" min="0" step="0.01" class="right-align">
                                    <span matSuffix>{{claim.data.currency.code}}</span>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div fxFlex="48px"></div>
                        <button mat-button color="accent" class="add-spare-part-button" (click)="addOtherCost()" [disabled]="!actions.can.edit">
                            <mat-icon class="kemppi-symbols">ř</mat-icon>
                            {{ 'claims.add_other_cost' | translate }}
                        </button>
                        <div fxFlex></div>
                        <mat-form-field *ngIf="showPrices" fxFlex="160px" class="total-price">
                            <mat-label>{{ 'claims.sub_total' | translate }}</mat-label>
                            <input matInput [(ngModel)]="claim.data.costs.otherCosts" type="number" min="0" step="0.01" class="right-align" readonly="true">
                            <span matSuffix>{{claim.data.currency.code}}</span>
                            <mat-hint *ngIf="claim.data.costs.currency !== 'EUR'">{{claim.data.costs.otherCostsEUR}} EUR</mat-hint>
                        </mat-form-field>
                    </div>
                </fieldset>

                <fieldset class="sub-fieldset" *ngIf="showPrices">
                    <legend>{{ 'claims.total_costs' | translate }}</legend>
                    <div fxLayout="row">
                        <div fxFlex></div>
                        <mat-form-field fxFlex="160px" *ngIf="authUserScope === 'global' || authUserScope === 'company'" class="total-price limited-access">
                            <mat-label>{{ 'claims.total_transfer_net_price' | translate }}</mat-label>
                            <input matInput [(ngModel)]="claim.data.costs.totalTransferCosts" type="number" min="0" step="0.01" class="right-align" readonly="true">
                            <span matSuffix>{{claim.data.currency.internal?.code || claim.data.currency.code}}</span>
                            <mat-hint *ngIf="claim.data.costs.currency !== 'EUR'">{{claim.data.costs.totalTransferCostsEUR}} EUR</mat-hint>
                        </mat-form-field>
                        <mat-form-field fxFlex="160px" class="total-price">
                            <mat-label>{{ 'claims.total_costs' | translate }}</mat-label>
                            <input matInput [(ngModel)]="claim.data.costs.totalCosts" type="number" min="0" step="0.01" class="right-align" readonly="true">
                            <span matSuffix>{{claim.data.currency.code}}</span>
                            <mat-hint *ngIf="claim.data.costs.currency !== 'EUR'">{{claim.data.costs.totalCostsEUR}} EUR</mat-hint>
                        </mat-form-field>
                    </div>
                </fieldset>

            </fieldset>
        </div>

        <!-- SAFETY TEST -->
        <div class="full" fxLayout="row">
            <fieldset fxFlex>
                <legend>{{ 'claims.safety_test' | translate }}</legend>
                <mat-icon class="info" matTooltip="{{'claims.info_text.safety_test' | translate}}" matTooltipClass="info-text">error_outline</mat-icon>
                <small *ngIf="!claim.id" class="weak"><i>{{ 'claims.claim_must_be_saved_notice' | translate }}</i></small>
                <div class="full" fxLayout="column" *ngIf="claim.id">
                    <div class="full" style="padding-top:16px;" fxLayout="row">
                        <div>
                            <mat-checkbox [(ngModel)]="claim.data.safetyTest" (change)="addSafetyTestCost()" [disabled]="!actions.can.edit">{{ 'claims.safety_test_done_description' | translate }}</mat-checkbox>
                        </div>
                        <div fxFlex></div>
                        <div class="hide-in-print">
                            <a href="/assets/Safety_test_report_EN.docx">
                                <button mat-button color="primary" class="smaller-button">{{ 'claims.safety_test_download_report_layout' | translate }}</button>
                            </a>
                        </div>
                    </div>
                    <small *ngIf="claim.data.safetyTest && claim.data.attachments.length === 0" class="warn"><i>{{ 'claims.safety_test_attachment_is_required' | translate }}</i></small>
                    <div>
                        <button *ngIf="canAddSafety" mat-button color="accent" class="add-spare-part-button" (click)="addSafetyTest()">
                            <mat-icon class="kemppi-symbols">ř</mat-icon>
                            {{ 'claims.add_safety_test' | translate }}
                        </button>
                    </div>
                </div>
            </fieldset>
        </div>

        <!-- ATTACHMENTS -->
        <div class="full" fxLayout="row">
            <fieldset fxFlex>
                <legend>{{ 'claims.attachments' | translate }}</legend>
                <div class="full" fxLayout="column">
                    <div fxLayout="row" fxLayoutGap="20px">
                        <div *ngFor="let claimAttachment of claim.data.attachments; let i = index">
                            <div fxLayout="row">
                                <button mat-icon-button class="button-hover" color="accent" (click)="deleteAttachmentRow(i)" [disabled]="!actions.can.edit"><mat-icon>delete</mat-icon></button>
                                <button class="button-hover show-in-print" (click)="openAttachment(i)">
                                    <div class="file-icon" [attr.data-type]="claimAttachment.fileExtension"></div>
                                    <div>
                                        {{ claimAttachment.filename }}<br>
                                        <span class="weak">{{ (claimAttachment.size / 1024) | number:0 }} KB</span>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <small *ngIf="!claim.id" class="weak"><i>{{ 'claims.claim_must_be_saved_notice' | translate }}</i></small>
                    <input id="add-attachment" placeholder="Kuva" type="file" ng2FileSelect [hidden]="true" (change)="selectedFile($event)" [disabled]="!claim.id || (!actions.can.edit && !canAddSafety)">
                    <fieldset class="sub-fieldset hide-in-print" *ngIf="claim.id && actions.can.edit">
                        <legend>{{ 'claims.add_attachment' | translate }}</legend>

                        <div fxLayoutAlign="start center">
                            <label class="mat-button mat-accent" color="accent" mat-button for="add-attachment" fxLayoutAlign="start center">
                                <mat-icon class="kemppi-symbols">ř</mat-icon>
                                {{ 'claims.select_file' | translate }}
                            </label>

                            <ng-container *ngIf="attachment">
                                <div>
                                    <div *ngIf="attachment.preview" [style.background-image]="'url(' + attachment.content + ')'" class="image-preview"></div>
                                    <div *ngIf="!attachment.preview && attachment.file && attachment.content" fxLayout="column" class="image-preview" fxLayoutAlign="center center">
                                        <div class="file-icon" [attr.data-type]="attachment.fileExtension"></div>
                                    </div>
                                    <div *ngIf="attachment.file && attachment.content"><small class="weak"><i>OK - (save attachment and claim)</i></small></div>
                                </div>

                                <button *ngIf="attachment.file && attachment.content" mat-raised-button color="accent" (click)="saveAttachment()">Save attachment</button>
                            </ng-container>
                        </div>
                    </fieldset>
                </div>
            </fieldset>
        </div>

        <!-- SHIPPING -->
        <div *ngIf="claim.data.shipping && isDistributorClaim" class="full" fxLayout="row">
            <fieldset fxFlex>
                <legend>{{ 'claims.shipping_details' | translate }}</legend>
                <mat-form-field [attr.readonly]="!actions.can.edit">
                    <mat-label>{{ 'claims.shipping_comment' | translate }}</mat-label>
                    <textarea matInput [(ngModel)]="claim.data.shipping.comment" rows="2" [readonly]="!actions.can.edit"></textarea>
                </mat-form-field>
            </fieldset>
        </div>

        <!-- COMMENTS -->
        <div class="full" fxLayout="row">
            <fieldset fxFlex>
                <legend>{{ 'claims.comments' | translate }}</legend>
                <small *ngIf="!claim.id" class="weak"><i>{{ 'claims.claim_must_be_saved_notice' | translate }}</i></small>
                <ng-container *ngIf="claim.id">
                    <div class="full" fxLayout="column" *ngFor="let comment of claim.data.comments; let i = index">
                        <div fxLayout="row">
                            <button *ngIf="authUserScope === 'global' && actions.can.edit" mat-icon-button class="button-hover" color="accent" (click)="removeComment(i)"><mat-icon>delete</mat-icon></button>
                            <div fxLayout="column" fxFlex>
                                <div class="weak">
                                    <b *ngIf="comment.user">{{comment.user.name}}</b>
                                    -
                                    <i *ngIf="comment.timestamp">{{comment.timestamp | date:'short':undefined:localeService.locale}}</i>
                                </div>
                                <div class="line-breaks">{{ comment.comment }}</div>
                            </div>
                        </div>
                    </div>
                    <div fxLayout="row" *ngIf="actions.can.save" class="hide-in-print">
                        <mat-form-field fxFlex>
                            <mat-label>{{ 'claims.comment' | translate }}</mat-label>
                            <input matInput [(ngModel)]="comment" (keydown.enter)="addComment()">
                        </mat-form-field>
                        <div>
                            <button mat-button color="accent" (click)="addComment()">{{ 'claims.add_comment' | translate }}</button>
                        </div>
                    </div>
                </ng-container>
            </fieldset>
        </div>


<ng-container *ngIf="claim.id && authUserScope !== 'partner'">
        <!-- INTERNAL COMMENTS -->
        <div class="full" fxLayout="row">
            <fieldset fxFlex class="limited-access">
                <legend>{{ 'claims.internal_comments' | translate }}</legend>
                <div class="full" fxLayout="column" *ngFor="let comment of claim.comments; let i = index">
                    <div fxLayout="row">
                        <button *ngIf="authUserScope === 'global' && actions.can.edit" mat-icon-button class="button-hover" color="accent" (click)="removeInternalComment(i)"><mat-icon>delete</mat-icon></button>
                        <div fxLayout="column" fxFlex>
                            <div class="weak">
                                <b *ngIf="comment.user">{{comment.user.name}}</b>
                                -
                                <i *ngIf="comment.timestamp">{{comment.timestamp | date:'short':undefined:localeService.locale}}</i>
                            </div>
                            <div class="line-breaks">{{ comment.comment }}</div>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" *ngIf="actions.can.save" class="hide-in-print">
                    <mat-form-field fxFlex>
                        <mat-label>{{ 'claims.comment' | translate }}</mat-label>
                        <input matInput [(ngModel)]="internalComment" (keydown.enter)="addInternalComment()">
                    </mat-form-field>
                    <div>
                        <button mat-button color="limited-access" (click)="addInternalComment()">{{ 'claims.add_comment' | translate }}</button>
                    </div>
                </div>
            </fieldset>
        </div>
</ng-container>

<ng-container *ngIf="claim.id && (authUserScope === 'company' || authUserScope === 'global')">
        <!-- APPROVALS -->
        <div class="full" fxLayout="row">
            <fieldset fxFlex class="limited-access">
                <legend>{{ 'claims.approvals' | translate }}</legend>
                <div class="full" fxLayout="row" fxLayoutAlign="start start">
                    <war-approval
                        [(ngModel)]="claim.approvals.dealer"
                        topic="Dealer"
                        [editEnabled]="editApprovalsEnabled"
                        (addEvent)="editApprovals('dealer')"
                        (removeEvent)="removeApprovals('dealer')"
                    ></war-approval>
                    <war-approval
                        [(ngModel)]="claim.approvals.distributor"
                        topic="Distributor"
                        [editEnabled]="editApprovalsEnabled"
                        (addEvent)="editApprovals('distributor')"
                        (removeEvent)="removeApprovals('distributor')"
                    ></war-approval>
                    <war-approval
                        [(ngModel)]="claim.approvals.inProgress"
                        topic="In progress"
                        [editEnabled]="editApprovalsEnabled"
                        (addEvent)="editApprovals('inProgress')"
                        (removeEvent)="removeApprovals('inProgress')"
                    ></war-approval>
                    <war-approval
                        [(ngModel)]="claim.approvals.subsidiary"
                        topic="Subsidiary"
                        [editEnabled]="editApprovalsEnabled"
                        (addEvent)="editApprovals('subsidiary')"
                        (removeEvent)="removeApprovals('subsidiary')"
                    ></war-approval>
                    <war-approval
                        [(ngModel)]="claim.approvals.returned"
                        topic="Returned"
                        [editEnabled]="editApprovalsEnabled"
                        (addEvent)="editApprovals('returned')"
                        (removeEvent)="removeApprovals('returned')"
                    ></war-approval>
                    <war-approval
                        [(ngModel)]="claim.approvals.rejected"
                        topic="Rejected"
                        [editEnabled]="editApprovalsEnabled"
                        (addEvent)="editApprovals('rejected')"
                        (removeEvent)="removeApprovals('rejected')"
                    ></war-approval>
                    <war-approval
                        [(ngModel)]="claim.approvals.accepted"
                        topic="Accepted"
                        [editEnabled]="editApprovalsEnabled"
                        (addEvent)="editApprovals('accepted')"
                        (removeEvent)="removeApprovals('accepted')"
                    ></war-approval>
                    <war-approval
                        [(ngModel)]="claim.approvals.inQueue"
                        topic="In queue"
                        [editEnabled]="editApprovalsEnabled"
                        (addEvent)="editApprovals('inQueue')"
                        (removeEvent)="removeApprovals('inQueue')"
                    ></war-approval>
                    <war-approval
                        [(ngModel)]="claim.approvals.logistics"
                        topic="Logistics"
                        [editEnabled]="editApprovalsEnabled"
                        (addEvent)="editApprovals('logistics')"
                        (removeEvent)="removeApprovals('logistics')"
                    ></war-approval>
                    <war-approval
                        [(ngModel)]="claim.approvals.invoiced"
                        topic="Invoiced"
                        [editEnabled]="editApprovalsEnabled"
                        (addEvent)="editApprovals('invoiced')"
                        (removeEvent)="removeApprovals('invoiced')"
                    ></war-approval>
                    <war-approval
                        [(ngModel)]="claim.approvals.refunded"
                        topic="Refunded"
                        [editEnabled]="editApprovalsEnabled"
                        (addEvent)="editApprovals('refunded')"
                        (removeEvent)="removeApprovals('refunded')"
                    ></war-approval>
                    <war-approval
                        [(ngModel)]="claim.approvals.invoicedInternal"
                        topic="Invoiced internal"
                        [editEnabled]="editApprovalsEnabled"
                        (addEvent)="editApprovals('invoicedInternal')"
                        (removeEvent)="removeApprovals('invoicedInternal')"
                    ></war-approval>
                    <war-approval
                        [(ngModel)]="claim.approvals.refundedInternal"
                        topic="Refunded internal"
                        [editEnabled]="editApprovalsEnabled"
                        (addEvent)="editApprovals('refundedInternal')"
                        (removeEvent)="removeApprovals('refundedInternal')"
                    ></war-approval>
                    <war-approval
                        [(ngModel)]="claim.state.closed"
                        topic="Closed"
                        [editEnabled]="editApprovalsEnabled"
                        (addEvent)="editApprovals('closed')"
                        (removeEvent)="removeApprovals('closed')"
                    ></war-approval>
                </div>
            </fieldset>
        </div>
</ng-container>

<ng-container *ngIf="claim.id && authUserScope === 'global'">
        <div class="full" fxLayout="row">
            <fieldset fxFlex class="limited-access">
                <legend>{{ 'common.meta' | translate }}</legend>
                <div fxLayout="row" fxLayoutAlign="start start">
                    <div *ngIf="claim.version.created" class="user-stamp">
                        <b>Schema</b><br>
                        {{claim.schema?.type}}<br>
                        {{claim.schema?.version}}
                    </div>
                    <div *ngIf="claim.version.created" class="user-stamp">
                        <b>Created</b><br>
                        {{claim.version.created.timestamp | date:'short':undefined:localeService.locale}}<br>
                        {{claim.version.created.user?.name}}<br>
                        {{claim.version.created.user?.email}}
                    </div>
                    <div *ngIf="claim.version.modified" class="user-stamp">
                        <b>Modified</b><br>
                        {{claim.version.modified.timestamp | date:'short':undefined:localeService.locale}}<br>
                        {{claim.version.modified.user?.name}}<br>
                        {{claim.version.modified.user?.email}}
                    </div>
                </div>
            </fieldset>
        </div>
</ng-container>

<ng-container *ngIf="claim.id && (authUserScope === 'company' || authUserScope === 'global')">
    <div class="limited-access"><small><i>* Blue colored sections, fields and buttons are visible to Kemppi users only.</i></small></div>
</ng-container>

    </div>
</div>

<!-- DEBUG -->
<div *ngIf="showAdminTools" class="admin-tools" fxLayout="column">
    <div *ngIf="showJSON" fxFlex="572px">
        <pre>{{ claim | json }}</pre>
    </div>
    <div fxFlex="128px" fxLayoutAlign="start center" style="padding: 10px;" fxLayout="column">
        <div>
            <small class="warn">Admin tools</small>
        </div>
        <div fxLayoutGap="20px" fxLayout="row">
            <div fxLayout="column">
                <mat-checkbox [(ngModel)]="editApprovalsEnabled" matTooltip="Claim will be instantly save after changes to approvals. Hope you know what you are doing (EKKJ)">Edit approvals</mat-checkbox>
                <mat-checkbox [(ngModel)]="validateEnabled" matTooltip="If disabled, claim validations will be skipped on save">Validate?</mat-checkbox>
            </div>
            <div fxLayout="column">
                <mat-checkbox [(ngModel)]="showJSON" matTooltip="Show claim raw JSON data">Show JSON</mat-checkbox>
                <mat-checkbox [(ngModel)]="showPrices">Show prices</mat-checkbox>
            </div>
            <div fxLayout="column">
                <mat-checkbox [(ngModel)]="actions.can.edit" matTooltip="Fields editing enabled">Can edit?</mat-checkbox>
                <mat-checkbox [(ngModel)]="actions.can.save" matTooltip="Claim saving enabled">Can save?</mat-checkbox>
            </div>
            <mat-form-field>
                <mat-label>authUserScope</mat-label>
                <mat-select [(ngModel)]="authUserScope">
                    <mat-option value="partner">partner</mat-option>
                    <mat-option value="company">company</mat-option>
                    <mat-option value="global">global</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>

<!-- ACTIONBAR BUTTONS -->
<ks-actionbar
        *ngIf="claim && actions && actions.can && actions.approvals"
        [hasCancel]="true"
        [hasSave]="true"
        [isSaving]="state.busy"
        [canSave]="!state.busy && claim && actions.can.save && (authUserScope !== 'partner' || (authUserScope === 'partner' && (claim.state.currentState !== 'ACCEPTED' && claim.state.currentState !== 'REJECTED')))"
        (save)="save()"
        (cancel)="cancel()"
>
    <div fxLayout="row" fxFlex>
        <!-- For debuggin, should be removed -->
        <ng-container *ngIf="authUserScope === 'global' && authUserRole === 'sys_admin'">
            <button mat-button color="warn" (click)="toggleAdmin()" fxShow.lt-sm="false">
                <mat-icon class="kemppi-symbols">s</mat-icon>
            </button>
        </ng-container>

        <ng-container *ngIf="claim && canDelete">
            <button mat-button mat-raised-button mat-icon-button color="warn" (click)="openDeleteDialog()">
                <mat-icon>delete</mat-icon>
            </button>
        </ng-container>

        <div>
            <button *ngIf="id !== 'new'" mat-button mat-raised-button mat-icon-button color="primary" (click)="print()" matTooltip="Print">
                <mat-icon>print</mat-icon>
            </button>
            <button *ngIf="authUserScope === 'global' || authUserScope === 'company'" mat-button mat-raised-button mat-icon-button color="primary"
                (click)="authUserScope = 'partner'" matTooltip="Show claim in partner mode. (Hides internal fields)" fxShow.lt-sm="false">
                <mat-icon>remove_red_eye</mat-icon>
            </button>
        </div>

        <button mat-button mat-raised-button color="primary" (click)="toggleActions()" fxShow.gt-sm="false">
            <mat-icon class="kemppi-symbols">ą</mat-icon> {{ 'claims.actions.actions' | translate }}
        </button>

        <ng-container *ngIf="claim && claim.id && actions && actions.approvals">
            <div fxFlex class="actions" [ngClass]="{'actions-visible': showActions}" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="center center">
                <div fxFlex></div>
                <!-- PARTNER -->
                <button *ngIf="actions.approvals['partner.add']" mat-button mat-raised-button color="accent" (click)="addApprovals('partner')">
                    <mat-icon class="kemppi-symbols">÷</mat-icon> {{ 'claims.actions.send_to_approval' | translate }}
                </button>
                <button *ngIf="actions.approvals['partner.remove']" mat-button mat-raised-button color="primary" (click)="removeApprovals('partner')">
                    <mat-icon class="kemppi-symbols">³</mat-icon> {{ 'claims.actions.cancel_and_modify' | translate }}
                </button>

                <!-- IN PROGRESS -->
                <button *ngIf="actions.approvals['inProgress.add']" mat-button mat-raised-button color="accent" (click)="addApprovals('inProgress')">
                    <mat-icon class="kemppi-symbols">ó</mat-icon> {{ 'claims.actions.in_progress' | translate }} <small *ngIf="showAdminTools">(SUB)</small>
                </button>

                <!-- RETURN -->
                <button *ngIf="actions.approvals['returned.add']" mat-button mat-raised-button color="primary" (click)="addApprovals('returned')">
                    <mat-icon class="kemppi-symbols">³</mat-icon> {{ 'claims.actions.return' | translate }}
                </button>
                <!-- REJECT -->
                <button *ngIf="actions.approvals['rejected.add']" mat-button mat-raised-button color="warn" (click)="addApprovals('rejected')">
                    <mat-icon class="kemppi-symbols">®</mat-icon> {{ 'claims.actions.reject' | translate }}
                </button>

                <!-- SUBSIDIARY -->
                <button *ngIf="actions.approvals['subsidiary.add']" mat-button mat-raised-button color="accent" (click)="openApprovalsConfirmDialog('subsidiary')">
                    <mat-icon class="kemppi-symbols">ó</mat-icon> {{ 'claims.actions.accept' | translate }} <small *ngIf="showAdminTools">(SUB)</small>
                </button>
                <button *ngIf="actions.approvals['subsidiary.remove']" mat-button mat-raised-button color="primary" (click)="removeApprovals('subsidiary')">
                    <mat-icon class="kemppi-symbols">³</mat-icon> {{ 'claims.actions.cancel_acception' | translate }} <small *ngIf="showAdminTools">(SUB)</small>
                </button>

                <!-- ACCEPTED -->
                <button *ngIf="actions.approvals['accepted.add']" mat-button mat-raised-button color="accent" (click)="openApprovalsConfirmDialog('accepted')">
                    <mat-icon class="kemppi-symbols">ó</mat-icon> {{ 'claims.actions.accept' | translate }} <small *ngIf="showAdminTools">(KOY)</small>
                </button>
                <button *ngIf="actions.approvals['accepted.remove']" mat-button mat-raised-button color="primary" (click)="removeApprovals('accepted')">
                    <mat-icon class="kemppi-symbols">³</mat-icon> {{ 'claims.actions.cancel_acception' | translate }} <small *ngIf="showAdminTools">(KOY)</small>
                </button>

                <!-- LOGISTICS -->
                <button *ngIf="actions.approvals['inQueue.add']" mat-button mat-raised-button color="accent" (click)="addApprovals('inQueue')">
                    <mat-icon class="kemppi-symbols">÷</mat-icon> {{ 'claims.actions.send_to_logistics' | translate }}
                </button>
                <button *ngIf="actions.approvals['inQueue.remove']" mat-button mat-raised-button color="primary" (click)="removeApprovals('inQueue')">
                    <mat-icon class="kemppi-symbols">³</mat-icon> {{ 'claims.actions.cancel_logistics' | translate }}
                </button>
                <button *ngIf="actions.approvals['logistics.add']" mat-button mat-raised-button color="accent" (click)="openInvoiceDialog('logistics')">
                    <mat-icon class="kemppi-symbols">ó</mat-icon> {{ 'claims.actions.handled_by_logistics' | translate }}
                </button>
                <button *ngIf="actions.approvals['logistics.remove']" mat-button mat-raised-button color="primary" (click)="removeApprovals('logistics')">
                    <mat-icon class="kemppi-symbols">³</mat-icon> {{ 'claims.actions.cancel_logistics' | translate }}
                </button>

                <div fxFlex fxLayoutAlign="end center">
                    <!-- INVOICE | REFUND -->
                    <button *ngIf="actions.approvals['invoiced.add']" mat-button color="accent" (click)="openInvoiceDialog('invoiced')">
                        <mat-icon class="kemppi-symbols">ó</mat-icon> {{ 'claims.actions.invoice' | translate }}
                    </button>
                    <button *ngIf="actions.approvals['refunded.add']" mat-button color="accent" (click)="addApprovals('refunded')">
                        <mat-icon class="kemppi-symbols">ó</mat-icon> {{ 'claims.actions.refund' | translate }}
                    </button>
                    <button *ngIf="actions.approvals['invoicedInternal.add']" mat-button color="accent" (click)="openInvoiceDialog('invoicedInternal')">
                        <mat-icon class="kemppi-symbols">ó</mat-icon> {{ 'claims.actions.invoice_internal' | translate }}
                    </button>
                    <button *ngIf="actions.approvals['refundedInternal.add']" mat-button color="accent" (click)="addApprovals('refundedInternal')">
                        <mat-icon class="kemppi-symbols">ó</mat-icon> {{ 'claims.actions.refund_internal' | translate }}
                    </button>

                    <!-- REOPEN -->
                    <button *ngIf="actions.approvals['rejected.remove']" mat-button mat-raised-button color="warn" (click)="removeApprovals('rejected')">
                        <mat-icon class="kemppi-symbols">³</mat-icon> {{ 'claims.actions.reopen' | translate }}
                    </button>
                </div>
            </div>
        </ng-container>

        <!-- cancel and save buttons are added -->
    </div>
</ks-actionbar>
