<div class="full" fxLayout="column" *ngIf="!stateService.state.isPrinting">
    <ks-toolbar
        [appInfo]="appInfo"
        [hasMenu]="stateService.state.hasMenu"
        [hasBack]="stateService.state.hasBack"
        [file]="stateService.state.file"
        (back)="back()">
    </ks-toolbar>
    <div class="hidden show-in-print">
        <img src="assets/images/Kemppi_logo_transparent.png" class="logo">
    </div>
    <!--<pq-document-page fxFlex></pq-document-page>-->
    <div *ngIf="stateService.state.loading" fxLayout="row" style="margin-top: 200px;">
        <mat-progress-spinner [mode]="'indeterminate'" [color]="'primary'"
                            style="margin: 0 auto;"
                            *ngIf="stateService.state.loading"
                            fxLayoutAlign="center center">
        </mat-progress-spinner>
    </div>
    <div *ngIf="!stateService.state.loading" id="ks-content-area">
        <router-outlet></router-outlet>
    </div>
</div>

<!-- For printing, don't show toolbar or help side menu -->
<div fxFlex class="full print-layout" fxLayout="column" *ngIf="stateService.state.isPrinting">
    <div id="ks-content-area">
        <router-outlet></router-outlet>
    </div>
</div>
