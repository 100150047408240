import { FaultyDescription } from './claim';

/**
* Faulty description coding v.1.0
* 1. Faulty coding version number
* 2. Category
* 3. Type
* 4. Section
* 5. Reason
*/

export const FaultyDescriptionVersion = '1';

export interface FaultySelection {
    id: string;
    key: string;
    name: string;
}

export const FaultyDescriptionCategories: Array<FaultySelection> = [
    { id: '1', key: 'functional-failure', name: 'Functional failure' },
    { id: '2', key: 'manufacturing', name: 'Manufacturing' },
    { id: 'P', key: 'project', name: 'Project' },
];

export const FaultyDescriptionTypes: Array<FaultySelection> = [
    { id: '1', key: 'electrical', name: 'Electrical' },
    { id: '2', key: 'mechanical', name: 'Mechanical' },
    { id: '3', key: 'software-or-firmware', name: 'Software/Firmware' },
    { id: '4', key: 'manufacturing-defect', name: 'Manufacturing defect' },
    { id: '5', key: 'material-defect', name: 'Material defect' },
    { id: 'N', key: 'not-applicable', name: 'N/A' },
];

export const FaultyDescriptionSections: Array<FaultySelection> = [
    { id: '1', key: 'primary-power-section', name: 'Pri. power section' },
    { id: '2', key: 'secondary-power-section', name: 'Sec. power section' },
    { id: '3', key: 'control-section', name: 'Control section (ps, feeder, cooler)' },
    { id: '4', key: 'user-interface', name: 'User interface (panel, etc.)' },
    { id: '5', key: 'wire-feeding-line', name: 'Wire feeding line' },
    { id: '6', key: 'water-circulation', name: 'Water circulation' },
    { id: '7', key: 'gas-flow', name: 'Gas flow' },
    { id: '8', key: 'air-flow', name: 'Air flow' },
    { id: '9', key: 'transformer-or-choke', name: 'Transformer/choke' },
    { id: 'A', key: 'frame-or-chasis-part', name: 'Frame/chassis part' },
    { id: 'B', key: 'accessories', name: 'Accessories' },
    { id: 'C', key: 'other', name: 'Other' },
    { id: 'N', key: 'not-applicable', name: 'N/A' },
];

export const FaultyDescriptionReasons: Array<FaultySelection> = [
    { id: '1', key: 'short-circuit', name: 'Short circuit' },
    { id: '2', key: 'open-circuit', name: 'Open circuit' },
    { id: '3', key: 'burned-or-visual-damage', name: 'Burned' },
    { id: '4', key: 'leakage', name: 'Leakage' },
    { id: '5', key: 'incorrect-operation', name: 'Incorrect operation' },
    { id: '6', key: 'connection-failure', name: 'Connection failure' },
    { id: '9', key: 'blocked', name: 'Blocked' },
    { id: 'H', key: 'mechanical-damage', name: 'Mechanical damage' },
    // { id: 'A', key: 'stucked', name: 'Stucked' },
    { id: 'A', key: 'jammed', name: 'Jammed' },
    { id: 'B', key: 'update', name: 'Update' },
    { id: 'C', key: 'software-bug', name: 'Software bug' },
    { id: '7', key: 'no-clear-reason-found', name: 'No clear reason found' },
    { id: 'D', key: 'assembly-error', name: 'Assembly error' },
    { id: 'E', key: 'soldering-failure', name: 'Soldering failure' },
    { id: 'F', key: 'wrong-or-missing-component', name: 'Wrong/missing component' },
    { id: 'G', key: 'material-failure', name: 'Material failure' },
    { id: '8', key: 'other', name: 'Other' },
    { id: 'N', key: 'not-applicable', name: 'N/A' },
];

export const FaultyDescriptionAllowedMappings = {
    'types': {
        'functional-failure': [
            'electrical',
            'mechanical',
            'software-or-firmware'
        ],
        'manufacturing': [
            'manufacturing-defect',
            'material-defect'
        ],
        'project': [
            'not-applicable'
        ]
    },
    'sections': {
        'electrical': [
            'primary-power-section',
            'secondary-power-section',
            'control-section',
            'user-interface',
            'wire-feeding-line',
            'water-circulation',
            'gas-flow',
            'air-flow',
            'transformer-or-choke',
            'other'
        ],
        'mechanical': [
            'primary-power-section',
            'secondary-power-section',
            'control-section',
            'user-interface',
            'wire-feeding-line',
            'water-circulation',
            'gas-flow',
            'air-flow',
            'frame-or-chasis-part',
            'accessories',
            'other'
        ],
        'software-or-firmware': [
            'control-section',
            'user-interface',
            'other'
        ],
        'manufacturing-defect': [
            'primary-power-section',
            'secondary-power-section',
            'control-section',
            'user-interface',
            'wire-feeding-line',
            'water-circulation',
            'gas-flow',
            'air-flow',
            'transformer-or-choke',
            'frame-or-chasis-part',
            'other'
        ],
        'material-defect': [
            'primary-power-section',
            'secondary-power-section',
            'control-section',
            'user-interface',
            'wire-feeding-line',
            'water-circulation',
            'gas-flow',
            'air-flow',
            'transformer-or-choke',
            'frame-or-chasis-part',
            'other'
        ],
        'not-applicable': [
            'not-applicable'
        ]
    },
    'reasons': {
        'electrical': [
            'short-circuit',
            'open-circuit',
            'burned-or-visual-damage',
            'incorrect-operation',
            'connection-failure',
            'no-clear-reason-found',
            'other'
        ],
        'mechanical': [
            'leakage',
            'incorrect-operation',
            'blocked',
            'mechanical-damage',
            // 'stucked',
            'jammed',
            'no-clear-reason-found',
            'other'
        ],
        'software-or-firmware': [
            'incorrect-operation',
            'update',
            'software-bug',
            'no-clear-reason-found',
            'other'
        ],
        'manufacturing-defect': [
            'assembly-error',
            'soldering-failure',
            'wrong-or-missing-component',
            'other'
        ],
        'material-defect': [
            'material-failure',
            'other'
        ],
        'not-applicable': [
            'not-applicable'
        ]
    }
};

export function getFaultyDescriptionCode(faultyDescription) {
    return (faultyDescription.version || FaultyDescriptionVersion)
    + ((FaultyDescriptionCategories.find(function( obj ) { return obj.key === faultyDescription.category; }) || { id: '0' }).id)
    + ((FaultyDescriptionTypes.find(function( obj )      { return obj.key === faultyDescription.type; }) || { id: '0' }).id)
    + ((FaultyDescriptionSections.find(function( obj )   { return obj.key === faultyDescription.section; }) || { id: '0' }).id)
    + ((FaultyDescriptionReasons.find(function( obj )    { return obj.key === faultyDescription.reason; }) || { id: '0' }).id);
}

/**
 * Legacy convert plain code '10000' to faulty descrtion object
 */
export function getFaultyDescriptionObjectByCode(code) {
    const output: FaultyDescription = {
        code: '10000',
        version: FaultyDescriptionVersion,
        category: null,
        type: null,
        section: null,
        reason: null
    };
    const codeParts = Array.from(code);

    // Version 1
    if (codeParts.length === 5 && codeParts[0] === '1') {
        output.category =   (FaultyDescriptionCategories.find(function( obj )   { return obj.id === codeParts[1]; }) || { key: null }).key;
        output.type =       (FaultyDescriptionTypes.find(function( obj )        { return obj.id === codeParts[2]; }) || { key: null }).key;
        output.section =    (FaultyDescriptionSections.find(function( obj )     { return obj.id === codeParts[3]; }) || { key: null }).key;
        output.reason =     (FaultyDescriptionReasons.find(function( obj )      { return obj.id === codeParts[4]; }) || { key: null }).key;
    }

    output.code = getFaultyDescriptionCode(output);

    return output;
}

/**
 * Update faulty description
 */
export function updateFaultyDescriptionObject(faultyDescription) {
    console.log('updateFaultyDescription', faultyDescription);
    const output: FaultyDescription = {
        code: '10000',
        version: FaultyDescriptionVersion,
        category: null,
        type: null,
        section: null,
        reason: null
    };
    if (!faultyDescription) {
        return output;
    }
    if (typeof faultyDescription === 'string') {
        // Legacy convert plain code '10000' to faulty descrtion object
        return getFaultyDescriptionObjectByCode(faultyDescription);
    } else if (faultyDescription.version === '1') {
        output.category =   faultyDescription.category;
        output.type =       faultyDescription.type;
        output.section =    faultyDescription.section;
        output.reason =     faultyDescription.reason;
        output.code = getFaultyDescriptionCode(output);
        return output;
    }
    return output;
}
