<div class="main">
    <div class="page-container" fxLayout="column" fxLayoutAlign="start start">
        <div class="full" fxLayout="row">
            <h1 fxFlex>Settings</h1>
        </div>

        <ng-container *ngIf="permissions">
            <h2>Permissions</h2>
            <div class="full" fxLayout="row">
                <table class="permission-table">
                    <thead>
                        <tr>
                            <th></th>
                            <ng-container *ngFor="let role of permissionRoles">
                                <th>
                                    <div *ngIf="permissions[role]">{{role}}</div>
                                </th>
                            </ng-container>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngIf="permissionScopes">
                            <tr>
                                <td>Scope</td>
                                <ng-container *ngFor="let role of permissionRoles">
                                    <td>
                                        <mat-select *ngIf="permissions[role]" [(ngModel)]="permissions[role].scope">
                                            <mat-option *ngFor="let scope of permissionScopes" [value]="scope">{{scope}}</mat-option>
                                        </mat-select>
                                    </td>
                                </ng-container>
                            </tr>
                        </ng-container>
                        <hr>
                        <tr>
                            <td><b>Modules</b></td>
                        </tr>
                        <ng-container *ngFor="let module of permissionModules">
                            <tr>
                                <td>{{module}}</td>
                                <ng-container *ngFor="let role of permissionRoles">
                                    <td>
                                        <mat-checkbox *ngIf="permissions[role]" [(ngModel)]="permissions[role].modules[module]"></mat-checkbox>
                                    </td>
                                </ng-container>
                            </tr>
                        </ng-container>
                        <hr>
                        <tr>
                            <td><b>Actions</b></td>
                        </tr>
                        <ng-container *ngFor="let action of permissionActions">

                            <tr>
                                <td>{{action}}</td>
                                <ng-container *ngFor="let role of permissionRoles">
                                    <td>
                                        <mat-checkbox *ngIf="permissions[role]" [(ngModel)]="permissions[role].actions[action]"></mat-checkbox>
                                    </td>
                                </ng-container>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </ng-container>
    </div>

</div>


<ks-actionbar [hasCancel]="true" [hasSave]="true" [isSaving]="state.busy" [canSave]="!state.busy" (save)="save()"
    (cancel)="cancel()">
    <div fxFlex></div>
</ks-actionbar>