import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';

import { AppConfigService } from '../../../../services/app-config.service';
import { AuthService } from '../../../../services/auth.service';
import { StateService } from '../../../../services/state.service';
import { ClaimsService } from 'src/app/services/claims.service';

import { Claim } from 'src/app/models/claim';

@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
    public loading: boolean;

    public authUserScope: string;
    public allowedModules: any;

    public publicUse: boolean;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private snackBar: MatSnackBar,
        private appConfigService: AppConfigService,
        private authService: AuthService,
        private claimsService: ClaimsService,
        public stateService: StateService
    ) {
        this.loading = true;
    }

    ngOnInit() {
        const environment = this.appConfigService.config.environment;

        setTimeout(async () => {
            this.stateService.state.hasMenu = true;

            // Set menu allowedModules by user.permissions.mudules
            if (this.authService.userProfile) {
                this.allowedModules = this.authService.userProfile.permissions.modules;
                this.authUserScope = this.authService.userProfile.permissions.scope;
            }

            // Disable public use before publish date 3.6.2019
            // Disable public use on prod environment before publish date 3.6.2019
            this.publicUse = true;
            if (environment === 'prod' && new Date() < new Date('2019-06-03 00:00:00.000Z')) {
                this.publicUse = false;
            }

            this.loading = false;
        });
    }

    /**
     * Open warranty claim with claim id
     * We have to first get the actual claim and check it's company and partnerId
     */
    openWarrantyClaim(claimId) {
        this.claimsService.getClaimById(claimId.trim()).subscribe((claim: Claim) => {
            if (claim) {
                this.router.navigate([`/claims/${claim.company}/${claim.partnerId}/${claim.id}`]);
            } else {
                this.showError({ message: 'Claim not found' });
            }
        }, (error) => {
            console.log(error);
            this.showError(error);
        });
    }

    /**
     * Show error snackbar notification
     */
    showError(error) {
        const message = _.get(error, 'error.errorMessage') || _.get(error, 'message');
        const type = _.get(error, 'type') || _.get(error, 'error.error.caused_by.caused_by.type') || _.get(error, 'error.error.caused_by.type') || '';
        const reason = _.get(error, 'reason') || _.get(error, 'error.error.caused_by.caused_by.reason') || _.get(error, 'error.error.caused_by.reason') || '';

        this.snackBar.open(message + '\n\n' + type + '\n' + reason, 'OK', {
            duration: 5000,
            panelClass: ['war-snackbar', 'war-snackbar-error-message']
        });
    }

}
