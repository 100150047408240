<!--
<button mat-raised-button [color]="dashboard === 'overall' ? 'accent' : 'primary'" (click)="changeDashboard('overall')">Overall</button>
<button mat-raised-button [color]="dashboard === 'faulty' ? 'accent' : 'primary'" (click)="changeDashboard('faulty')">Faulty %</button>
-->
<ng-container *ngIf="dashboard === 'overall'">
    <iframe src="http://localhost:5601/app/kibana#/dashboard/Warranty-dashboard?embed=true&_g=(refreshInterval%3A(display%3AOff%2Cpause%3A!f%2Cvalue%3A0)%2Ctime%3A(from%3Anow-90d%2Cinterval%3A'1d'%2Cmode%3Aquick%2Ctimezone%3AEurope%2FHelsinki%2Cto%3Anow))" height="100%" width="100%"></iframe>
</ng-container>
<ng-container *ngIf="dashboard === 'faulty'">

</ng-container>
