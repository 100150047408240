import { Component, forwardRef, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material';
import * as _ from 'lodash';
import * as moment from 'moment';

import { LocaleService } from 'src/app/services/locale.service';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => WarApprovalComponent),
    multi: true
};

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'war-approval',
    templateUrl: './war-approval.component.html',
    styleUrls: ['./war-approval.component.scss'],
    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class WarApprovalComponent implements ControlValueAccessor {

    private innerValue: string;
    private innnerDisabled: boolean;

    @Input() topic = null;
    @Input() editEnabled = false;
    @Output() addEvent = new EventEmitter();
    @Output() removeEvent = new EventEmitter();

    constructor(
        private localeService: LocaleService
    ) {
    }

    get value(): any {
        return this.innerValue;
    }

    set value(val: any) {
        if (val !== this.innerValue) {
            this.innerValue = val;
            this.onChange(val);
        }
    }

    onChange: any = () => { };

    onTouched: any = () => { };

    registerOnTouched(fn) {
        this.onTouched = fn;
    }

    registerOnChange(fn) {
        this.onChange = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.innnerDisabled = isDisabled;
    }

    writeValue(val: any): void {
        if (val !== this.innerValue) {
            this.innerValue = val;
        }
    }

    add() {
        this.addEvent.emit();
    }

    remove() {
        this.removeEvent.emit();
    }

}
