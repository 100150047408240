import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { StateService } from '../../../../services/state.service';

@Component({
    selector: 'app-dashboard-page',
    templateUrl: './dashboard-page.component.html',
    styleUrls: ['./dashboard-page.component.scss']
})
export class ReportingDashboardPageComponent implements OnInit {

    id: string;
    state: any;

    dashboard: string;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public stateService: StateService,
    ) {
        this.stateService.state.hasBack = false;

        this.state = {
            busy: false
        };

        this.dashboard = 'overall';
    }

    ngOnInit() {
        setTimeout(() => {
            this.id = this.activatedRoute.snapshot.params['id'];
            this.stateService.state.file = this.id;
        });
    }

    save() {

    }
    cancel() {
    }

    changeDashboard(dashboard) {
        this.dashboard = dashboard;
    }
}
