import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CustomersService, ICustomerInfo } from 'src/app/services/customers.service';

import { CompanyCodes } from 'src/app/models/company';
import { CurrencyCodes } from 'src/app/models/currency';

@Component({
    selector: 'app-cost-dialog',
    templateUrl: './cost-dialog.component.html',
    styleUrls: ['./cost-dialog.component.scss']
})
export class CostDialogComponent implements OnInit {

    public companyCodes: Array<string>;
    public currencyCodes: Array<string>;

    constructor(
        public dialogRef: MatDialogRef<CostDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private customersService: CustomersService,
    ) {
        this.companyCodes = CompanyCodes;
        this.currencyCodes = CurrencyCodes;
    }

    ngOnInit(): void {
    }

    async customerChanged() {
        const customerInfo = await this.getCustomerInfo(this.data.company, this.data.partnerId);
        if (customerInfo) {
            console.log('customerInfo', customerInfo);
            this.data.name = customerInfo.name;
            this.data.groupCode = customerInfo.groupCode; // E, B12, B2, B3, CC, A
            this.data.groupDesc = customerInfo.groupDesc; // INTERCOMPANY, DISTRIBUTOR, KEMPPI DEALER, DEALER, SERVICE, DIRECT CUSTOMER
            this.data.currency = customerInfo.currencyCode;
        } else {
            this.data.name = null;
            this.data.currency = null;
        }
    }

    /**
     * Get Customer info
     */
    async getCustomerInfo(company, partnerId) {
        return await this.customersService.getCustomerInfo(company, partnerId).toPromise() as ICustomerInfo;
    }



}
