<div mat-dialog-title>
    <h3 *ngIf="!data.type || data.type === 'invoiced' || data.type === 'invoicedInternal'">{{ 'claims.invoice' | translate }}</h3>
    <h3 *ngIf="data.type === 'logistics'">{{ 'claims.actions.handled_by_logistics' | translate }}</h3>
</div>
<div mat-dialog-content fxLayout="column">
    <mat-form-field>
        <mat-label *ngIf="!data.type || data.type === 'invoiced' || data.type === 'invoicedInternal'">{{ 'claims.invoice_reference_number' | translate }}</mat-label>
        <mat-label *ngIf="data.type === 'logistics'">{{ 'claims.sales_order_number' | translate }}</mat-label>
        <input matInput [(ngModel)]="data.invoiceReference">
    </mat-form-field>
</div>
<div mat-dialog-actions fxLayoutAlign="center center" fxLayoutGap="10px">
    <button mat-button mat-raised-button mat-dialog-close="" color="primary"><span translate>common.cancel</span></button>
    <button mat-button mat-raised-button mat-dialog-close="ok" color="accent"><span translate>common.ok</span></button>
</div>