<div class="print-page-wrapper" fxLayoutAlign="center center" *ngIf="!loading && serialNumber">
    <div class="print-page-container" fxLayout="column">
        <div>
            <img class="banner" src="assets/images/warranty_certificate_bg.png" />
        </div>
        <div fxLayoutAlign="center start">
            <h1 class="topic" translate>REGISTRATION.CERTIFICATE.WarrantyCertificate</h1>
        </div>

        <div class="page-contents" fxLayout="column" fxFlex>

            <div fxLayout="row">
                <div fxFlex="25" class="topic"><span translate>REGISTRATION.CERTIFICATE.Customer</span>:</div>
                <div fxFlex class="value">{{customer}}</div>
            </div>
            <!--
            <div fxLayout="row">
                <div fxFlex="25" class="topic"><span translate>REGISTRATION.CERTIFICATE.Address</span>:</div>
                <div fxFlex class="value">{{address}}</div>
            </div>
            -->
            <div fxLayout="row">
                <div fxFlex="25" class="topic"><span translate>REGISTRATION.PurchasedFrom</span>:</div>
                <div fxFlex class="value">{{dealer}}</div>
            </div>
            <div fxLayout="row">
                <div fxFlex="25" class="topic"><span translate>REGISTRATION.CERTIFICATE.DateOfPurchase</span>:</div>
                <div fxFlex="20" class="value">{{purchaseDate}}</div>
                <div fxFlex="30" class="topic"><span translate>REGISTRATION.CERTIFICATE.WarrantyExpiryDate</span>:</div>
                <div fxFlex="25" class="value">{{warrantyExpireDate}}</div>
            </div>

            <div fxFlex="80px"></div>

            <div fxLayout="row">
                <div fxFlex="25" fxLayout="column">
                    <div class="topic"><span translate>REGISTRATION.CERTIFICATE.SerialNumber</span>:</div>
                    <div class="value">{{serialNumber}}</div>
                </div>
                <div fxFlex="45" fxLayout="column">
                    <div class="topic"><span translate>REGISTRATION.CERTIFICATE.Product</span>:</div>
                    <div class="value">{{product}}</div>
                </div>
                <div fxFlex="30" fxLayout="column">
                    <div class="topic"><span translate>REGISTRATION.CERTIFICATE.ProductCode</span>:</div>
                    <div class="value">{{productCode}}</div>
                </div>
            </div>

            <div fxFlex="80px"></div>

            <div fxLayout="row">
                <div fxFlex="25" class="topic"><span translate>REGISTRATION.CERTIFICATE.Manufacturer</span>:</div>
                <div fxFlex class="value">Kemppi Oy</div>
            </div>

            <div fxFlex="80px"></div>

            <div fxLayoutAlign="end end">
                <img class="logo" src="assets/images/warranty_certificate_kemppi.png" />
            </div>

        </div>

    </div>

    <div class="print-button">
        <button mat-raised-button color="primary"
            (click)="print()">
            <mat-icon class="kemppi-symbols">ě</mat-icon>
            <span translate>REGISTRATION.BUTTON.Print</span>
        </button>
    </div>
</div>

<div class="print-page-wrapper" fxLayoutAlign="center center" *ngIf="!loading && !serialNumber">
    <div class="print-page-container" fxLayout="column">
        <div fxLayoutAlign="center start">
            <h1>Error fetching warranty certificate data</h1>
        </div>
    </div>
</div>