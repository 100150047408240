import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

export interface SheetData {
    name: string;
    json: any;
}

@Injectable()
export class ExcelService {

    constructor() { }

    /**
     * Create multiple sheet excel file
     */
    public exportAsExcelFileMultisheet(sheets: Array<SheetData>, excelFileName: string) {
        const book = {
            Sheets: {},
            SheetNames: []
        };
        for (const sheet of sheets) {
            console.log('generate worksheet', sheet.name, sheet.json);
            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(sheet.json);
            book.Sheets[sheet.name] = worksheet;
            book.SheetNames.push(sheet.name);
        }
        const workbook: XLSX.WorkBook = book;
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    /**
     * Create single sheet excel file
     */
    public exportAsExcelFile(json: any[], excelFileName: string): void {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        console.log('worksheet', worksheet);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().toISOString().slice(0, 10) + EXCEL_EXTENSION);
    }

}
