import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as _ from 'lodash';

import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';

declare var require: any;

// Warranty claims service

@Injectable()
export class ClaimsService {

    BACKENDURL: string;

    constructor(
        private appConfigService: AppConfigService,
        private authService: AuthService,
        private http: HttpClient
    ) {
        this.BACKENDURL = this.appConfigService.config.warrantyBackendUrl;
    }

    get token() {
        return this.authService.accessToken;
    }

    getClaim(company, partnerId, claimId) {
        console.log('getClaim', company, partnerId, claimId);
        return this.http.get(`${this.BACKENDURL}/claims/company/${company}/partner/${partnerId}/${claimId}`, { headers: this._getHeaders() });
    }

    getClaimById(claimId) {
        console.log('getClaimById', claimId);
        return this.http.get(`${this.BACKENDURL}/claims/${claimId}`, { headers: this._getHeaders() });
    }

    saveClaim(company, partnerId, claimId, claim, action = null, type = null) {
        if (!claimId) {
            // Create new (POST)
            return this.http.post(`${this.BACKENDURL}/claims/company/${company}/partner/${partnerId}/`, JSON.stringify(claim), { headers: this._getHeaders() });
        } else {
            // Update existing one (PUT)
            if (action && type) {
                // Add / Remove approvals
                return this.http.put(
                    `${this.BACKENDURL}/claims/company/${company}/partner/${partnerId}/${claimId}?action=${action}&type=${type}`,
                    JSON.stringify(claim),
                    { headers: this._getHeaders() }
                );
            } else {
                // Save
                return this.http.put(`${this.BACKENDURL}/claims/company/${company}/partner/${partnerId}/${claimId}`, JSON.stringify(claim), { headers: this._getHeaders() });
            }
        }
    }

    deleteClaim(company, partnerId, claimId) {
        return this.http.delete(`${this.BACKENDURL}/claims/company/${company}/partner/${partnerId}/${claimId}`, { headers: this._getHeaders() });
    }

    getAttachment(company, partnerId, claimId, attachmentId) {
        return this.http.get(`${this.BACKENDURL}/claims/company/${company}/partner/${partnerId}/${claimId}/attachment/${attachmentId}`, { headers: this._getHeaders() });
    }

    saveAttachment(company, partnerId, claimId, attachmentId, file) {
        return this.http.post(`${this.BACKENDURL}/claims/company/${company}/partner/${partnerId}/${claimId}/attachment/${attachmentId}`, JSON.stringify(file), { headers: this._getHeaders() });
    }

    deleteAttachment(company, partnerId, claimId, attachmentId) {
        return this.http.delete(`${this.BACKENDURL}/claims/company/${company}/partner/${partnerId}/${claimId}/attachment/${attachmentId}`, { headers: this._getHeaders() });
    }

    /**
     * Bulk approve claims
     * Used for list view selected items update. Invoice for example.
     * @param type approval type
     * @param action add / remove
     * @param items claims [{ id: '', company: '', partnerId: '' }]
     * @param ref invoice / internal invoice reference number
     */
    bulkApproveClaims(type, action, items, ref = null) {
        const body = {
            type: type,
            action: action,
            items: items,
            ref: ref
        };
        console.log('bulkApproveClaims', body);
        return this.http.post(`${this.BACKENDURL}/claims/bulk-approve`, JSON.stringify(body), { headers: this._getHeaders() });
    }

    // Returns search params ?search=
    // key=value&key2=value2,value22
    // key%3Dvalue%26key2%3Dvalue2%2Cvalue22
    _getSearchString(params) {
        const queryStringParams = _.map(params, (p) => {
            return p.key + '%3D' + p.value;
        }).join('%26').replace(/,/g, '%2C').replace(/\#/g, '%23');
        if (queryStringParams) {
            return `&search=${queryStringParams}`;
        } else {
            return '';
        }
    }

    // Returns sort params ?sort=key:direction;key2:direction2
    _getSortString(params: Array<{ key: string; direction: string }>) {
        const queryStringParams = _.map(params, (p) => {
            return p.key + ':' + p.direction;
        }).join(',');
        return queryStringParams;
    }


    searchAll(params, size, from, sort: Array<{ key: string; direction: string }>) {
        return this.http.get(`${this.BACKENDURL}/claims?size=${size}&from=${from}${this._getSearchString(params)}&sort=${this._getSortString(sort)}`,
            { headers: this._getHeaders() });
    }

    searchByCompany(company, params, size, from, sort: Array<{ key: string; direction: string }>) {
        console.log('searchByCompany', company, params, size, from);
        return this.http.get(`${this.BACKENDURL}/claims/company/${company}?size=${size}&from=${from}${this._getSearchString(params)}&sort=${this._getSortString(sort)}`,
            { headers: this._getHeaders() });
    }

    searchByPartner(company, partnerId, params, size, from, sort: Array<{ key: string; direction: string }>) {
        console.log('searchByPartner', company, partnerId, params, size, from);
        return this.http.get(`${this.BACKENDURL}/claims/company/${company}/partner/${partnerId}?size=${size}&from=${from}${this._getSearchString(params)}&sort=${this._getSortString(sort)}`,
            { headers: this._getHeaders() }
        );
    }

    getClaimsBySerialNumber(serialNumber) {
        console.log('getClaimsBySerialNumber', serialNumber);
        return this.http.get(`${this.BACKENDURL}/claims/serial/${serialNumber}`, { headers: this._getHeaders() });
    }

    _getHeaders() {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.token}`
        });
    }

}
