import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListClaimsPageComponent } from './modules/claims/components/list-claims-page/list-claims-page.component';
import { ClaimPageComponent } from './modules/claims/components/claim-page/claim-page.component';
import { ListRegistrationsPageComponent } from './modules/registrations/components/list-registrations-page/list-registrations-page.component';
import { RegistrationPageComponent } from './modules/registrations/components/registration-page/registration-page.component';
import { RegistrationCertificateComponent } from './modules/registrations/components/registration-certificate/registration-certificate.component';
import { LabourCostsPageComponent } from './modules/settings/components/labour-costs-page/labour-costs-page.component';
import { SettingsPageComponent } from './modules/settings/components/settings-page/settings-page.component';
import { ReportsClaimsPageComponent } from './modules/reporting/components/reports-claims-page/reports-claims-page.component';
import { ReportingDashboardPageComponent } from './modules/reporting/components/dashboard-page/dashboard-page.component';
import { ReportingCustomPageComponent } from './modules/reporting/components/custom-page/custom-page.component';
import { LandingPageComponent } from './modules/base/components/landing-page/landing-page.component';
import { LoginSelectPageComponent } from './modules/base/components/login-select-page/login-select-page.component';
import { LoginPageComponent } from './modules/base/components/login-page/login-page.component';
import { CallbackComponent } from './modules/base/components/callback/callback.component';

import { AuthService } from './services/auth.service';
import { CanDeactivateGuard } from './services/can-deactivate-guard.service';


// TODO: remove imports and actual implementations of editorpage and rulespage
const routes: Routes = [
    {
        path: '',
        component: LandingPageComponent,
        canActivate: [AuthService]
    },
    {
        path: 'testlogin',
        component: LoginPageComponent
    },
    {
        path: 'selectlogin',
        component: LoginSelectPageComponent
    },
    {
        path: 'callback',
        component: CallbackComponent
    },
    {
        path: 'claims',
        component: ListClaimsPageComponent,
        canActivate: [AuthService]
    },
    {
        path: 'claims/:company/:partnerId/:id',
        component: ClaimPageComponent,
        canActivate: [AuthService],
        canDeactivate: [CanDeactivateGuard], // Discard changes checker
        runGuardsAndResolvers: 'paramsChange'
    },
    {
        path: 'registrations',
        component: ListRegistrationsPageComponent,
        canActivate: [AuthService]
    },
    {
        path: 'registrations/certificate/:id',
        component: RegistrationCertificateComponent,
        canActivate: [AuthService]
    },
    {
        path: 'registrations/:company/:partnerId/:id',
        component: RegistrationPageComponent,
        canActivate: [AuthService],
        canDeactivate: [CanDeactivateGuard] // Discard changes checker
    },
    {
        path: 'reporting/claims',
        component: ReportsClaimsPageComponent,
        canActivate: [AuthService]
    },
    {
        path: 'reporting/reporting',
        component: ReportingDashboardPageComponent,
        canActivate: [AuthService]
    },
    {
        path: 'reporting/reporting-custom',
        component: ReportingCustomPageComponent,
        canActivate: [AuthService]
    },
    {
        path: 'prices/labour-costs',
        component: LabourCostsPageComponent,
        canActivate: [AuthService],
        canDeactivate: [CanDeactivateGuard] // Discard changes checker
    },
    {
        path: 'settings',
        component: SettingsPageComponent,
        canActivate: [AuthService]
    },
    {
        path: '**', redirectTo: ''
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })], // Force component reload when navigating to same component
  exports: [RouterModule],
  providers: [AuthService]
})
export class AppRoutingModule { }
