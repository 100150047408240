<div class="main">
    <div class="list-container">
        <div class="full main-header" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <mat-icon class="kemppi-symbols">s</mat-icon>
            <div fxLayout="column">
                <h2>{{ 'menu.warranty_claims' | translate }}</h2>
            </div>
        </div>

        <div class="full" fxLayoutGap="20px">
            <h2 class="mat-subhead">{{ 'common.search' | translate }}</h2>
            <div fxLayout="row">
                <div fxFlex fxLayout="column">
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutAlign="start start">
                        <!--
                        <label class="search-icon"></label>
                        -->
                        <!--
                        <mat-icon class="kemppi-symbols avatar-icon">å</mat-icon>
                        <mat-chip-list>
                            <mat-chip *ngFor="let chip of filterChips; let i = index" (click)="removeFilterChip(i)">{{filterNames[chip.key]}}={{chip.text}}</mat-chip>
                        </mat-chip-list>
                        -->

                        <!--
                        <input matInput placeholder="Search by id, state or user" #query (keyup)="filterList(query.value)">
                        -->
                        <!--
                        <mat-form-field fxFlex>
                            <mat-label>Search by claim id</mat-label>
                            <input matInput [(ngModel)]="searchText" (keydown)="addFilter($event, searchText)">
                        </mat-form-field>
                        -->

                        <div fxFlex.gt-sm="410px" fxLayout="row" fxLayout.lt-md="column">
                            <mat-chip-list>
                                <mat-chip *ngFor="let chip of filterChips; let i = index" (click)="removeFilterChip(i)">
                                    <b>{{chip.text}}</b>&nbsp;<small class="weak">({{filterNames[chip.key] | translate}})</small></mat-chip>
                            </mat-chip-list>

                            <mat-form-field fxFlex.gt-sm="true">
                                <input matInput [(ngModel)]="searchText" (keydown)="searchKeyEvent($event)" (keyup)="searchValueUpdate()" [matAutocomplete]="auto">
                                <span matPrefix><mat-icon class="kemppi-symbols avatar-icon">å</mat-icon></span>
                            </mat-form-field>
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addFilterChip($event.option.value, searchValue)">
                                <ng-container *ngIf="searchText">
                                    <mat-option value="id"><b>{{searchValue}}</b> - {{ 'claims.claim_id' | translate }}</mat-option>
                                    <mat-option value="partnerName.normalize" *ngIf="permissions.scope === 'global' || permissions.scope === 'company'"><b>{{searchValue}}</b> - {{ 'common.partner_name' | translate }}</mat-option>
                                    <mat-option value="data.customer.companyName.normalize"><b>{{searchValue}}</b> - {{ 'common.end_customer' | translate }}</mat-option>
                                    <mat-option value="data.products.serialNumber"><b>{{searchValue}}</b> - {{ 'common.serial_number' | translate }}</mat-option>
                                    <mat-option value="data.products.productId"><b>{{searchValue}}</b> - {{ 'common.product_number' | translate }}</mat-option>
                                    <mat-option value="data.products.productName"><b>{{searchValue}}</b> - {{ 'common.product_name' | translate }}</mat-option>
                                    <mat-option value="data.invoice.reference"><b>{{searchValue}}</b> - {{ 'claims.invoice_reference_number' | translate }}</mat-option>
                                    <mat-option value="data.invoice.internalReference" *ngIf="permissions.scope === 'global' || permissions.scope === 'company'"><b>{{searchValue}}</b> - {{ 'claims.invoice_internal_reference_number' | translate }}</mat-option>
                                    <mat-option value="data.invoice.salesOrderNumber" *ngIf="permissions.scope === 'global'"><b>{{searchValue}}</b> - {{ 'claims.sales_order_number' | translate }}</mat-option>
                                </ng-container>
                            </mat-autocomplete>
                        </div>

                        <mat-form-field fxFlex.gt-sm="200px">
                            <mat-label>{{ 'common.status' | translate }}</mat-label>
                            <mat-select [(value)]="claimState" (selectionChange)="setFilter('state.currentState', claimState)" multiple>
                                <!-- <mat-option value="">{{ 'claims.state.ALL' | translate }}</mat-option> -->
                                <mat-option value="DRAFT">{{ 'claims.state.DRAFT' | translate }}</mat-option>
                                <mat-option value="OPEN">{{ 'claims.state.OPEN' | translate }}</mat-option>
                                <mat-option value="IN PROGRESS">{{ 'claims.state.IN PROGRESS' | translate }}</mat-option>
                                <mat-option value="RETURNED">{{ 'claims.state.RETURNED' | translate }}</mat-option>
                                <mat-option value="REJECTED">{{ 'claims.state.REJECTED' | translate }}</mat-option>
                                <mat-option value="ACCEPTED">{{ 'claims.state.ACCEPTED' | translate }}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex.gt-sm="200px">
                            <mat-label>{{ 'claims.invoice' | translate }}</mat-label>
                            <mat-select [(value)]="claimStateInvoiced" (selectionChange)="setFilter('state.invoiceState', claimStateInvoiced)" multiple>
                                <mat-option value="" matTooltip="(Not invoiced or refunded)">{{ 'claims.state.OPEN' | translate }}</mat-option>
                                <mat-option value="INVOICED">{{ 'claims.state.INVOICED' | translate }}</mat-option>
                                <mat-option value="REFUNDED">{{ 'claims.state.REFUNDED' | translate }}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field fxFlex.gt-sm="200px">
                            <mat-label>{{ 'common.closed' | translate }}</mat-label>
                            <mat-select [(value)]="claimStateClosed" (selectionChange)="setFilter('state.closed', claimStateClosed)" multiple>
                                <!-- <mat-option value="">{{ 'claims.state.ALL' | translate }}</mat-option> -->
                                <mat-option [value]="false">{{ 'claims.state.NOT CLOSED' | translate }}</mat-option>
                                <mat-option [value]="true">{{ 'claims.state.CLOSED' | translate }}</mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>
                    <div *ngIf="permissions.scope === 'global' || permissions.scope === 'company'" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutAlign="start start">

                        <mat-form-field *ngIf="permissions.scope === 'global'" class="limited-access" fxFlex.gt-sm="200px">
                            <mat-label class="limited-access">{{ 'common.company' | translate }}</mat-label>
                            <mat-select [(value)]="claimCompanyNumber" (selectionChange)="setFilter('company', claimCompanyNumber)" multiple>
                                <!-- <mat-option value="">{{ 'claims.state.ALL' | translate }}</mat-option> -->
                                <mat-option *ngFor="let company of companies" [value]="company.id">{{ company.id }} - {{ company.name }}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div fxFlex.gt-xs="200px"></div>

                        <mat-form-field *ngIf="permissions.scope === 'global' || permissions.scope === 'company'" class="limited-access" fxFlex.gt-sm="200px">
                            <mat-label class="limited-access">{{ 'common.status' | translate }} ({{ 'common.internal' | translate }})</mat-label>
                            <mat-select [(value)]="claimStateInternal" (selectionChange)="setFilter('state.internalState', claimStateInternal)" multiple>
                                <!-- <mat-option value="">{{ 'claims.state.ALL' | translate }}</mat-option> -->
                                <mat-option value="WAITING">{{ 'claims.state.WAITING' | translate }}</mat-option>
                                <mat-option value="REJECTED">{{ 'claims.state.REJECTED' | translate }}</mat-option>
                                <mat-option value="ACCEPTED">{{ 'claims.state.ACCEPTED' | translate }}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field *ngIf="permissions.scope === 'global' || permissions.scope === 'company'" class="limited-access" fxFlex.gt-sm="200px">
                            <mat-label class="limited-access">{{ 'claims.invoice' | translate }} ({{ 'common.internal' | translate }})</mat-label>
                            <mat-select [(value)]="claimStateInvoicedInternal" (selectionChange)="setFilter('state.internalInvoiceState', claimStateInvoicedInternal)" multiple>
                                <mat-option value="" matTooltip="(Not invoiced or refunded)">{{ 'claims.state.OPEN' | translate }}</mat-option>
                                <mat-option value="INVOICED">{{ 'claims.state.INVOICED' | translate }}</mat-option>
                                <mat-option value="REFUNDED">{{ 'claims.state.REFUNDED' | translate }}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field *ngIf="permissions.scope === 'global'" class="limited-access" fxFlex.gt-sm="200px">
                            <mat-label class="limited-access">{{ 'common.status' | translate }} ({{ 'claims.logistics' | translate }})</mat-label>
                            <mat-select [(value)]="claimStateLogistics" (selectionChange)="setFilter('state.logisticsState', claimStateLogistics)" multiple>
                                <!-- <mat-option value="">{{ 'claims.state.ALL' | translate }}</mat-option> -->
                                <mat-option value="" matTooltip="(Not sended)">{{ 'claims.state.OPEN' | translate }}</mat-option>
                                <mat-option value="QUEUED">{{ 'claims.state.QUEUED' | translate }}</mat-option>
                                <mat-option value="HANDLED">{{ 'claims.state.HANDLED' | translate }}</mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>

                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px" fxLayoutAlign="start start">
                        <war-datepicker
                        [(ngModel)]="claimRepairDateStart"
                        [label]="'claims.repair_date' | translate"
                        (changeEvent)="setFilter('data.repairDate', claimRepairDateStart + '#' + claimRepairDateEnd)"></war-datepicker>
                        <war-datepicker
                        [(ngModel)]="claimRepairDateEnd"
                        [label]="''"
                        [endOfDay]="true"
                        (changeEvent)="setFilter('data.repairDate', claimRepairDateStart + '#' + claimRepairDateEnd)"></war-datepicker>
                    </div>

                </div>
                <div>
                    <button mat-button color="warn" (click)="resetSearchParams()">
                        {{ 'claims.reset' | translate }}
                    </button>
                </div>
            </div>
            <div *ngIf="permissions.scope === 'global'" class="saved-filters" fxLayout="column">
                <div fxLayout="row">
                    <button mat-button (click)="toggleSavedFilters()" fxLayoutAlign="start center" class="cursor-pointer">
                        <mat-icon *ngIf="showSavedFilters" class="drop-down-arrow">arrow_drop_up</mat-icon>
                        <mat-icon *ngIf="!showSavedFilters" class="drop-down-arrow">arrow_drop_down</mat-icon>
                        {{ 'common.saved_filters' | translate }}
                    </button>
                </div>
                <div *ngIf="showSavedFilters" fxFlex.gt-sm="true" fxLayout="wrap row" fxLayout.lt-md="column" fxLayoutAlign="start center">
                    <div *ngFor="let savedSearch of savedSearchParams">
                        <button mat-button mat-raised-button color="primary" (click)="loadSearchParams(savedSearch.name)">
                            <mat-icon [ngClass]="{'accent': savedSearch.name === savedSearchName}">filter_list</mat-icon> {{ savedSearch.name }}
                        </button>
                    </div>
                </div>
                <div *ngIf="showSavedFilters" fxLayout="row">
                    <div>
                        <mat-form-field>
                            <mat-label>{{ 'common.filter_name' | translate }}</mat-label>
                            <input matInput [(ngModel)]="savedSearchName">
                        </mat-form-field>
                    </div>
                    <div>
                        <button mat-button color="accent" (click)="saveSearch()" [disabled]="!savedSearchName">
                            {{ 'common.save' | translate }}
                        </button>
                    </div>
                    <div>
                        <button mat-button color="warn" (click)="removeSavedSearch()" [disabled]="!savedSearchName">
                            {{ 'common.remove' | translate }}
                        </button>
                    </div>
                </div>

            </div>
        </div>
        <div fxLayoutAlign="start center">
            <div>
                <h2 class="mat-subhead">{{ 'menu.claims' | translate }}</h2>
            </div>
        </div>
        <div fxLayoutAlign="start center">
            <div *ngIf="canCreate">
                <button mat-button mat-raised-button color="accent" (click)="createNewClaim()">
                    <mat-icon class="kemppi-symbols">ř</mat-icon>{{ 'claims.create_new_claim' | translate }}
                </button>
            </div>
            <div fxFlex></div>
            <mat-slide-toggle *ngIf="showPrices"
                color="accent"
                [(ngModel)]="invoicing">
                {{ 'claims.invoicing' | translate }}
            </mat-slide-toggle>
        </div>

        <div class="list-header-row" fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="40px">
                <mat-checkbox [(ngModel)]="allClaimsChecked" (change)="selectAllClaims(allClaimsChecked)"></mat-checkbox>
            </div>
            <div fxShow.lt-sm="false" fxFlex="35px" *ngIf="permissions.scope === 'global'" class="limited-access" (click)="sort('company')">
                {{ 'common.com' | translate }}
                <mat-icon *ngIf="sortParams.key === 'company'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
            </div>
            <div fxFlex="130px" fxFlex.lt-sm="120px" (click)="sort('id')">
                {{ 'claims.claim_id' | translate }}
                <mat-icon *ngIf="sortParams.key === 'id'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
            </div>
            <div fxShow.lt-sm="false" fxFlex *ngIf="permissions.scope === 'global' || permissions.scope === 'company'" class="limited-access" (click)="sort('partnerName')">
                {{ 'common.partner' | translate }}
                <mat-icon *ngIf="sortParams.key === 'partnerName'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
            </div>
            <div fxFlex="100px" (click)="sort('data.repairDate')">
                {{ 'claims.repair_date' | translate }}
                <mat-icon *ngIf="sortParams.key === 'data.repairDate'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
            </div>
            <div fxFlex *ngIf="permissions.scope === 'partner'" (click)="sort('data.customer.companyName')">
                {{ 'common.end_customer' | translate }}
                <mat-icon *ngIf="sortParams.key === 'data.customer.companyName'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
            </div>
            <div fxShow.lt-md="false" *ngIf="!invoicing" fxFlex="80px" (click)="sort('data.products.serialNumber')">
                {{ 'common.serial' | translate }}
                <mat-icon *ngIf="sortParams.key === 'data.products.serialNumber'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
            </div>
            <div fxShow.lt-lg="false" *ngIf="!invoicing" fxFlex="80px" (click)="sort('data.products.productId')">
                {{ 'common.product' | translate }}
                <mat-icon *ngIf="sortParams.key === 'data.products.productId'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
            </div>
            <div fxShow.lt-lg="false" *ngIf="!invoicing" fxFlex="200px" (click)="sort('data.products.productName')">
                {{ 'common.product_name' | translate }}
                <mat-icon *ngIf="sortParams.key === 'data.products.productName'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
            </div>
            <div fxFlex="90px" (click)="sort('state.currentState')" >
                {{ 'common.status' | translate }}
                <mat-icon *ngIf="sortParams.key === 'state.currentState'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
            </div>
            <div fxShow.lt-sm="false" fxFlex="90px" *ngIf="!invoicing && (permissions.scope === 'global' || permissions.scope === 'company')" class="limited-access" (click)="sort('state.internalState')">
                {{ 'common.internal' | translate }}
                <mat-icon *ngIf="sortParams.key === 'state.internalState'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
            </div>
            <div fxShow.lt-md="false" fxFlex="90px" (click)="sort('state.invoiceState')">
                {{ 'claims.invoice' | translate }}
                <mat-icon *ngIf="sortParams.key === 'state.invoiceState'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
            </div>
            <div fxShow.lt-md="false" fxFlex="90px" *ngIf="(permissions.scope === 'global' || permissions.scope === 'company')" class="limited-access" (click)="sort('state.internalInvoiceState')">
                {{ 'common.internal' | translate }}
                <mat-icon *ngIf="sortParams.key === 'state.internalInvoiceState'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
            </div>
            <div fxShow.lt-lg="false" fxFlex="90px" *ngIf="!invoicing && (permissions.scope === 'global' || authUserRole === 'cus_distributor')" class="limited-access" (click)="sort('state.logisticsState')">
                {{ 'claims.logistics' | translate }}
                <mat-icon *ngIf="sortParams.key === 'state.logisticsState'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
            </div>

            <div fxShow.lt-md="false" *ngIf="invoicing && (permissions.scope === 'global' || permissions.scope === 'company')" fxFlex="80px" class="limited-access right-align" (click)="sort('data.costs.partTransferCosts')">
                {{ 'claims.parts_transfer_price' | translate }}
                <mat-icon *ngIf="sortParams.key === 'data.costs.partTransferCosts'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
            </div>
            <div *ngIf="invoicing && (permissions.scope === 'global' || permissions.scope === 'company')" fxFlex="80px" class="limited-access right-align" (click)="sort('data.costs.totalTransferCosts')">
                {{ 'claims.total_transfer_costs' | translate }}
                <mat-icon *ngIf="sortParams.key === 'data.costs.totalTransferCosts'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
            </div>
            <div *ngIf="invoicing && (permissions.scope === 'global' || permissions.scope === 'company')" fxFlex="25px" class="right-align"></div>
            <div fxShow.lt-md="false" *ngIf="invoicing" fxFlex="80px" class="right-align" (click)="sort('data.costs.partCosts')">
                {{ 'claims.parts' | translate }}
                <mat-icon *ngIf="sortParams.key === 'data.costs.partCosts'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
            </div>
            <div fxShow.lt-lg="false" *ngIf="invoicing" fxFlex="80px" class="right-align" (click)="sort('data.costs.repairCosts')">
                {{ 'claims.repairs' | translate }}
                <mat-icon *ngIf="sortParams.key === 'data.costs.repairCosts'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
            </div>
            <div fxShow.lt-lg="false" *ngIf="invoicing" fxFlex="80px" class="right-align" (click)="sort('data.costs.otherCosts')">
                {{ 'claims.others' | translate }}
                <mat-icon *ngIf="sortParams.key === 'data.costs.otherCosts'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
            </div>
            <div *ngIf="invoicing" fxFlex="80px" class="right-align" (click)="sort('data.costs.totalCosts')">
                {{ 'claims.total' | translate }}
                <mat-icon *ngIf="sortParams.key === 'data.costs.totalCosts'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
            </div>
            <div *ngIf="invoicing" fxFlex="25px" class="right-align"></div>
        </div>
        <div fxLayout="column" fxLayoutAlign="start start">
            <div *ngFor="let claim of claims; let i = index; trackBy: trackById" class="list-row" [ngClass]="{'state-CLOSED': claim.state.closed}" fxLayout="row" fxLayoutAlign="start center">
                <div fxFlex="40px" class="select-checkbox">
                    <!--
                    &nbsp;
                    <mat-icon class="kemppi-symbols avatar-icon">s</mat-icon>
                    -->
                    <mat-checkbox [(ngModel)]="selectedClaims[claim.id]" (change)="checkSelectedClaims()"></mat-checkbox>
                </div>
                <div (click)="openClaim(claim.company, claim.partnerId, claim.id)" fxFlex>
                    <div fxShow.lt-sm="false" fxFlex="35px" *ngIf="permissions.scope === 'global'" fxLayoutAlign="start center">
                        {{claim.company}}
                    </div>
                    <div fxFlex="130px" fxFlex.lt-sm="120px" fxLayoutAlign="start center">
                        {{claim.id}}
                    </div>
                    <div *ngIf="permissions.scope === 'global' || permissions.scope === 'company'" fxShow.lt-sm="false" fxFlex fxLayoutAlign="start center">
                        <small>{{claim.partnerName}}</small>
                    </div>
                    <div fxFlex="100px" fxLayoutAlign="start center">
                        {{claim.data.repairDate | date:'shortDate':undefined:localeService.locale}}<br>
                    </div>
                    <div fxFlex *ngIf="permissions.scope === 'partner'">
                        <small>{{claim.data.customer?.companyName}}</small>
                    </div>
                    <ng-container *ngIf="!invoicing">
                        <div fxShow.lt-md="false" fxFlex="80px" fxLayoutAlign="start center">
                            <small *ngIf="claim.data.products">{{claim.data.products[0]?.serialNumber}}</small>
                        </div>
                        <div fxShow.lt-lg="false" fxFlex="80px" fxLayoutAlign="start center">
                            <small *ngIf="claim.data.products">{{claim.data.products[0]?.productId}}</small>
                        </div>
                        <div fxShow.lt-lg="false" fxFlex="200px" fxLayoutAlign="start center">
                            <small *ngIf="claim.data.products">{{claim.data.products[0]?.productName}}</small>
                        </div>
                    </ng-container>
                    <div fxFlex="90px" class="state-{{claim.state.currentState}}" fxLayoutAlign="start center">
                        <small>{{ 'claims.state.' + claim.state.currentState | translate}}</small>
                    </div>
                    <div *ngIf="!invoicing && (permissions.scope === 'global' || permissions.scope === 'company')" fxLayout="column" fxShow.lt-sm="false" fxFlex="90px" class="state-{{claim.state.internalState}}" fxLayoutAlign="center start">
                        <div *ngIf="claim.state.internalState"><small>{{ 'claims.state.' + claim.state.internalState | translate }}</small></div>
                        <div *ngIf="claim.state.approvalProcess === 'WUXI'" class="warn" style="height: 0px;"><small><i>WUXI</i></small></div>
                    </div>
                    <div fxShow.lt-md="false" fxFlex="90px" class="state-{{claim.state.invoiceState}}" fxLayoutAlign="start center">
                        <small *ngIf="claim.state.invoiceState !== '' && (!claim.data.invoice || !claim.data.invoice.reference)">{{ 'claims.state.' + claim.state.invoiceState | translate }}</small>
                        <small *ngIf="claim.data.invoice && claim.data.invoice.reference">{{claim.data.invoice?.reference}}</small>
                    </div>
                    <div *ngIf="(permissions.scope === 'global' || permissions.scope === 'company')" fxShow.lt-md="false" fxFlex="90px" class="state-{{claim.state.internalInvoiceState}}" fxLayoutAlign="start center">
                        <small *ngIf="claim.state.internalInvoiceState !== '' && (!claim.data.invoice || !claim.data.invoice.internalReference)">{{ 'claims.state.' + claim.state.internalInvoiceState | translate }}</small>
                        <small *ngIf="claim.data.invoice && claim.data.invoice.internalReference">{{claim.data.invoice?.internalReference}}</small>
                    </div>
                    <div *ngIf="!invoicing && (permissions.scope === 'global' || authUserRole === 'cus_distributor')" fxShow.lt-lg="false" fxFlex="90px" class="state-{{claim.state.logisticsState}}" fxLayoutAlign="start center">
                        <small *ngIf="claim.state.logisticsState !== '' && (!claim.data.invoice || !claim.data.invoice.salesOrderNumber)">{{ 'claims.state.' + claim.state.logisticsState | translate }}</small>
                        <small *ngIf="claim.data.invoice && claim.data.invoice.salesOrderNumber">{{claim.data.invoice?.salesOrderNumber}}</small>
                        <div fxFlex></div>
                        <mat-icon *ngIf="claim.data.shipping && claim.data.shipping.comment !== ''" matTooltip="{{ 'claims.shipping_comment' | translate }}">message</mat-icon>
                    </div>

                    <ng-container *ngIf="invoicing && claim.data.costs">
                        <div *ngIf="(permissions.scope === 'global' || permissions.scope === 'company')" fxShow.lt-md="false" fxFlex="80px" class="limited-access" fxLayoutAlign="end center">
                            {{claim.data.costs.partTransferCosts | number:'1.2-2'}}
                        </div>
                        <div *ngIf="(permissions.scope === 'global' || permissions.scope === 'company')" fxFlex="80px" class="limited-access" fxLayoutAlign="end center">
                            {{claim.data.costs.totalTransferCosts | number:'1.2-2'}}
                        </div>
                        <div *ngIf="(permissions.scope === 'global' || permissions.scope === 'company')" fxFlex="25px" class="limited-access" fxLayoutAlign="end center">
                            <small *ngIf="claim.data.currency && claim.data.currency.internal">{{claim.data.currency.internal.code}}</small>
                            <small *ngIf="claim.data.currency && !claim.data.currency.internal">{{claim.data.currency.code}}</small>
                        </div>
                        <div fxShow.lt-md="false" fxFlex="80px" fxLayoutAlign="end center">
                            {{claim.data.costs.partCosts | number:'1.2-2'}}
                        </div>
                        <div fxShow.lt-lg="false" fxFlex="80px" fxLayoutAlign="end center">
                            {{claim.data.costs.repairCosts | number:'1.2-2'}}
                        </div>
                        <div fxShow.lt-lg="false" fxFlex="80px" fxLayoutAlign="end center">
                            {{claim.data.costs.otherCosts | number:'1.2-2'}}
                        </div>
                        <div fxFlex="80px" fxLayoutAlign="end center">
                            <b>{{claim.data.costs.totalCosts | number:'1.2-2'}}</b>
                        </div>
                        <div fxFlex="25px" fxLayoutAlign="end center">
                            <small *ngIf="claim.data.currency">{{claim.data.currency.code}}</small>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div *ngIf="invoicing && invoicingCosts" class="list-row invoicing-costs" fxLayout="row" fxLayoutAlign="start center">
                <div fxFlex="40px" class="select-checkbox">
                    &nbsp;
                </div>
                <div fxFlex>
                    <div fxFlex></div>
                    <div fxShow.lt-md="false" *ngIf="(permissions.scope === 'global' || permissions.scope === 'company')" fxFlex="80px" fxLayoutAlign="end center" class="limited-access">
                        <b>{{invoicingCosts.partTransferCosts | number:'1.2-2'}}</b>
                    </div>
                    <div *ngIf="(permissions.scope === 'global' || permissions.scope === 'company')" fxFlex="80px" fxLayoutAlign="end center" class="limited-access">
                        <b>{{invoicingCosts.totalTransferCosts | number:'1.2-2'}}</b>
                    </div>
                    <div *ngIf="(permissions.scope === 'global' || permissions.scope === 'company')" fxFlex="25px" fxLayoutAlign="end center" class="limited-access">
                        <b><small>{{invoicingCosts.internalCurrency}}</small></b>
                    </div>
                    <div fxShow.lt-md="false" fxFlex="80px" fxLayoutAlign="end center">
                        <b>{{invoicingCosts.partCosts | number:'1.2-2'}}</b>
                    </div>
                    <div fxShow.lt-lg="false" fxFlex="80px" fxLayoutAlign="end center">
                        <b>{{invoicingCosts.repairCosts | number:'1.2-2'}}</b>
                    </div>
                    <div fxShow.lt-lg="false" fxFlex="80px" fxLayoutAlign="end center">
                        <b>{{invoicingCosts.otherCosts | number:'1.2-2'}}</b>
                    </div>
                    <div fxFlex="80px" fxLayoutAlign="end center">
                        <b>{{invoicingCosts.totalCosts | number:'1.2-2'}}</b>
                    </div>
                    <div fxFlex="25px" fxLayoutAlign="end center">
                        <b><small>{{invoicingCosts.currency}}</small></b>
                    </div>
                </div>
            </div>
            <div fxLayout="row" class="width100">
                <div fxFlex>
                    <h2 class="mat-subhead">
                        <small><i>{{ 'common.results' | translate }}: {{resultHits}} / {{totalHits}}</i></small>
                    </h2>
                </div>
                <div fxFlex fxLayoutAlign="center center">
                    <mat-progress-spinner matSuffix *ngIf="claimsLoading" [mode]="'indeterminate'" [color]="'primary'" [diameter]="48" [strokeWidth]="4"></mat-progress-spinner>
                    <button *ngIf="!claimsLoading && totalHits > resultHits" mat-button (click)="loadMore()">
                        <mat-icon class="kemppi-symbols">ş</mat-icon> {{ 'claims.load_more' | translate }}
                    </button>
                </div>
                <div fxFlex></div>
            </div>
        </div>
    </div>
</div>

<ks-actionbar [hasCancel]="false" [hasSave]="false">

    <button mat-button mat-raised-button color="primary" (click)="toggleActions()" fxShow.gt-sm="false" [disabled]="!anyClaimSelected">
        <mat-icon class="kemppi-symbols">ą</mat-icon> {{ 'claims.actions.actions' | translate }}
    </button>

    <div fxFlex></div>

    <div fxFlex class="actions" [ngClass]="{'actions-visible': showActions}" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="center center">

        <button *ngIf="anyClaimSelected && canCreate && authUserScope === 'global' && authUserRole === 'sys_admin'" mat-button mat-raised-button color="warn" (click)="openCloseConfirmDialog()">
            <mat-icon class="kemppi-symbols">Ê</mat-icon> {{ 'claims.close' | translate }}
        </button>

        <button *ngIf="anyClaimSelected && canCreate" mat-button mat-raised-button color="accent" (click)="openInvoiceDialog('invoiced')" [disabled]="!anyClaimSelected || claimsCannotBeInvoiced">
            <mat-icon class="kemppi-symbols">ó</mat-icon> {{ 'claims.actions.invoice' | translate }}
        </button>

        <button *ngIf="anyClaimSelected && canCreate && (authUserScope === 'company' || authUserScope === 'global')" mat-button mat-raised-button color="accent" (click)="openRefundConfirmDialog()" [disabled]="!anyClaimSelected || claimsCannotBeRefunded">
            <mat-icon class="kemppi-symbols">ó</mat-icon> {{ 'claims.actions.refund' | translate }}
        </button>

        <button *ngIf="anyClaimSelected && canCreate && (authUserScope === 'company' || authUserScope === 'global')" mat-button mat-raised-button color="accent" (click)="openInvoiceDialog('invoicedInternal')" [disabled]="!anyClaimSelected || claimsCannotBeInvoicedInternal">
            <mat-icon class="kemppi-symbols">ó</mat-icon> {{ 'claims.actions.invoice_internal' | translate }}
        </button>

        <button *ngIf="anyClaimSelected && canCreate && (authUserScope === 'global')" mat-button mat-raised-button color="accent" (click)="openRefundConfirmDialog(true)" [disabled]="!anyClaimSelected || claimsCannotBeRefundedInternal">
            <mat-icon class="kemppi-symbols">ó</mat-icon> {{ 'claims.actions.refund_internal' | translate }}
        </button>

        <button *ngIf="anyClaimSelected && (authUserRole === 'koy_logistics' || authUserRole === 'sys_admin')" mat-button mat-raised-button color="accent" (click)="openInvoiceDialog('logistics')" [disabled]="!anyClaimSelected || claimsCannotBeHandledByLogistics">
            <mat-icon class="kemppi-symbols">ó</mat-icon> {{ 'claims.actions.handled_by_logistics' | translate }}
        </button>
    </div>

    <div fxFlex></div>

    <button *ngIf="anyClaimSelected" mat-button mat-raised-button color="accent" class="export-button" (click)="toggleExportActions()">
        <mat-icon>open_in_new</mat-icon> Export
    </button>

    <div fxFlex class="export-actions" [ngClass]="{'export-actions-visible': showExportActions}" fxLayout="column" fxLayoutAlign="center center">
        <button *ngIf="anyClaimSelected" mat-button mat-raised-button color="accent" class="export-button" (click)="exportSelectedClaimsToXLSX()">
            <mat-icon>open_in_new</mat-icon> XLSX
        </button>

        <button *ngIf="anyClaimSelected && (authUserRole === 'koy_logistics' || authUserRole === 'sys_admin')" mat-button mat-raised-button color="accent" class="export-button" (click)="exportSelectedClaimsToLogisticsXLSX()" [disabled]="!anyClaimSelected || claimsCannotBeExportedByLogistics">
            <mat-icon>open_in_new</mat-icon> Logistics
        </button>

        <button *ngIf="anyClaimSelected && (authUserScope === 'global')" mat-button mat-raised-button color="accent" class="export-button" (click)="exportSelectedClaimsToFaultyXLSX()" [disabled]="!anyClaimSelected">
            <mat-icon>open_in_new</mat-icon> Faulty
        </button>
    </div>

</ks-actionbar>