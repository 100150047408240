import { Injectable } from '@angular/core';

@Injectable()
export class StateService {

    public state: any = {
        hasMenu: true,
        hasBack: false,
        url: [],
        loading: true,
        file: '',
        isPrinting: false
    };

    constructor() { }
}
