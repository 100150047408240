<div class="main">
    <div class="full" class="page-container" fxLayout="column" fxLayoutGap="20px">
        <div class="full main-header" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <mat-icon class="kemppi-symbols">Å</mat-icon>
            <div fxLayout="column">
                <h2>{{ 'menu.reporting' | translate }}</h2>
            </div>
        </div>

<mat-tab-group class="container">
    <mat-tab label="Listing">
        <fieldset fxFlex>
            <legend>REPORT SELECTIONS</legend>
            <div class="full" fxLayout="column">
                <div fxLayout="row">
                    <mat-form-field fxFlex="250px">
                        <mat-label>Main field</mat-label>
                        <mat-select [(value)]="report.field" (selectionChange)="checkResultFields()">
                            <mat-option value="company">CLAIMS - Company</mat-option>
                            <mat-option value="partner">CLAIMS - Partner</mat-option>
                            <mat-option value="id">CLAIMS - Claim id</mat-option>
                            <mat-option value="productId">PRODUCTS - Product id</mat-option>
                            <mat-option value="serialNumber">PRODUCTS - Serial number</mat-option>
                            <mat-option value="part">SPARE PARTS - Part id</mat-option>
                            <!--
                            <mat-option value="productGroup">Product group</mat-option>
                            <mat-option value="partGroup">PARTS - Part Group</mat-option>
                            -->
                        </mat-select>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutGap="20px">
                    <fieldset fxFlex>
                        <legend>Filters</legend>
                        <div fxLayout="row" *ngFor="let filter of report.filters; let i = index">
                            <div fxFlex>
                                <div fxFlex="300px">
                                    {{ filter.name }}<br>
                                    <div class="weak">{{ filter.field}}</div>
                                </div>
                                <div fxFlex="20px">=</div>
                                <div *ngIf="filter.type === 'match'" fxFlex="300px">
                                    {{ filter.matchValue }}
                                </div>
                                <div *ngIf="filter.type === 'should'" fxFlex="300px">
                                    (<span *ngFor="let shouldValue of filter.shouldValues; let i = index"><span *ngIf="i > 0"> / </span>{{ shouldValue }}</span>)
                                </div>
                                <div *ngIf="filter.type === 'range'" fxFlex="300px">
                                    {{ filter.rangeStart }} -<br>
                                    {{ filter.rangeEnd }}
                                </div>
                                <div fxFlex></div>
                            </div>
                            <button mat-icon-button mat-raised-button color="accent" (click)="editReportFilter(i)">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-icon-button mat-raised-button color="warn" (click)="removeReportFilter(i)">
                                <mat-icon>remove</mat-icon>
                            </button>
                        </div>

                        <div fxFlex fxLayout="row" fxLayoutGap="10px">
                            <mat-form-field fxFlex="250px">
                                <mat-label>Field</mat-label>
                                <mat-select [(value)]="selectFilter">
                                    <mat-option *ngFor="let filter of filters" [value]="filter">
                                        <div fxFlex>{{ filter.name }}</div>
                                        <small *ngIf="filter.type === 'range'"><i>{{ filter.type }}</i></small>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <ng-container *ngIf="selectFilter && selectFilter.type === 'match'">
                                <mat-form-field fxFlex>
                                    <mat-label>Value</mat-label>
                                    <input *ngIf="!selectFilter.values" matInput [(ngModel)]="selectFilterValue">
                                    <mat-select *ngIf="selectFilter.values" [(value)]="selectFilterValue" multiple>
                                        <mat-option *ngFor="let value of selectFilter.values" [value]="value">{{ value }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngIf="selectFilter && selectFilter.type === 'range'">
                                <war-datepicker
                                [(ngModel)]="selectFilterStart"
                                [label]="'Start date'"></war-datepicker>
                                <war-datepicker
                                [(ngModel)]="selectFilterEnd"
                                [label]="'End date'"></war-datepicker>
                                <div fxFlex></div>
                            </ng-container>

                            <button *ngIf="selectFilter && (selectFilterValue || (selectFilterStart && selectFilterEnd))" mat-icon-button mat-raised-button color="accent" (click)="addReportFilter()">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                    </fieldset>

                    <fieldset>
                        <legend>result fields</legend>
                        <mat-selection-list #list [(ngModel)]="report.aggs" class="results-selection">
                            <mat-label>Claims</mat-label>
                            <mat-list-option checkboxPosition="before" color="primary" value="count"># count</mat-list-option>
                            <mat-list-option checkboxPosition="before" color="primary" value="name">name</mat-list-option>
                            <mat-label>Costs</mat-label>
                            <mat-list-option checkboxPosition="before" color="primary" value="partCostsOKH" [disabled]="report.field === 'part'">Spareparts (OKH) €</mat-list-option>
                            <mat-list-option checkboxPosition="before" color="primary" value="partCosts" [disabled]="report.field === 'part'">Spareparts €</mat-list-option>
                            <mat-list-option checkboxPosition="before" color="primary" value="repairCosts" [disabled]="report.field === 'part'">Repairs €</mat-list-option>
                            <mat-list-option checkboxPosition="before" color="primary" value="otherCosts" [disabled]="report.field === 'part'">Others €</mat-list-option>
                            <mat-list-option checkboxPosition="before" color="primary" value="totalCosts" [disabled]="report.field === 'part'">Total €</mat-list-option>
                            <mat-label>Spare parts</mat-label>
                            <mat-list-option checkboxPosition="before" color="primary" value="partQuantity" [disabled]="report.field !== 'part'">parts usage</mat-list-option>
                        </mat-selection-list>
                    </fieldset>
                </div>

                <div fxLayout="row">
                    <div>
                        <button mat-button mat-raised-button color="accent" (click)="getClaimsTerms()">
                            Update
                        </button>
                    </div>
                    <div fxFlex></div>
                    <mat-form-field>
                        <mat-label>Max results</mat-label>
                        <input matInput [(ngModel)]="report.size" type="number" step="1" min="0">
                    </mat-form-field>
                </div>
                <!--
                <div fxLayout="row">
                    <button mat-button mat-raised-button color="accent" (click)="getParts()" disabled="true">
                        Get parts
                    </button>
                </div>
                -->
            </div>
        </fieldset>

        <fieldset fxFlex>
            <legend>Results</legend>
            <table *ngIf="results">
                <tr>
                    <th>{{ fields[resultField].name }}</th>
                    <th *ngIf="resultShowName">name</th>
                    <th *ngIf="resultShowCount"># claims</th>
                    <th *ngIf="results && results[0] && results[0].partQuantity"># parts usage</th>
                    <th *ngIf="results && results[0] && results[0].partCostsOKH">Parts (OKH) €</th>
                    <th *ngIf="results && results[0] && results[0].partCosts">Parts €</th>
                    <th *ngIf="results && results[0] && results[0].repairCosts">Repairs €</th>
                    <th *ngIf="results && results[0] && results[0].otherCosts">Others €</th>
                    <th *ngIf="results && results[0] && results[0].totalCosts">Total €</th>
                </tr>
                <tr *ngFor="let result of results">
                    <td>{{result.key}}</td>
                    <td *ngIf="resultShowName">{{result.name}}</td>
                    <td *ngIf="resultShowCount">{{result.doc_count}}</td>
                    <td *ngIf="result.partQuantity">{{result.partQuantity.value}}</td>
                    <td *ngIf="result.partCosts">{{result.partCostsOKH.value | currency:'EUR':'symbol':'1.2':'fi-FI'}}</td>
                    <td *ngIf="result.partCosts">{{result.partCosts.value | currency:'EUR':'symbol':'1.2':'fi-FI'}}</td>
                    <td *ngIf="result.repairCosts">{{result.repairCosts.value | currency:'EUR':'symbol':'1.2':'fi-FI'}}</td>
                    <td *ngIf="result.otherCosts">{{result.otherCosts.value | currency:'EUR':'symbol':'1.2':'fi-FI'}}</td>
                    <td *ngIf="result.totalCosts">{{result.totalCosts.value | currency:'EUR':'symbol':'1.2':'fi-FI'}}</td>
                </tr>
            </table>
        </fieldset>

</mat-tab>
<mat-tab label="Histogram">
        <fieldset fxFlex>
            <legend>REPORT SELECTIONS</legend>
            <div class="full" fxLayout="column">
                <div fxLayout="row" fxLayoutGap="20px">
                    <mat-form-field fxFlex="250px">
                        <mat-label>Main data field</mat-label>
                        <mat-select [(value)]="report.sumField">
                            <mat-option value="count">CLAIMS - count</mat-option>
                            <mat-option value="partCostsOKH">COSTS - spare parts (OKH)</mat-option>
                            <mat-option value="partCosts">COSTS - spare parts</mat-option>
                            <mat-option value="repairCosts">COSTS - Repair costs</mat-option>
                            <mat-option value="otherCosts">COSTS - Other costs</mat-option>
                            <mat-option value="totalCosts">COSTS - Total costs</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex="250px">
                        <mat-label>Date field</mat-label>
                        <mat-select [(value)]="report.histogramField">
                            <mat-option value="repairDate">REPAIR INFO - Repair date</mat-option>
                            <mat-option value="creationDate">VERSION - Creation date</mat-option>
                            <mat-option value="acceptionDate">APPROVAL - Acception date</mat-option>
                            <mat-option value="manufactureDate">PRODUCT - Manufacture date</mat-option>
                            <mat-option value="deliveryDate">PRODUCT - Delivery date</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field fxFlex="250px">
                        <mat-label>Time interval</mat-label>
                        <mat-select [(value)]="report.histogramInterval">
                            <mat-option value="day">Day</mat-option>
                            <mat-option value="week">Week</mat-option>
                            <mat-option value="month">Month</mat-option>
                            <mat-option value="year">Year</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!--
                <div fxLayout="row" fxLayoutGap="20px">
                    <mat-form-field>
                        <mat-label>Range field</mat-label>
                        <mat-select [(value)]="report.range.field">
                            <mat-option value="repairDate">CLAIM - Repair date</mat-option>
                            <mat-option value="creationDate">CLAIM - Creation date</mat-option>
                            <mat-option value="acceptionDate">CLAIM - Acception date</mat-option>
                            <mat-option value="manufactureDate">PRODUCT - Manufacture date</mat-option>
                            <mat-option value="deliveryDate">PRODUCT - Delivery date</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>Start date</mat-label>
                        <input matInput [(ngModel)]="report.range.start">
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>End date</mat-label>
                        <input matInput [(ngModel)]="report.range.end">
                    </mat-form-field>
                </div>
                -->

                <div fxLayout="row" fxLayoutGap="20px">
                    <fieldset fxFlex>
                        <legend>Filters</legend>
                        <div fxLayout="row" *ngFor="let filter of report.filters; let i = index">
                            <div fxFlex>
                                <div fxFlex="300px">
                                    {{ filter.name }}<br>
                                    <div class="weak">{{ filter.field}}</div>
                                </div>
                                <div fxFlex="20px">=</div>
                                <div *ngIf="filter.type === 'match'" fxFlex="300px">
                                    {{ filter.matchValue }}
                                </div>
                                <div *ngIf="filter.type === 'should'" fxFlex="300px">
                                    (<span *ngFor="let shouldValue of filter.shouldValues; let i = index"><span *ngIf="i > 0"> / </span>{{ shouldValue }}</span>)
                                </div>
                                <div *ngIf="filter.type === 'range'" fxFlex="300px">
                                    {{ filter.rangeStart }} -<br>
                                    {{ filter.rangeEnd }}
                                </div>
                                <div fxFlex></div>
                            </div>
                            <button mat-icon-button mat-raised-button color="warn" (click)="removeReportFilter(i)">
                                <mat-icon>remove</mat-icon>
                            </button>
                        </div>

                        <div fxFlex fxLayout="row" fxLayoutGap="10px">
                            <mat-form-field fxFlex="250px">
                                <mat-label>Field</mat-label>
                                <mat-select [(value)]="selectFilter">
                                    <mat-option *ngFor="let filter of filters" [value]="filter">
                                        <div fxFlex>{{ filter.name }}</div>
                                        <small *ngIf="filter.type === 'range'"><i>{{ filter.type }}</i></small>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <ng-container *ngIf="selectFilter && selectFilter.type === 'match'">
                                <mat-form-field fxFlex>
                                    <mat-label>Value</mat-label>
                                    <input *ngIf="!selectFilter.values" matInput [(ngModel)]="selectFilterValue">
                                    <mat-select *ngIf="selectFilter.values" [(value)]="selectFilterValue" multiple>
                                        <mat-option *ngFor="let value of selectFilter.values" [value]="value">{{ value }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            <ng-container *ngIf="selectFilter && selectFilter.type === 'range'">
                                <war-datepicker
                                [(ngModel)]="selectFilterStart"
                                [label]="'Start date'"></war-datepicker>
                                <war-datepicker
                                [(ngModel)]="selectFilterEnd"
                                [label]="'End date'"></war-datepicker>
                                <div fxFlex></div>
                            </ng-container>

                            <button *ngIf="selectFilter && (selectFilterValue || (selectFilterStart && selectFilterEnd))" mat-icon-button mat-raised-button color="accent" (click)="addReportFilter()">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                    </fieldset>
                </div>

                <div fxLayout="row">
                    <button mat-button mat-raised-button color="accent" (click)="getClaimsHistogram()">
                        Update
                    </button>
                    <div fxFlex></div>
                </div>
            </div>
        </fieldset>

        <fieldset fxFlex>
            <legend>Histogram</legend>
            <div *ngIf="chart">
                <div [chart]="chart" class="chart"></div>
            </div>

            <!--
            <div class="debug">
                <p>Results</p>
                <pre>
                    {{resultsHistogram | json}}
                </pre>
            </div>
            -->
        </fieldset>

</mat-tab>
</mat-tab-group>
    </div>
<!--
    <div class="debug">
        <p>Results</p>
        <pre>
            {{results | json}}
            </pre>
    </div>
-->
<!--
    <div class="debug">
        <p>Parts ({{ parts.length }})</p>
        <pre>
        {{parts | json}}
        </pre>
    </div>
-->

</div>

<!-- ACTIONBAR BUTTONS -->
<ks-actionbar
        [hasCancel]="false"
        [hasSave]="false"
>
