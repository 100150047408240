import * as moment from 'moment';

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig, MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

import { StateService } from 'src/app/services/state.service';
import { AuthService } from 'src/app/services/auth.service';
import { Permissions, UserProfile } from 'src/app/models/user';

import { Company, Companies, CompanyCodes } from 'src/app/models/company';
import { Claim } from 'src/app/models/claim';
import { ClaimsService } from 'src/app/services/claims.service';
import { LocaleService } from 'src/app/services/locale.service';
import { ExcelService } from 'src/app/services/excel.service';

import { ConfirmDialogComponent } from 'src/app/shared/dialogs/components/confirm-dialog/confirm-dialog.component';
import { InvoiceDialogComponent } from 'src/app/shared/dialogs/components/invoice-dialog/invoice-dialog.component';

import * as _ from 'lodash';

@Component({
    selector: 'app-list-claims-page',
    templateUrl: './list-claims-page.component.html',
    styleUrls: ['./list-claims-page.component.scss']
})
export class ListClaimsPageComponent implements OnInit {

    /**
     * Warranty claim objects
     */
    claims: Array<Claim> = [];

    /**
     * Claims count
     */
    resultHits: number;

    /**
     * Claims total count
     */
    totalHits: number;

    /**
     * Available company codes suchs as '100', '109', '130'...
     */
    companyCodes: Array<string>;

    /**
     * List of companies
     */
    companies: Array<Company> = [];

    /**
     * Search parameters, passed to search
     */
    searchParams: any = [];

    /**
     * Saved "favorite" search parameters
     * [{ name: '', searchParams: [] }, {}]
     */
    savedSearchParams: any = [];

    /**
     * Name for "favorite" search parameters when saving
     */
    savedSearchName: string;

    /**
     * Number of claims returned with search
     */
    searchSize: number;

    /**
     * Number where to start searching claims
     */
    searchFrom: number;

    /**
     * Permission to create new claim, check from auth service 'claims.write' action
     */
    canCreate = false;

    /**
     * Show action buttons toggler. (Actions are hidden on small mobile view)
     */
    showActions = false;

    /**
     * Show export actions buttons toggler.
     */
    showExportActions = false;

    /**
     * User permissions, same as authService.userProfile.permissions
     */
    permissions: Permissions = {
        scope: '',
        modules: {},
        actions: {}
    };

    // TODO: Convert to more clearer, booleans or something
    authUserScope = 'partner';
    authUserRole = 'partner';

    /**
     * Filter by company
     */
    claimCompanyNumber: Array<string>;

    /**
     * Filter by state
     */
    claimState: Array<string>;

    /**
     * Filter by state internal
     */
    claimStateInternal: Array<string>;

    /**
     * Filter by state invoiced
     */
    claimStateInvoiced: Array<string>;

    /**
     * Filter by state invoicedInternal
     */
    claimStateInvoicedInternal: Array<string>;

    /**
     * Filter by state logistics
     */
    claimStateLogistics: Array<string>;

    /**
     * Filter by closed status
     */
    claimStateClosed: Array<boolean>;

    /**
     * Filter by repair time start from
     */
    claimRepairDateStart: string;

    /**
     * Filter by repair time end to
     */
    claimRepairDateEnd: string;

    /**
     * Filter chips
     */
    filterChips = [];

    /**
     * Current search bar value
     */
    searchText = '';

    /**
     * Current search value behind the text
     */
    searchValue = '';

    /**
     * Filter names mapping, (field path: translation key)
     */
    filterNames = {
        'id': 'claims.claim_id',
        'partnerName': 'common.partner_name',
        'partnerName.normalize': 'common.partner_name',
        'data.customer.companyName': 'common.end_customer',
        'data.customer.companyName.normalize': 'common.end_customer',
        'data.products.serialNumber': 'common.serial_number',
        'data.products.productId': 'common.product_number',
        'data.products.productName': 'common.product_name',
        'data.invoice.reference': 'claims.invoice_reference_number',
        'data.invoice.internalReference': 'claims.invoice_internal_reference_number',
        'data.invoice.salesOrderNumber': 'claims.sales_order_number'
    };

    /**
     * All claim rows selected
     */
    allClaimsChecked: boolean;

    /**
     * Collection of claim id's that are checked
     */
    selectedClaims: object;

    // Selected claims and action button flags
    anyClaimSelected: boolean;
    claimsCannotBeInvoiced: boolean;
    claimsCannotBeInvoicedInternal: boolean;
    claimsCannotBeRefunded: boolean;
    claimsCannotBeRefundedInternal: boolean;
    claimsCannotBeHandledByLogistics: boolean;
    claimsCannotBeExportedByLogistics: boolean;

    invoicing: boolean;

    invoicingCosts: {
        currency: string;
        internalCurrency: string;
        partTransferCosts: number;
        totalTransferCosts: number;
        partCosts: number;
        repairCosts: number;
        otherCosts: number;
        totalCosts: number;
    };

    claimsLoading: boolean;

    sortParams: any = {
        key: 'data.repairDate',
        direction: 'desc'
    };

    showPrices: boolean;

    showSavedFilters: boolean;

    user: UserProfile;
    isDistributor: boolean;

    constructor(
        private router: Router,
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        private authService: AuthService,
        public stateService: StateService,
        private localeService: LocaleService,
        private translateService: TranslateService,
        private claimsService: ClaimsService,
        private excelService: ExcelService
    ) {
        this.companyCodes = CompanyCodes;
        this.companies = Companies;
        this.stateService.state.hasBack = false;
        this.stateService.state.hasMenu = true;
    }

    ngOnInit() {
        this.invoicing = false;
        this.invoicingCosts = {
            currency: '',
            internalCurrency: '',
            partTransferCosts: 0,
            totalTransferCosts: 0,
            partCosts: 0,
            repairCosts: 0,
            otherCosts: 0,
            totalCosts: 0
        };

        setTimeout(() => {
            this.stateService.state.file = '';

            this.claimCompanyNumber = [];
            this.claimState = [];
            this.claimStateInternal = [];
            this.claimStateInvoiced = [];
            this.claimStateInvoicedInternal = [];
            this.claimStateLogistics = [];
            this.claimStateClosed = [];
            this.claimRepairDateStart = '';
            this.claimRepairDateEnd = '';

            this.selectedClaims = {};

            this.user = this.authService.userProfile;
            this.permissions = this.user.permissions;
            this.authUserScope = this.user.permissions.scope;
            this.authUserRole = this.user.role;
            this.isDistributor = (this.user.company === '100' && !this.user.partnerId.startsWith('FI') && this.companyCodes.indexOf(this.user.partnerId) === -1) ? true : false;

            this.showPrices = this.user.showPrices || false;

            this.showSavedFilters = false;

            // Size
            this.searchSize = 50;
            // From
            this.searchFrom = 0;

            // Reload filters
            // Check url search for specific partner
            // const id = this.activatedRoute.snapshot.params['id'];
            // if (id) {
            //     this.searchParams.push({ key: 'id', value: `*${id}*` });
            //     this.searchParamsInStorage = this.searchParams;
            // }
            // Filters are stored to browser local storage and reloaded here.
            this.searchParams = this.searchParamsInStorage || [];
            this.savedSearchParams = this.savedSearchParamsInStorage || [];
            if (this.savedSearchParams.length > 0) {
                this.showSavedFilters = true;
            }
            this.savedSearchName = '';
            this.reloadFilters();

            this.canCreate = this.authService.isAllowedTo('claims.write');
            if (this.authUserRole === 'koy_logistics') {
                this.canCreate = false;
            }

            this.newSearch();
        });
    }

    reloadFilters() {
        // DROPDOWNS
        if (this.permissions.scope === 'company' || this.permissions.scope === 'global') {
            const claimState: any = _.find(this.searchParams, { key: 'state.currentState' });
            this.claimState = claimState ? claimState.value : [];
        }
        if (this.permissions.scope === 'global') {
            const claimCompanyNumber: any = _.find(this.searchParams, { key: 'company' });
            this.claimCompanyNumber = claimCompanyNumber ? claimCompanyNumber.value : [];
            const claimStateInternal: any = _.find(this.searchParams, { key: 'state.internalState' });
            this.claimStateInternal = claimStateInternal ? claimStateInternal.value : [];
            const claimStateInvoiced: any = _.find(this.searchParams, { key: 'state.invoiceState' });
            this.claimStateInvoiced = claimStateInvoiced ? claimStateInvoiced.value : [];
            const claimStateInvoicedInternal: any = _.find(this.searchParams, { key: 'state.internalInvoiceState' });
            this.claimStateInvoicedInternal = claimStateInvoicedInternal ? claimStateInvoicedInternal.value : [];
            const claimStateLogistics: any = _.find(this.searchParams, { key: 'state.logisticsState' });
            this.claimStateLogistics = claimStateLogistics ? claimStateLogistics.value : [];
            const claimStateClosed: any = _.find(this.searchParams, { key: 'state.closed' });
            this.claimStateClosed = claimStateClosed ? claimStateClosed.value : [];
        }
        // Repair date range 2019-01-01T00:00:00.000Z#2019-01-01T23:59:59.999Z
        const claimRepairDateRange: any = _.find(this.searchParams, { key: 'data.repairDate' });
        const claimRepairDate = claimRepairDateRange ? claimRepairDateRange.value.split('#') : null;
        this.claimRepairDateStart = claimRepairDate ? claimRepairDate[0] : '';
        this.claimRepairDateEnd = claimRepairDate ? claimRepairDate[1] : '';

        // CHIPS
        // Check if filter is found from filterNames then it is filter chip
        const searchChipParams = _.filter(this.searchParams, (p) => {
            return typeof(this.filterNames[p.key]) !== 'undefined';
        });
        this.filterChips = [];
        for (const searchChipParam of searchChipParams) {
            this.filterChips.push({ key: searchChipParam.key, text: searchChipParam.value.replace(/[*]/g, '') });
        }
        // this.filterChips = [];
        // const filterChip: any = _.find(this.searchParams, { key: 'id' });
        // if (filterChip && filterChip.value) {
        //     this.filterChips.push({ key: 'id', text: filterChip.value.replace(/[*]/g, '') });
        // }
    }

    sort(sortKey) {
        console.log('sort', sortKey);
        const currentSortObj = this.sortParams || null;
        let sortDirection = 'desc';
        if (currentSortObj && currentSortObj.key && currentSortObj.key === sortKey) {
            sortDirection = this.sortParams.direction === 'desc' ? 'asc' : 'desc';
        }
        this.sortParams = {
            key: sortKey,
            direction: sortDirection
        };
        this.newSearch();
    }

    newSearch() {
        this.totalHits = 0;
        this.resultHits = 0;
        this.claims = [] as Array<Claim>;
        this.searchSize = 50;
        this.searchFrom = 0;
        this.getClaims();
    }

    getClaims() {
        this.claimsLoading = true;
        // According to user permission get all (global) / company / partner claims
        if (this.permissions.scope === 'global') {
            // GLOBAL
            this.claimsService.searchAll(this.searchParams, this.searchSize, this.searchFrom, [this.sortParams]).subscribe((response: any) => {
                if (typeof response.items !== 'undefined' && typeof response.totalCount !== 'undefined') {
                    this.claims.push(...response.items);
                    this.totalHits = response.totalCount;
                } else {
                    this.claims = response;
                }
                this.resultHits = this.claims.length;
                this.resetSelectedClaims();
                this.resetTotalCosts();
                this.claimsLoading = false;
            }, (error) => {
                this.claimsLoading = false;
            });
        } else if (this.permissions.scope === 'company') {
            // COMPANY
            this.claimsService.searchByCompany(this.authService.userProfile.company, this.searchParams, this.searchSize, this.searchFrom, [this.sortParams])
                .subscribe((response: any) => {
                    if (typeof response.items !== 'undefined' && typeof response.totalCount !== 'undefined') {
                        this.claims.push(...response.items);
                        this.totalHits = response.totalCount;
                    } else {
                        this.claims = response;
                    }
                    this.resultHits = this.claims.length;
                    this.resetSelectedClaims();
                    this.resetTotalCosts();
                    this.claimsLoading = false;
                }, (error) => {
                    this.claimsLoading = false;
                });
        } else if (this.permissions.scope === 'partner') {
            // PARTNER
            this.claimsService.searchByPartner(this.authService.userProfile.company, this.authService.userProfile.partnerId, this.searchParams, this.searchSize, this.searchFrom, [this.sortParams])
                .subscribe((response: any) => {
                    if (typeof response.items !== 'undefined' && typeof response.totalCount !== 'undefined') {
                        this.claims.push(...response.items);
                        this.totalHits = response.totalCount;
                    } else {
                        this.claims = response;
                    }
                    this.resultHits = this.claims.length;
                    this.resetSelectedClaims();
                    this.resetTotalCosts();
                    this.claimsLoading = false;
                }, (error) => {
                    this.claimsLoading = false;
                });
        }
    }

    loadMore() {
        this.searchFrom = this.searchFrom + this.searchSize;
        this.searchSize = 50;
        this.getClaims();
    }


    setFilter(key, value) {
        if (value === '' || value.length === 0) {
            this.removeFilter(key);
        } else {
            // Find item index
            const index = _.findIndex(this.searchParams, { key: key });
            if (index !== -1) {
                // Replace item at index
                this.searchParams.splice(index, 1, { key: key, value: value });
            } else {
                // Add new item
                this.searchParams.push({ key: key, value: value });
            }
            this.newSearch();
        }
        this.searchParamsInStorage = this.searchParams;
    }
    removeFilter(key) {
        // Find item index
        const index = _.findIndex(this.searchParams, { key: key });
        if (index !== -1) {
            // Remove item at index
            this.searchParams.splice(index, 1);
        }
        this.newSearch();
        this.searchParamsInStorage = this.searchParams;
    }

    searchKeyEvent(event) {
        // backspace
        if (event.keyCode === 8 && this.searchText === '') {
            this.removeFilterChip(this.filterChips.length - 1);
        }
    }

    searchValueUpdate() {
        const value = this.searchText.trim();
        this.searchValue = value;
    }

    addFilterChip(key, value) {
        if (value.trim() !== '') {
            const val = key === 'id' ? value.toUpperCase() : value;
            this.filterChips.push({ key: key, text: val });
            this.setFilter(key, `*${val}*`);
            this.searchText = '';
            this.searchValue = '';
        }
    }

    removeFilterChip(index) {
        if (this.filterChips[index]) {
            const key = this.filterChips[index].key;
            this.filterChips.splice(index, 1);
            this.removeFilter(key);
        }
    }

    resetSearchParams() {
        this.claimCompanyNumber = [];
        this.claimState = [];
        this.claimStateInternal = [];
        this.claimStateInvoiced = [];
        this.claimStateInvoicedInternal = [];
        this.claimStateLogistics = [];
        this.claimStateClosed = [];
        this.claimRepairDateStart = '';
        this.claimRepairDateEnd = '';
        this.searchParams = [];
        this.searchParamsInStorage = [];
        this.filterChips = [];
        this.savedSearchName = '';
        this.newSearch();
    }

    get searchParamsInStorage(): object {
        try {
            const paramsString = localStorage.getItem('claims_search_params');
            console.log('searchParamsInStorage', paramsString);
            return JSON.parse(paramsString);
        } catch (e) {
            return [];
        }
    }

    set searchParamsInStorage(searchParams: object) {
        localStorage.setItem('claims_search_params', JSON.stringify(searchParams));
    }

    get savedSearchParamsInStorage() {
        try {
            const paramsString = localStorage.getItem('claims_saved_search_params');
            return JSON.parse(paramsString);
        } catch (e) {
            return [];
        }
    }

    set savedSearchParamsInStorage(savedSearchParams) {
        localStorage.setItem('claims_saved_search_params', JSON.stringify(savedSearchParams));
    }

    loadSearchParams(name) {
        console.log('loadSearchParams', name);
        const index = _.findIndex(this.savedSearchParams, { name: name });
        if (index > -1) {
            this.searchParams = _.cloneDeep(this.savedSearchParams[index].searchParams);
            this.savedSearchName = _.cloneDeep(this.savedSearchParams[index].name);
            this.searchParamsInStorage = this.searchParams;
            this.reloadFilters();
            this.newSearch();
        }
    }

    saveSearch() {
        const savedSearch = {
            name: _.cloneDeep(this.savedSearchName),
            searchParams: _.cloneDeep(this.searchParams)
        };
        const index = _.findIndex(this.savedSearchParams, { name: this.savedSearchName });
        if (index > -1) {
            this.savedSearchParams[index] = savedSearch;
        } else {
            this.savedSearchParams.push(savedSearch);
        }
        this.savedSearchParamsInStorage = this.savedSearchParams;
    }

    removeSavedSearch() {
        const index = _.findIndex(this.savedSearchParams, { name: this.savedSearchName });
        if (index > -1) {
            this.savedSearchParams.splice(index, 1);
            this.savedSearchParamsInStorage = this.savedSearchParams;
            this.savedSearchName = '';
        }
    }


    openClaim(company, partnerId, claimId) {
        this.router.navigate(['claims', company, partnerId, claimId]);
    }

    createNewClaim() {
        const user = this.authService.userProfile;
        this.router.navigate(['claims', user.company, user.partnerId, 'new']);
    }

    /**
     * Reset selected claims / deselect all selections
     */
    resetSelectedClaims() {
        this.allClaimsChecked = false;
        this.selectedClaims = {};
        for (const claim of this.claims) {
            this.selectedClaims[claim.id] = false;
        }
        this.checkSelectedClaims();
    }

    /**
     * Check or uncheck allClaimsChecked
     */
    selectAllClaims(checked) {
        _.map(this.claims, (claim) => {
            this.selectedClaims[claim.id] = checked;
        });
        this.checkSelectedClaims();
    }

    /**
     * Check selected claims
     * Raise flags to disable action buttons if action is not allowed
     */
    checkSelectedClaims() {
        this.anyClaimSelected = _.some(_.values(this.selectedClaims), (value) => value === true);
        if (this.anyClaimSelected) {
            const selectedClaims = _.filter(this.claims, (claim) => {
                return this.selectedClaims[claim.id] ? true : false;
            });

            this.claimsCannotBeInvoiced = _.some(selectedClaims, (claim) => {
                return claim.state.closed
                    || claim.state.currentState !== 'ACCEPTED'
                    || claim.state.invoiceState !== ''
                    || this.companyCodes.indexOf(claim.partnerId) !== -1;
            });
            this.claimsCannotBeInvoicedInternal = _.some(selectedClaims, (claim) => {
                return claim.state.closed
                    || claim.company === '100'
                    || claim.state.currentState !== 'ACCEPTED'
                    || claim.state.internalState !== 'ACCEPTED'
                    || claim.state.internalInvoiceState !== '';
            });
            this.claimsCannotBeRefunded = _.some(selectedClaims, (claim) => {
                return claim.state.closed
                    || claim.state.currentState !== 'ACCEPTED'
                    || claim.state.invoiceState === ''
                    || claim.state.invoiceState === 'REFUNDED';
            });
            this.claimsCannotBeRefundedInternal = _.some(selectedClaims, (claim) => {
                return claim.state.closed
                    || claim.company === '100'
                    || claim.state.currentState !== 'ACCEPTED'
                    || claim.state.internalState !== 'ACCEPTED'
                    || claim.state.internalInvoiceState === ''
                    || claim.state.internalInvoiceState === 'REFUNDED';
            });
            this.claimsCannotBeHandledByLogistics = _.some(selectedClaims, (claim) => {
                return claim.state.closed
                    || claim.state.currentState !== 'ACCEPTED'
                    || claim.state.internalState !== 'ACCEPTED'
                    || claim.state.logisticsState === ''
                    || claim.state.logisticsState === 'HANDLED';
            });
            // Claims exported to logistics xlsx must be from same partner/company
            this.claimsCannotBeExportedByLogistics = _.some(selectedClaims, (claim) => {
                return claim.partnerId !== selectedClaims[0].partnerId
                    || claim.company !== selectedClaims[0].company;
            });

            this.calculateTotalCosts();
        }
    }

    /**
     * Reset total costs
     */
    resetTotalCosts() {
        this.invoicingCosts = {
            currency: '',
            internalCurrency: '',
            partTransferCosts: 0,
            totalTransferCosts: 0,
            partCosts: 0,
            repairCosts: 0,
            otherCosts: 0,
            totalCosts: 0
        };
    }

    /**
     * Calculate total costs
     * Shown on invoicing last row
     */
    calculateTotalCosts() {
        this.resetTotalCosts();
        // Currency check, multiple currencies cannot be calculated
        if (this.anyClaimSelected) {
            const selectedClaims = _.filter(this.claims, (claim) => {
                return this.selectedClaims[claim.id] ? true : false;
            });

            for (const claim of selectedClaims) {
                if (_.get(claim, 'data.costs') && _.get(claim, 'data.currency.internal')
                && (this.invoicingCosts.internalCurrency === '' || this.invoicingCosts.internalCurrency === claim.data.currency.internal.code)) {
                    this.invoicingCosts.internalCurrency = claim.data.currency.internal.code; // First claim sets the currency
                    this.invoicingCosts.partTransferCosts += (claim.data.costs.partTransferCosts || 0);
                    this.invoicingCosts.totalTransferCosts += (claim.data.costs.totalTransferCosts || 0);
                } else if (_.get(claim, 'data.costs') && _.get(claim, 'data.currency')
                && (this.invoicingCosts.internalCurrency === '' || this.invoicingCosts.internalCurrency === claim.data.currency.code)) {
                    this.invoicingCosts.internalCurrency = claim.data.currency.code; // First claim sets the currency
                    this.invoicingCosts.partTransferCosts += (claim.data.costs.partTransferCosts || 0);
                    this.invoicingCosts.totalTransferCosts += (claim.data.costs.totalTransferCosts || 0);
                } else {
                    this.invoicingCosts.internalCurrency = 'N/A';
                    this.invoicingCosts.partTransferCosts = 0;
                    this.invoicingCosts.totalTransferCosts = 0;
                }

                if (_.get(claim, 'data.costs') && _.get(claim, 'data.currency')
                    && (this.invoicingCosts.currency === '' || this.invoicingCosts.currency === claim.data.currency.code)) {
                    this.invoicingCosts.currency = claim.data.currency.code; // First claim sets the currency
                    this.invoicingCosts.partCosts += (claim.data.costs.partCosts || 0);
                    this.invoicingCosts.repairCosts += (claim.data.costs.repairCosts || 0);
                    this.invoicingCosts.otherCosts += (claim.data.costs.otherCosts || 0);
                    this.invoicingCosts.totalCosts += (claim.data.costs.totalCosts || 0);
                } else {
                    this.invoicingCosts.currency = 'N/A';
                    this.invoicingCosts.partCosts = 0;
                    this.invoicingCosts.repairCosts = 0;
                    this.invoicingCosts.otherCosts = 0;
                    this.invoicingCosts.totalCosts = 0;
                }
            }
        }
    }

    /**
     * Returns selected claims header infos
     */
    getSelectedClaimsForBulk() {
        const items = _.map(
            _.filter(this.claims, (claim) => {
                return this.selectedClaims[claim.id] ? true : false;
            }),
            (claim) => {
                return {
                    id: claim.id,
                    company: claim.company,
                    partnerId: claim.partnerId
                };
            }
        );
        return items;
    }

    /**
     * Open confirm dialog to ask reference numbers for claims
     * - invoiced
     * - invoicedInternal
     * - logistics
     */
    openInvoiceDialog(type = 'invoiced') {
        const data = {
            type: type,
            invoiceReference: ''
        };
        const dialogConfig: MatDialogConfig = {
            data: data
        };

        const dialogRef = this.dialog.open(InvoiceDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            console.log('Invoice dialog close: ', result, data.invoiceReference);
            if (result === 'ok') {
                console.log('INVOICE REFERENCE', data.invoiceReference);

                const items = this.getSelectedClaimsForBulk();
                this.claimsService.bulkApproveClaims(type, 'add', items, data.invoiceReference).subscribe((response: any) => {
                    if (response && response.success) {
                        this.notify('claims.notifications.claims_updated');
                        setTimeout(() => {
                            this.newSearch();
                        }, 3000);
                    }
                });
            }
        });
    }

    /**
     * Open confirm dialog for closing the claims
     */
    openRefundConfirmDialog(internal = false) {
        const type = internal ? 'refundedInternal' : 'refunded';
        const dialogConfig: MatDialogConfig = {
            data: {
                title: 'confirm_dialog.refund_claims',
                content: 'confirm_dialog.are_you_sure_you_want_to_refund_selected_claims'
            }
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            if (result === 'ok') {
                const items = this.getSelectedClaimsForBulk();
                this.claimsService.bulkApproveClaims(type, 'add', items).subscribe((response: any) => {
                    if (response && response.success) {
                        this.notify('claims.notifications.claims_updated');
                        setTimeout(() => {
                            this.newSearch();
                        }, 2000);
                    }
                });
            }
        });
    }

    /**
     * Open confirm dialog for closing the clais
     */
    openCloseConfirmDialog() {
        const type = 'closed';
        const dialogConfig: MatDialogConfig = {
            data: {
                title: 'confirm_dialog.close_claims',
                content: 'confirm_dialog.are_you_sure_you_want_to_close_selected_claims'
            }
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            if (result === 'ok') {
                const items = this.getSelectedClaimsForBulk();
                this.claimsService.bulkApproveClaims(type, 'add', items).subscribe((response: any) => {
                    if (response && response.success) {
                        this.notify('claims.notifications.claims_updated');
                        setTimeout(() => {
                            this.newSearch();
                        }, 2000);
                    }
                });
            }
        });
    }

    /**
     * Export selected claims to Excel XLSX format
     */
    async exportSelectedClaimsToXLSX() {
        const claims = await this._getSelectedClaimsForExport();
        const parts = await this._getClaimsForPartsExport();
        const sheets = [
            { name: 'claims', json: claims },
            { name: 'parts', json: parts }
        ];
        this.excelService.exportAsExcelFileMultisheet(sheets, 'warranty_claims');
    }

    /**
     * Get export data
     * Depends on user role / scope / permissions
     */
    async _getSelectedClaimsForExport() {
        const items = _.map(
            _.filter(this.claims, (claim) => {
                return this.selectedClaims[claim.id] ? true : false;
            }),
            (claim) => {
                let claimRow = {
                    'Claim id': claim.id,
                    'Repair date': moment(claim.data.repairDate).format('YYYY-MM-DD'),
                    'Serial': _.get(claim, 'data.products[0].serialNumber'),
                    'Product id': _.get(claim, 'data.products[0].productId'),
                    'Product name': _.get(claim, 'data.products[0].productName'),
                    'Status': claim.state.currentState,
                    'Status closed': claim.state.closed,
                    'Status invoice': claim.state.invoiceState,
                    'Invoice ref': _.get(claim, 'data.invoice.reference')
                };
                if (this.showPrices) {
                    claimRow = _.merge(claimRow, {
                        'Parts': claim.data.costs.partCosts,
                        'Repairs': claim.data.costs.repairCosts,
                        'Others': claim.data.costs.otherCosts,
                        'Total': claim.data.costs.totalCosts
                    });
                }
                if (this.authUserScope === 'company' || this.authUserScope === 'global') {
                    claimRow = _.merge(claimRow, {
                        'Part transfer costs': claim.data.costs.partTransferCosts,
                        'Total transfer costs': claim.data.costs.totalTransferCosts,
                        'Status internal': claim.state.internalState,
                        'Status invoice internal': claim.state.internalInvoiceState,
                        'Invoice ref internal': _.get(claim, 'data.invoice.internalReference'),
                        'Partner id': claim.partnerId,
                        'Partner name': claim.partnerName,
                    });
                }
                if (this.authUserScope === 'global') {
                    claimRow = _.merge(claimRow, {
                        'Company': claim.company,
                        'Status logistics': claim.state.logisticsState,
                        'Sales order number': _.get(claim, 'data.invoice.salesOrderNumber'),
                    });
                }
                return claimRow;
            }
        );
        return items;
    }

    async _getClaimsForPartsExport() {
        // Filter selected claims
        const selectedClaims = _.filter(this.claims, (claim) => {
            return this.selectedClaims[claim.id] ? true : false;
        });

        // Fetch full claim objects
        const promises = [];
        for (const selectedClaim of selectedClaims) {
            promises.push(this.claimsService.getClaim(selectedClaim.company, selectedClaim.partnerId, selectedClaim.id).toPromise());
        }
        let claims = [];
        await Promise.all(promises).then(fetchedClaims => {
            claims = fetchedClaims;
        });
        const claimIds = _.map(claims, 'id');
        console.log('claims', claimIds);

        const parts = [];
        const totals = {};
        for (const claim of claims) {
            if (claim && claim.data && claim.data.parts) {
                for (const part of claim.data.parts) {
                    parts.push({
                        'Claim id': claim.id,
                        'Part number': part.partNumber,
                        'Quantity': part.quantity,
                    });
                    // Totals
                    if (totals[part.partNumber]) {
                        totals[part.partNumber] += part.quantity;
                    } else {
                        totals[part.partNumber] = part.quantity;
                    }
                }
            }
        }
        parts.push({ 'Claim id': '' });
        parts.push({ 'Claim id': 'Totals' });
        for (const key in totals) {
            if (Object.prototype.hasOwnProperty.call(totals, key)) {
                parts.push({
                    'Part number': key,
                    'Quantity': totals[key]
                });
            }
        }
        console.log('parts', parts);
        return parts;
    }

    /**
     * Export selected claims to Logistics Excel XLSX format
     */
    async exportSelectedClaimsToLogisticsXLSX() {
        const data = await this._getClaimsForLogisticsExport();
        this.excelService.exportAsExcelFile(data, 'warranty_claims');
    }

    async _getClaimsForLogisticsExport() {
        // Filter selected claims
        const selectedClaims = _.filter(this.claims, (claim) => {
            return this.selectedClaims[claim.id] ? true : false;
        });

        // Fetch full claim objects
        const promises = [];
        for (const selectedClaim of selectedClaims) {
            promises.push(this.claimsService.getClaim(selectedClaim.company, selectedClaim.partnerId, selectedClaim.id).toPromise());
        }
        let claims = [];
        await Promise.all(promises).then(fetchedClaims => {
            claims = fetchedClaims;
        });
        const claimIds = _.map(claims, 'id');
        console.log('claims', claimIds);

        const partnerInfo = [
            {
                'Row number': '- - - - - - - -',
                'Part number': '- - - - - - - -',
                'Quantity': '- - - - - - - -',
                'Claims': '- - - - - - - -',
                'Company': selectedClaims[0].company,
                'Partner id': selectedClaims[0].partnerId,
                'Partner name': selectedClaims[0].partnerName,

            }
        ];
        console.log('partnerInfo', partnerInfo);

        const parts = [];
        let rowNumber = 1;
        for (const claim of claims) {
            if (claim && claim.data && claim.data.parts) {
                for (const part of claim.data.parts) {
                    const index = _.findIndex(parts, { 'Part number': part.partNumber });
                    if (index >= 0) {
                        parts[index]['Quantity'] += part.quantity;
                        parts[index]['Claims'] = `${parts[index]['Claims']}, ${claim.id}`;
                    } else {
                        parts.push({
                            'Row number': rowNumber,
                            'Part number': part.partNumber,
                            'Quantity': part.quantity,
                            'Claims': claim.id
                        });
                        rowNumber += 1;
                    }
                }
            }
        }
        console.log('parts', parts);
        const data = partnerInfo.concat(parts);
        // data.push(parts);
        return data;
    }

    /**
     * Export selected claims to Logistics Excel XLSX format
     */
     async exportSelectedClaimsToFaultyXLSX() {
        const data = await this._getClaimsForFaultyExport();
        this.excelService.exportAsExcelFileMultisheet(data, 'warranty_claims');
    }

    async _getClaimsForFaultyExport() {
        // Filter selected claims
        const selectedClaims = _.filter(this.claims, (claim) => {
            return this.selectedClaims[claim.id] ? true : false;
        });

        // Fetch full claim objects
        const promises = [];
        for (const selectedClaim of selectedClaims) {
            promises.push(this.claimsService.getClaim(selectedClaim.company, selectedClaim.partnerId, selectedClaim.id).toPromise());
        }
        let claims = [];
        await Promise.all(promises).then(fetchedClaims => {
            claims = fetchedClaims;
        });
        const claimIds = _.map(claims, 'id');
        console.log('claims', claimIds);

        const items = _.map(
            _.filter(claims, (claim) => {
                return this.selectedClaims[claim.id] ? true : false;
            }),
            (claim) => {
                let claimRow = {
                    'Claim id': claim.id,
                    'Company': claim.company,
                    'Partner id': claim.partnerId,
                    'Partner name': claim.partnerName,
                    'Repair date': moment(claim.data.repairDate).format('YYYY-MM-DD'),
                    'Serial number': _.get(claim, 'data.products[0].serialNumber'),
                    'Product id': _.get(claim, 'data.products[0].productId'),
                    'Product name': _.get(claim, 'data.products[0].productName'),
                    'Status': claim.state.currentState,
                    'F code': _.get(claim, 'data.faultyDescription.code'),
                    'F version': _.get(claim, 'data.faultyDescription.version'),
                    'F category': _.get(claim, 'data.faultyDescription.category'),
                    'F type': _.get(claim, 'data.faultyDescription.type'),
                    'F section': _.get(claim, 'data.faultyDescription.section'),
                    'F reason': _.get(claim, 'data.faultyDescription.reason'),
                    'Additional information': _.get(claim, 'data.description'),
                    'Attachments': _.get(claim, 'data.attachments', []).length > 0
                }
                return claimRow;
            }
        );

        const parts = [];
        const totals = {};
        for (const claim of claims) {
            if (claim && claim.data && claim.data.parts) {
                for (const part of claim.data.parts) {
                    parts.push({
                        'Claim id': claim.id,
                        'Part number': part.partNumber,
                        'Quantity': part.quantity,
                        'Part description': part.partName,
                    });
                    // Totals
                    if (totals[part.partNumber]) {
                        totals[part.partNumber] += part.quantity;
                    } else {
                        totals[part.partNumber] = part.quantity;
                    }
                }
            }
        }
        parts.push({ 'Claim id': '' }); // Empty row
        parts.push({ 'Claim id': 'Totals' }); // Totals header row
        for (const key in totals) {
            if (Object.prototype.hasOwnProperty.call(totals, key)) {
                parts.push({
                    'Part number': key,
                    'Quantity': totals[key]
                });
            }
        }
        console.log('parts', parts);

        const sheets = [
            { name: 'claims', json: items },
            { name: 'parts', json: parts }
        ];

        return sheets;
    }


    /**
     * Toggle actions (buttons)
     * Shown only in extra small layout.
     */
     toggleActions() {
        this.showActions = !this.showActions;
    }
    /**
     * Toggle actions (buttons)
     * Shown only in extra small layout.
     */
    toggleExportActions() {
        this.showExportActions = !this.showExportActions;
    }

    /**
     * Toggle show/hide saved filters section on search section
     */
    toggleSavedFilters() {
        this.showSavedFilters = !this.showSavedFilters;
    }

    /**
     * Show snackbar notification
     */
    notify(translationKey, id = '', duration = 3000) {
        this.translateService.get(translationKey).subscribe(translation => {
            const message = _.join([translation, id], ' ');
            this.snackBar.open(message, 'OK', {
                duration: duration,
                panelClass: ['war-snackbar']
            });
        });
    }

    trackById(index, item) {
        return item.id;
    }
}
