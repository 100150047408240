import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { Repair } from '../models/repair';

declare var require: any;
const repairTimes = require('./repair-times.json');

// Dummy RepairTimes service

@Injectable()
export class RepairTimesService {

    repairTimes: Array<Repair>;

    constructor() {
        this.repairTimes = repairTimes;
    }

    getAll() {
        return this.repairTimes;
    }

    getRepairInfo(partNumber) {
        const repairInfo: any = _.find(this.repairTimes, { partNumber: partNumber });
        return repairInfo;
    }
}
