export const CurrencyCodes: Array<string> = [
    'AUD',
    'BRL',
    'CHF',
    'CNH',
    'CNY',
    'DKK',
    'EUR',
    'GBP',
    'HKD',
    'INR',
    'JPY',
    'MXN',
    'MYR',
    'NOK',
    'NZD',
    'PLN',
    'RUB',
    'SEK',
    'SGD',
    'TRY',
    'USD'
];
