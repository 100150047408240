
<div class="main">
    <div class="list-container">
        <div class="full main-header" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <mat-icon class="kemppi-symbols">è</mat-icon>
            <div fxLayout="column">
                <h2>{{ 'menu.product_registrations' | translate }}</h2>
            </div>
        </div>
        <div class="full" fxLayout="column" fxLayoutGap="20px">
            <h2 class="mat-subhead">{{ 'common.search' | translate }}</h2>
            <div fxLayout="row">
                <div fxLayout="column">
                    <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="start start">
                        <div fxFlex.gt-sm="620px" fxLayout="row" fxLayoutAlign="start center">
                            <mat-chip-list>
                                <mat-chip *ngFor="let chip of filterChips; let i = index" (click)="removeFilterChip(i)"><b>{{chip.text}}</b>&nbsp;<small class="weak">({{ filterNames[chip.key] | translate }})</small></mat-chip>
                            </mat-chip-list>

                            <mat-form-field fxFlex>
                                <input matInput [(ngModel)]="searchText" (keydown)="searchKeyEvent($event)" (keyup)="searchValueUpdate()" [matAutocomplete]="auto">
                                <span matPrefix><mat-icon class="kemppi-symbols avatar-icon">å</mat-icon></span>
                            </mat-form-field>
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addFilterChip($event.option.value, searchValue)">
                                <ng-container *ngIf="searchText">
                                    <mat-option value="id"><b>{{searchValue}}</b> - {{ 'common.serial_number' | translate }}</mat-option>
                                    <mat-option value="registeredEndCustomerName.normalize"><b>{{searchValue}}</b> - {{ 'common.end_customer' | translate }}</mat-option>
                                    <mat-option value="productId"><b>{{searchValue}}</b> - {{ 'common.product_number' | translate }}</mat-option>
                                    <mat-option value="partnerId" *ngIf="permissions.scope === 'global' || permissions.scope === 'company'"><b>{{searchValue}}</b> - {{ 'common.partner_id' | translate }}</mat-option>
                                </ng-container>
                            </mat-autocomplete>
                        </div>
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px">
                            <mat-form-field *ngIf="permissions.scope === 'global'" class="limited-access">
                                <mat-label>{{ 'common.company' | translate }}</mat-label>
                                <mat-select [(value)]="registrationCompanyNumber" (selectionChange)="setFilter('company', registrationCompanyNumber)" multiple>
                                    <mat-option value="">Any</mat-option>
                                    <mat-option *ngFor="let company of companies" [value]="company.id">{{ company.id }} - {{ company.name }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div>
                            <button mat-button color="warn" (click)="resetSearchParams()">
                                {{ 'claims.reset' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div fxLayoutAlign="start center">
            <div>
                <h2 class="mat-subhead">{{ 'menu.registrations' | translate }}</h2>
            </div>
        </div>
        <div fxLayoutAlign="start center">
            <div *ngIf="canCreate">
                <button mat-button mat-raised-button color="accent" (click)="createNewRegistration()">
                    <mat-icon class="kemppi-symbols">ř</mat-icon>{{ 'registrations.create_new_registration' | translate }}
                </button>
            </div>

            <div fxFlex></div>
        </div>

        <!--
        <div class="search-field" fxLayout="row">
            <div fxFlex fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="kemppi-symbols avatar-icon">å</mat-icon>
                <mat-chip-list>
                    <mat-chip *ngFor="let chip of filterChips; let i = index" (click)="removeFilterChip(i)">{{chip.text}}</mat-chip>
                </mat-chip-list>

                <mat-form-field fxFlex>
                    <mat-label>Search by serial number</mat-label>
                    <input matInput [(ngModel)]="searchText" (keydown)="addFilter($event, searchText)">
                </mat-form-field>
            </div>
        </div>

        <div fxLayout="row" fxLayoutGap="10px">
            <mat-form-field *ngIf="permissions.scope === 'global'" fxFlex="100px">
                <mat-label>{{ 'common.company' | translate }}</mat-label>
                <mat-select [(value)]="registrationCompanyNumber" (selectionChange)="setFilter('company', registrationCompanyNumber)">
                    <mat-option value="">Any</mat-option>
                    <mat-option *ngFor="let company of companies" [value]="company.id">{{ company.id }} - {{ company.name }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        -->

        <div class="list-header-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxFlex="40px">
                    <mat-checkbox [(ngModel)]="allRegistrationsChecked" (change)="selectAllRegistrations(allRegistrationsChecked)"></mat-checkbox>
                </div>
                <div fxShow.lt-sm="false" fxFlex="60px" *ngIf="permissions.scope === 'global'" class="limited-access" (click)="sort('company')">
                    {{ 'common.com' | translate }}
                    <mat-icon *ngIf="sortParams.key === 'company'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
                </div>
                <div fxShow.lt-sm="false" fxFlex="80px" *ngIf="permissions.scope === 'global' || permissions.scope === 'company'" class="limited-access" (click)="sort('registeredPartnerId')">
                    {{ 'common.partner' | translate }}
                    <mat-icon *ngIf="sortParams.key === 'registeredPartnerId'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
                </div>
                <div fxFlex="100px" (click)="sort('serialNumber')">
                    {{ 'common.serial_number' | translate }}
                    <mat-icon *ngIf="sortParams.key === 'serialNumber'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
                </div>
                <div fxFlex="110px" fxShow.lt-md="false" (click)="sort('productId')">
                    {{ 'common.product_number' | translate }}
                    <mat-icon *ngIf="sortParams.key === 'productId'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
                </div>
                <div fxFlex fxShow.lt-sm="false" (click)="sort('productInfo.searchPartDescription')">
                    {{ 'common.product_name' | translate }}
                    <mat-icon *ngIf="sortParams.key === 'productInfo.searchPartDescription'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
                </div>
                <div fxFlex="300px" fxShow.lt-sm="false" (click)="sort('registeredEndCustomerName')">
                    {{ 'common.customer' | translate }}
                    <mat-icon *ngIf="sortParams.key === 'registeredEndCustomerName'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
                </div>
                <div fxFlex="90px" (click)="sort('partnerRegistrationDate')">
                    {{ 'common.registration_date' | translate }}
                    <mat-icon *ngIf="sortParams.key === 'partnerRegistrationDate'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
                </div>
                <div fxFlex="90px" fxShow.lt-md="false" (click)="sort('salesDate')">
                    {{ 'common.sales_date' | translate }}
                    <mat-icon *ngIf="sortParams.key === 'salesDate'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
                </div>
                <div fxFlex="90px" fxShow.lt-lg="false" (click)="sort('endCustomerRegistrationDate')">
                    {{ 'common.customer_registration' | translate }}
                    <mat-icon *ngIf="sortParams.key === 'endCustomerRegistrationDate'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
                </div>
                <div fxFlex="90px" fxShow.lt-lg="false" (click)="sort('warrantyExpireDate')">
                    {{ 'common.warranty_expiry_date' | translate }}
                    <mat-icon *ngIf="sortParams.key === 'warrantyExpireDate'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
                </div>
            </div>
        <div fxLayout="column" fxLayoutAlign="start start">
            <ng-container *ngFor="let registration of registrations">
                <div class="list-row" fxLayout="row" fxLayoutAlign="start center">
                    <div fxFlex="40px" class="select-checkbox">
                        <mat-checkbox [(ngModel)]="selectedRegistrations[registration.id]" (change)="checkSelectedRegistrations()"></mat-checkbox>
                    </div>
                    <div (click)="openRegistration(registration.registeredCompany, registration.registeredPartnerId, registration.serialNumber)" fxFlex>
                        <div fxShow.lt-sm="false" fxFlex="60px" *ngIf="permissions.scope === 'global'">
                            {{registration.registeredCompany}}
                        </div>
                        <div fxShow.lt-sm="false" fxFlex="80px" *ngIf="permissions.scope === 'global' || permissions.scope === 'company'">
                            {{registration.registeredPartnerId}}
                        </div>
                        <div fxFlex="100px">
                            {{registration.serialNumber}}
                        </div>
    
                        <div fxFlex="110px" fxShow.lt-md="false">
                            {{registration.productId}}
                        </div>
                        <div fxFlex fxShow.lt-sm="false">
                            {{registration.productInfo?.partDescription}}
                        </div>
                        <div fxFlex="300px" fxShow.lt-sm="false" class="date">
                            {{registration.registeredEndCustomerName}}
                        </div>
                        <div fxFlex="90px" class="date">
                            {{registration.partnerRegistrationDate | date:'shortDate':undefined:localeService.locale}}<br>
                        </div>
                        <div fxFlex="90px" fxShow.lt-md="false" class="date">
                            {{registration.salesDate | date:'shortDate':undefined:localeService.locale}}<br>
                        </div>
                        <div fxFlex="90px" fxShow.lt-lg="false" class="date">
                            {{registration.endCustomerRegistrationDate | date:'shortDate':undefined:localeService.locale}}<br>
                        </div>
                        <div fxFlex="90px" fxShow.lt-lg="false" class="date" [ngClass]="{'warn': registration.warrantyExpireDate < now}">
                            {{registration.warrantyExpireDate | date:'shortDate':undefined:localeService.locale}}<br>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div fxLayout="row" class="width100">
                <div fxFlex>
                    <h2 class="mat-subhead">
                        <small><i>{{ 'common.results' | translate }}: {{resultHits}} / {{totalHits}}</i></small>
                    </h2>
                </div>
                <div fxFlex fxLayoutAlign="center center">
                    <mat-progress-spinner matSuffix *ngIf="registrationsLoading" [mode]="'indeterminate'" [color]="'primary'" [diameter]="48" [strokeWidth]="4"></mat-progress-spinner>
                    <button *ngIf="!registrationsLoading && totalHits > resultHits" mat-button (click)="loadMore()">
                        <mat-icon class="kemppi-symbols">ş</mat-icon> {{ 'claims.load_more' | translate }}
                    </button>
                </div>
                <div fxFlex></div>
            </div>
        </div>
    </div>
</div>

<ks-actionbar
        [hasCancel]="false"
        [hasSave]="false"
>
    <div fxFlex></div>

    <div *ngIf="anyRegistrationSelected">
        <button mat-button mat-raised-button color="accent" class="export-button" (click)="exportSelectedRegistrationsToXLSX()">
            <mat-icon>open_in_new</mat-icon> XLSX
        </button>
    </div>

    <div fxFlex></div>
</ks-actionbar>
