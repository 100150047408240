<div class="main">
    <div class="full" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center">
        <fieldset class="login-container">
            <legend>Test login</legend>
            <div fxLayout="column">
                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
                    <mat-form-field>
                        <mat-label>Name</mat-label>
                        <input matInput [(ngModel)]="loginParams.name" required>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Role</mat-label>
                        <!--
                        <mat-select [(value)]="loginParams.role" required [disabled]="publicLogin">
                            <mat-option *ngFor="let roleType of roleTypes" [value]="roleType">{{roleType}}</mat-option>
                        </mat-select>
                        -->
                        <mat-select [(value)]="loginParams.role" required [disabled]="publicLogin">
                            <mat-option value="cus_dealer">Dealer</mat-option>
                            <mat-option value="cus_dealer_wo_workshop">Dealer w/o Workshop</mat-option>
                            <mat-option value="cus_direct">Direct customer</mat-option>
                            <mat-option value="cus_distributor">Distributor</mat-option>
                            <mat-option value="cus_workshop">Workshop</mat-option>
                            <mat-option value="sub_user">Subsidiary</mat-option>
                            <mat-option value="sub_readonly">Subsidiary (Read only)</mat-option>
                            <mat-option value="koy_user">Kemppi Oy</mat-option>
                            <mat-option value="koy_readonly">Kemppi Oy (Read only)</mat-option>
                            <mat-option value="koy_logistics">Kemppi Oy (Logistics)</mat-option>
                            <mat-option value="sys_admin">System Admin</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
                    <mat-form-field>
                        <mat-label>Email</mat-label>
                        <input matInput [(ngModel)]="loginParams.email" required [disabled]="publicLogin">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Id</mat-label>
                        <input matInput [(ngModel)]="loginParams.id" required [disabled]="publicLogin">
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
                    <mat-form-field>
                        <mat-label>Partner id</mat-label>
                        <input matInput [(ngModel)]="loginParams.partnerId" (change)="validateAndParseUserType()" required [disabled]="publicLogin">
                        <mat-hint class="weak">({{ loginParams.userType }})</mat-hint>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Company</mat-label>
                        <mat-select [(value)]="loginParams.company" (selectionChange)="validateAndParseUserType()" required [disabled]="publicLogin">
                            <mat-option *ngFor="let companyCode of companyCodes" [value]="companyCode">{{companyCode}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <br>
                <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
                    <mat-form-field>
                        <mat-label>Password</mat-label>
                        <input matInput [(ngModel)]="loginParams.password" type="password" (keydown.enter)="login()" [disabled]="publicLogin">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Language (default)</mat-label>
                        <mat-select [(value)]="loginParams.language">
                            <mat-option *ngFor="let languageCode of languageCodes" [value]="languageCode">{{languageCode}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxLayout="row">
                    <mat-checkbox [(ngModel)]="loginParams.showPrices">Show prices</mat-checkbox>
                </div>
                <div fxLayout="row">
                    <button mat-raised-button color="accent" (click)="login()">
                        <mat-icon>lock</mat-icon>
                        LOGIN
                    </button>
                    <mat-progress-spinner matSuffix *ngIf="loading" [mode]="'indeterminate'" [color]="'primary'" [diameter]="20" [strokeWidth]="2"></mat-progress-spinner>
                </div>
                <div fxLayout="row" class="warn">
                    {{ loginError }}
                </div>
            </div>
        </fieldset>
    </div>
</div>
<!-- ACTIONBAR BUTTONS -->
<ks-actionbar
        [hasCancel]="false"
        [hasSave]="false"
>
